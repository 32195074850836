export default {
  address: '台北市中正區臨沂街25巷1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.7777311509863!2d121.52851941544705!3d25.041616044139076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a97b8582e803%3A0xaf25c66710392ead!2zMTAw5Y-w5YyX5biC5Lit5q2j5Y2A6Ieo5rKC6KGXMjXlt7cx6Jmf!5e0!3m2!1szh-TW!2stw!4v1587091551548!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/r1nsSKwoNv4xfJat7',
  phone: '02-29668888',
  fbLink: 'https://www.facebook.com/佳元臨沂-109244194096370/',
  fbMessage: 'https://m.me/109244194096370',
  caseName: '佳元臨沂',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '佳元開發建設股份有限公司'],
    ['建築設計', '陳克聚建築師事務所'],
    ['公設設計', 'HHC Design Solution禾禧設計<br>張向榮設計師'],
    ['結構力學', '聯邦工程顧問公司'],
    ['建築代銷', '聯碩行銷股份有限公司'],
    ['建案位置', '台北市中正區臨沂街25巷1號'],
    ['建照號碼', '109建字第0006號'],
    ['經紀人', '李濟強(100)北市經證字第01732號'],
  ],

  gtmCode: ['575QKB8'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
