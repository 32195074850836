<template>
  <div class="relative">
    <div>
      <div
        data-aos="fade-left"
        data-aos-delay="300"
      >
        <img
          :src="isMobile ? require('./s1/mo/1.png') : require('./s1/1.png')"
          alt=""
          class="bg-img tree1"
        >
      </div>
      <div
        data-aos="fade-right"
        data-aos-delay="600"
      >
        <img
          :src="isMobile ? require('./s1/mo/2.png') : require('./s1/2.png')"
          alt=""
          class="bg-img tree2"
        >
      </div>
      <img
        :src="isMobile ? require('./s1/mo/3.png') : require('./s1/3.png')"
        alt=""
        class="bg-img"
        data-aos="fade"
        data-aos-delay="900"
      >
      <svg v-if="!isMobile" version="1.1" id="svg1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1106.8 518.5" style="enable-background:new 0 0 1106.8 518.5;" xml:space="preserve">
        <g class="an11">
          <polygon class="st0" points="18,485.6 12.3,485.6 11.5,485.6 0.6,485.6 0.6,490.7 11.5,490.7 11.5,490.7 11.5,490.7 14.8,489.5 
            18,490.7 28.9,490.7 28.9,485.6 	"/>
          <polygon class="st1" points="14.8,490.7 8.2,493.2 0.6,493.2 0.6,513.9 3.2,513.9 4,513.9 10.7,513.9 10.7,502.9 14.8,501.3 
            18.8,502.9 18.8,513.9 25.5,513.9 27.5,513.9 28.9,513.9 28.9,493.2 21.3,493.2 	"/>
          <path d="M44.6,497.9c-0.9,1.1-1.8,2.1-2.8,3.1c-0.1,0.1-0.3-0.2-0.3-0.3c1.8-2.6,3.2-5.5,4.2-8.5c0.5-1.4,0.8-2.8,1-4.3
            c3,0.9,3.4,1,3.4,1.4s-0.1,0.4-1.1,0.6c-0.7,2.1-1.8,4.1-3,6c1.5,0.2,1.7,0.3,1.7,0.6s-0.2,0.4-0.7,0.6v13.4c0,0.7-0.4,1.1-1.4,1.1
            s-1.2-0.3-1.2-1s0.2-2.7,0.2-6.8L44.6,497.9z M57.1,498.3h4.3c1.2-1.5,1.5-1.7,1.7-1.7s0.5,0.2,1.6,1.2c0.5,0.4,0.7,0.6,0.7,0.8
            s-0.1,0.3-0.3,0.3H50.2c-0.7,0-1.5,0.1-2.2,0.1l-0.3-1c0.8,0.1,1.6,0.2,2.5,0.2h4.5v-4.8h-3.2c-0.7,0-1.4,0.1-2.1,0.2l-0.3-1
            c0.8,0.1,1.5,0.2,2.3,0.2h3.2v-1.8c0-1.1,0-2.1-0.1-3c3.1,0.3,3.3,0.5,3.3,0.8s-0.3,0.5-0.8,0.6v3.4h3.3c1.1-1.4,1.4-1.6,1.6-1.6
            s0.5,0.2,1.6,1.1c0.5,0.4,0.7,0.6,0.7,0.8s-0.1,0.3-0.3,0.3h-6.9V498.3z M57.1,509.9h4.4c1.3-1.5,1.6-1.7,1.8-1.7s0.5,0.2,1.7,1.2
            c0.5,0.4,0.7,0.7,0.7,0.9s-0.1,0.3-0.3,0.3H50.2c-0.7,0-1.5,0.1-2.2,0.1l-0.3-1c0.8,0.1,1.6,0.2,2.5,0.2h4.5v-5.6H51
            c-0.7,0-1.3,0.1-2,0.1l-0.3-1c0.7,0.1,1.5,0.2,2.2,0.2h3.7v-1c0-1.1,0-2.1-0.1-3c3.1,0.3,3.3,0.5,3.3,0.8s-0.3,0.5-0.8,0.6v2.6h3.5
            c1.1-1.4,1.4-1.6,1.6-1.6s0.5,0.2,1.6,1.1c0.5,0.4,0.7,0.6,0.7,0.8s-0.1,0.3-0.3,0.3h-7L57.1,509.9z M83.8,507.7
            c0,0.9,0.4,1.1,2.3,1.1c0.9,0.1,1.9,0,2.8-0.4c0.8-0.5,1.2-1.7,1.8-4.5c0-0.1,0.3-0.1,0.3,0v3.2c0,0.6,0,0.7,0.5,0.9
            c0.4,0.2,0.6,0.6,0.6,1c0,0.7-0.4,1.2-1.3,1.5c-1.6,0.4-3.2,0.5-4.8,0.4c-3.8,0-4.7-0.5-4.7-2.6c0-1.7,0-3.2,0-5.1v-6.4h-3.4
            c0,4.1-0.6,7.1-1.8,9.3c-1.5,2.6-3.9,4.1-8,5.4c-0.1,0-0.1,0-0.2-0.1c0,0,0-0.1,0-0.1c3.2-1.9,5.1-3.7,6.2-6.4
            c0.9-2.6,1.3-5.3,1.2-8H71c-0.8,0-1.6,0.1-2.4,0.2l-0.3-1c0.9,0.1,1.8,0.2,2.7,0.2h16.2c1.4-1.7,1.7-2,1.9-2s0.5,0.2,2,1.4
            c0.7,0.5,0.9,0.8,0.9,0.9s-0.1,0.3-0.3,0.3h-7.9V507.7z M84.2,490c1.4-1.6,1.7-1.8,1.9-1.8s0.5,0.2,1.9,1.2
            c0.7,0.5,0.8,0.8,0.8,0.9s-0.1,0.3-0.3,0.3H74.1c-0.8,0-1.5,0.1-2.3,0.2l-0.3-1c0.8,0.1,1.6,0.2,2.5,0.2L84.2,490z M97.4,510.7
            c0,0.7-0.5,1-1.4,1c-0.8,0-1.1-0.2-1.1-0.9c0-1.6,0.2-3.9,0.2-11.6c0-3.9,0-7.7-0.2-11.6c1,0.4,1.9,0.8,2.8,1.4h4.6
            c0.9-1,1.1-1.2,1.3-1.2s0.5,0.2,1.4,0.9c0.4,0.3,0.6,0.5,0.6,0.7s-0.2,0.4-0.8,0.8v1c0,2.5,0,4.3,0,4.9s-0.5,0.9-1.4,0.9
            c-0.7,0-0.9-0.2-0.9-0.8v-0.5h-5L97.4,510.7z M102.4,489.6h-5v2.4h5V489.6z M97.4,495.1h5v-2.5h-5V495.1z M104.5,503.9
            c-0.5,4-2,5.7-5.3,7.2c-0.1,0-0.3-0.1-0.2-0.2c2-1.8,3.2-4.3,3.2-7h-1.3c-0.7,0-1.4,0.1-2.1,0.2l-0.3-1c0.8,0.1,1.5,0.2,2.3,0.2
            h1.5c0-0.8,0-1.6,0-2.5v-1.1h-0.9c-0.6,0-1.2,0.1-1.8,0.2l-0.3-1c0.7,0.1,1.3,0.2,2,0.2h8.4c1-1.2,1.3-1.4,1.5-1.4s0.5,0.2,1.4,1
            c0.4,0.3,0.6,0.6,0.6,0.8s-0.1,0.3-0.3,0.3h-3.3v3.6h0.7c1.1-1.4,1.4-1.6,1.6-1.6s0.5,0.2,1.5,1.1c0.4,0.4,0.6,0.6,0.6,0.8
            s-0.1,0.3-0.3,0.3h-4.1c0,2.4,0.1,5.1,0.1,6c0,0.7-0.5,1-1.4,1s-1.1-0.2-1.1-0.8s0.1-2.1,0.1-5.1v-1.1L104.5,503.9z M107.3,503.3
            v-3.6h-2.7c0,1.2,0,2.4-0.1,3.6H107.3z M109.5,496c0,0.6-0.4,0.9-1.3,0.9c-0.7,0-1-0.2-1-0.8c0-0.9,0-1.9,0-5.6c0-1,0-2-0.1-3
            c0.9,0.4,1.8,0.8,2.7,1.3h4.8c0.9-1,1.1-1.2,1.3-1.2s0.5,0.2,1.4,0.9c0.4,0.4,0.6,0.6,0.6,0.8s-0.2,0.4-0.7,0.8v3.1
            c0,14.6,0,15.3,0,16c0.1,0.7-0.2,1.4-0.8,1.8c-0.6,0.4-1.3,0.5-2,0.5c-0.5,0-0.5-0.1-0.6-0.6c-0.2-0.9-0.7-1.4-2.5-1.9
            c-0.1,0-0.1-0.3,0-0.3c1.5,0.1,2.1,0.1,3.2,0.1c0.3,0,0.3-0.1,0.3-0.4v-13h-5.2V496z M114.8,489.6h-5.3v2.4h5.3V489.6z
            M109.5,495.1h5.3v-2.5h-5.3V495.1z M136.8,495.8c0.3-0.3,0.5-0.6,0.7-0.7c-0.4-0.2-0.8-0.4-1.1-0.6c-1.6-1-3-2.4-4.1-4
            c-2,2.4-4.7,4.3-7.7,5.4c0.4,0.1,0.7,0.1,1.1,0.1h1.6c0.8-1,1-1.1,1.2-1.1s0.5,0.2,1.4,0.9c0.4,0.3,0.6,0.5,0.6,0.7
            s-0.1,0.3-0.7,0.7v0.6c0,1.5,0.1,2.5,0.1,2.8c0,0.6-0.5,0.9-1.3,0.9c-0.7,0-1-0.2-1-0.7v-0.3h-2.7c-0.3,1.1-0.5,2.3-0.8,3.3h3.3
            c0.9-1,1.1-1.1,1.3-1.1s0.5,0.2,1.4,0.9c0.4,0.3,0.6,0.5,0.6,0.7s-0.1,0.3-0.8,0.8c-0.1,1.7-0.4,3.4-1.1,4.9
            c-0.3,0.6-0.8,1.1-1.4,1.4c-0.5,0.2-1.1,0.3-1.7,0.3c-0.3,0-0.3-0.1-0.4-0.5c-0.2-1-0.6-1.3-2.4-1.9c-0.1,0-0.1-0.4,0-0.4
            c0.9,0.1,1.8,0.2,2.7,0.2c0.8,0,1-0.2,1.2-0.7c0.4-1.3,0.6-2.7,0.7-4.1h-3.5c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.5-0.5,0.7-1.3,0.7
            s-1.1-0.2-1.1-0.5c0-0.2,0.1-0.4,0.2-0.6c0.4-1.2,0.6-2.3,0.9-3.4c0.2-0.8,0.3-1.6,0.4-2.4c0.8,0.4,1.6,0.8,2.4,1.2h2.4v-3.2h-1.9
            c-0.5,0-0.9,0.1-1.3,0.2l-0.2-0.7c-1.4,0.5-2.8,0.9-4.2,1.3c-0.1,0-0.3-0.2-0.1-0.3c4.4-1.9,8.1-4.5,10-7.4H126
            c-0.6,0-1.2,0.1-1.8,0.2l-0.3-1c0.6,0.1,1.3,0.2,1.9,0.2h4c1.1-1.2,1.3-1.3,1.5-1.3s0.5,0.1,1.5,0.9c0.4,0.4,0.6,0.5,0.6,0.8
            s-0.2,0.4-0.8,0.6c0.5,0.5,1.1,0.9,1.7,1.4c0.5-0.6,0.9-1.3,1.3-2c0.3-0.5,0.5-1,0.7-1.6c2.1,1.1,2.6,1.4,2.6,1.7s-0.1,0.4-1.2,0.4
            c-0.8,0.7-1.7,1.4-2.7,1.9c0.6,0.4,1.3,0.7,2,1l0.4,0.2c0.5-0.6,1-1.2,1.4-1.8c0.3-0.5,0.6-1,0.9-1.6c2.5,1.4,2.6,1.5,2.6,1.8
            s-0.2,0.4-1.1,0.4c-0.9,0.6-1.9,1.1-2.9,1.5c2,0.6,4,1,6,1.3c0.1,0,0.1,0.2,0,0.3c-0.8,0.3-1.4,1-1.4,1.8c-0.1,0.2-0.2,0.3-0.3,0.3
            c-0.4,0-0.8-0.1-1.2-0.2c-0.6-0.2-1.2-0.4-1.9-0.7c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.4-0.6,0.6v1.9c0,0.4,0.2,0.5,1,0.5
            s1.1,0,1.4-0.4c0.3-0.5,0.5-1,0.6-1.5c0.1-0.1,0.3-0.1,0.3,0c0,0.4,0,0.7,0.1,1.1s0.1,0.4,0.3,0.5c0.3,0.1,0.6,0.4,0.6,0.8
            c0,0.5-0.3,0.9-0.8,1c-0.8,0.3-1.6,0.4-2.4,0.4c-2.5,0-3.2-0.5-3.2-2.2c0-0.6,0-1.1,0-1.5v-1.2h-2.3v0.5c0,1.1-0.3,2.1-0.9,3
            c-0.9,1.2-2.2,2-3.7,2.2c-0.1,0-0.3-0.2-0.2-0.3c1-0.6,1.7-1.5,2.1-2.5c0.2-0.9,0.3-1.8,0.3-2.8c0-0.6,0-1.3-0.1-1.9
            c0.9,0.3,1.7,0.7,2.5,1.2L136.8,495.8z M124.6,494c-0.5,0-0.7-0.1-1.1-1.3c-0.3-0.9-0.9-1.6-1.6-2.2c-0.1,0-0.1-0.1,0-0.2
            c0,0,0.1-0.1,0.1,0c2.6,0.4,3.8,1.2,3.8,2.3C125.8,493.4,125.3,494,124.6,494C124.6,494,124.6,494,124.6,494z M137.4,503
            c1.1-1.1,1.3-1.2,1.5-1.2s0.5,0.2,1.5,1c0.5,0.4,0.6,0.6,0.6,0.8s-0.3,0.5-1,0.8c-0.7,1.2-1.5,2.3-2.5,3.2c1.9,0.9,4,1.5,6.1,1.8
            c0.1,0,0.1,0.3,0,0.3c-0.9,0.2-1.3,0.6-1.6,1.7c-0.1,0.4-0.1,0.5-0.4,0.5c-0.4,0-0.7-0.1-1.1-0.3c-1.2-0.5-2.4-1.1-3.5-1.9
            c-0.4-0.3-0.7-0.5-1-0.8c-2.2,1.5-4.7,2.5-7.4,2.9c-0.1,0-0.3-0.3-0.1-0.3c2.3-0.9,4.4-2.2,6.2-3.9c-0.9-1.2-1.7-2.6-2.2-4h-0.3
            c-0.5,0-1,0.1-1.4,0.2l-0.3-1c0.7,0.1,1.3,0.2,2,0.2L137.4,503z M133.1,503.6c0.7,1.1,1.5,2.1,2.6,2.9c0.7-0.9,1.3-1.9,1.8-2.9
            H133.1z M151.5,497c0.9-1,1.1-1.1,1.3-1.1s0.5,0.1,1.3,0.9c0.4,0.3,0.5,0.5,0.5,0.7s-0.2,0.5-0.7,0.7c-0.3,2.7-1.1,5.2-2.4,7.6
            c0.3,0.3,0.6,0.6,0.9,0.9c2,1.6,4.9,2.1,9.7,2.1c2.7,0,5.4-0.2,8.1-0.5c0.1,0,0.1,0.2,0,0.2c-0.7,0.3-1.2,1.1-1.1,1.9
            c0,0.3-0.1,0.5-0.8,0.5c-0.9,0.1-3.3,0.2-4.8,0.2c-6.1,0-9.1-0.8-11.5-3c-0.4-0.4-0.8-0.8-1.2-1.3c-1.3,1.8-3,3.3-4.9,4.4
            c-0.1,0.1-0.3-0.2-0.2-0.3c1.7-1.7,3-3.7,4-5.9c-0.6-1.2-1.1-2.5-1.5-3.9c0-0.3-0.2-0.5-0.4-0.7c-0.1-0.1-0.2-0.2-0.2-0.3
            c0-0.1,0.6-0.4,1-0.4c0.2,0,0.3,0.1,0.3,0.2s-0.1,0.4-0.1,0.5c0.1,0.5,0.2,0.9,0.4,1.3c0.3,0.7,0.7,1.4,1.1,2.1
            c0.7-2.1,1.1-4.2,1.3-6.4h-1.9c-0.1,0.1-0.2,0.3-0.3,0.5c-0.4,0.6-0.5,0.7-1,0.7c-0.8,0-1.4-0.4-1.4-0.9c0-0.2,0.1-0.5,0.2-0.7
            c1.4-2.2,2.5-4.5,3.3-6.9h-2.3c-0.6,0-1.1,0-1.6,0.2l-0.2-1c0.6,0.2,1.2,0.2,1.8,0.2h2.3c0.9-1,1.1-1.2,1.3-1.2s0.4,0.2,1.3,1
            c0.4,0.4,0.5,0.6,0.5,0.8s-0.2,0.4-0.9,0.7c-0.8,2-1.7,4-2.7,5.9H151.5z M162,498.1c0,0.9,0,1.8,0,2.7h3.3c1-1.2,1.4-1.4,1.5-1.4
            s0.5,0.2,1.6,1c0.4,0.4,0.6,0.6,0.6,0.8s-0.1,0.3-0.3,0.3H162c0,0.9,0,1.8,0.1,2.8h3.9c1.2-1.4,1.5-1.6,1.7-1.6s0.5,0.2,1.6,1.1
            c0.5,0.4,0.7,0.6,0.7,0.8s-0.1,0.3-0.3,0.3h-7.6c0,1.1,0,1.9,0,2.5c0,0.8-0.4,1.1-1.4,1.1s-1.1-0.2-1.1-1.1c0-0.5,0-1.3,0-2.5h-4.2
            c-0.6,0-1.1,0.1-1.6,0.2l-0.3-1c0.6,0.1,1.2,0.2,1.9,0.2h4.3c0-0.9,0-1.9,0-2.8h-3.1c-0.5,0-1,0.1-1.5,0.1l-0.3-1
            c0.6,0.1,1.2,0.2,1.8,0.2h3.1c0-0.9,0-1.8,0-2.7h-2.9c-0.5,0-1,0.1-1.4,0.2l-0.3-1c0.5,0.1,1.1,0.2,1.7,0.2h3v-2.8h-4
            c-0.6,0-1.2,0.1-1.7,0.1l-0.3-1c0.7,0.1,1.3,0.2,2,0.2h4v-2.7H157c-0.5,0-1.1,0.1-1.6,0.2l-0.3-1c0.6,0.1,1.2,0.2,1.9,0.2h2.7
            c0-1.1-0.1-2-0.1-2.9c2.6,0.3,3,0.4,3,0.8c0,0.2-0.1,0.3-0.7,0.6v1.5h3.2c0.9-1.1,1.1-1.3,1.3-1.3s0.5,0.2,1.4,1
            c0.4,0.4,0.5,0.5,0.5,0.8s-0.1,0.3-0.8,0.9v2h0.2c0.7-1.1,1-1.3,1.2-1.3s0.4,0.2,1.1,0.9c0.2,0.2,0.4,0.4,0.4,0.7
            c0,0.2-0.1,0.3-0.3,0.3h-2.6c0,1.6,0.1,2.8,0.1,3.2c0,0.7-0.4,1-1.2,1s-1-0.3-1-0.9L162,498.1z M162,494.1h3.3v-2.7H162V494.1z
            M165.3,494.7H162c0,0.9,0,1.9,0,2.8h3.3L165.3,494.7z M179,493.2c1.1-1.4,1.4-1.6,1.6-1.6s0.5,0.2,1.7,1.1
            c0.5,0.4,0.7,0.6,0.7,0.8s-0.1,0.3-0.3,0.3h-8.2c-0.6,0-1.2,0.1-1.8,0.2l-0.3-1c0.7,0.1,1.4,0.2,2.1,0.2L179,493.2z M175.8,509.9
            v0.8c0,0.7-0.5,1-1.3,1s-1.1-0.3-1.1-0.9c0-0.8,0.1-1.6,0.1-5c0-1.2,0-2.5-0.2-3.7c0.9,0.4,1.9,0.8,2.8,1.3h2.6
            c0.9-1.1,1.1-1.3,1.3-1.3s0.5,0.2,1.4,0.9c0.4,0.4,0.6,0.5,0.6,0.8s-0.2,0.5-0.8,0.9v0.9c0,2.2,0.1,3.8,0.1,4.3c0,0.7-0.5,1-1.4,1
            c-0.7,0-1-0.2-1-0.8v-0.2L175.8,509.9z M177.8,489.7c1.1-1.4,1.5-1.6,1.6-1.6s0.5,0.2,1.6,1.1c0.5,0.4,0.7,0.6,0.7,0.8
            s-0.1,0.3-0.3,0.3h-6.1c-0.5,0-1,0.1-1.5,0.2l-0.3-1c0.6,0.1,1.2,0.2,1.8,0.2L177.8,489.7z M178,496.7c1-1.3,1.3-1.5,1.5-1.5
            s0.5,0.2,1.5,1c0.4,0.4,0.6,0.6,0.6,0.8s-0.1,0.3-0.3,0.3h-6c-0.5,0-1.1,0.1-1.6,0.2l-0.3-1c0.6,0.1,1.2,0.2,1.9,0.2L178,496.7z
            M178,500c1-1.3,1.3-1.5,1.5-1.5s0.5,0.2,1.5,1c0.4,0.4,0.6,0.6,0.6,0.8s-0.1,0.3-0.3,0.3h-6c-0.5,0-1,0.1-1.6,0.2l-0.3-1
            c0.6,0.1,1.2,0.2,1.9,0.2L178,500z M178.9,504h-3.1v5.2h3.1V504z M191,499.3c1.1-1,1.3-1.2,1.5-1.2s0.5,0.2,1.6,1
            c0.5,0.4,0.6,0.6,0.6,0.8s-0.3,0.5-1,0.8c-0.9,2-2,3.8-3.5,5.3c1.9,1.2,4,2,6.3,2.4c0.1,0,0.1,0.1,0.1,0.2c0,0,0,0.1-0.1,0.1
            c-0.9,0.3-1.2,0.8-1.5,2c-0.1,0.4-0.2,0.5-0.4,0.5c-0.3-0.1-0.7-0.2-1-0.4c-1.9-0.7-3.5-1.9-4.9-3.4c-2.3,1.8-4.9,3.2-7.7,3.9
            c-0.1,0-0.3-0.3-0.1-0.3c2.5-1.2,4.8-2.9,6.6-5.1c-1.2-1.8-2.1-3.9-2.6-6h-0.4c-0.5,0-1,0.1-1.5,0.2l-0.3-1
            c0.6,0.1,1.3,0.2,1.9,0.2L191,499.3z M195.6,494.2c0,0.4,0.1,0.5,0.5,0.6c0.3,0.2,0.4,0.5,0.4,0.8c0,0.5-0.3,1-1,1.2
            c-0.8,0.2-1.7,0.4-2.5,0.3c-2.3,0-3.1-0.6-3.1-2.2c0-1,0-1.3,0-2.3V490h-3.1v0.5c0.1,1.6-0.2,3.2-0.8,4.6c-0.7,1.3-1.9,2.1-3.9,3.1
            c-0.1,0-0.3-0.1-0.2-0.2c1.1-1,1.9-2.2,2.2-3.6c0.2-1,0.3-2.1,0.3-3.1c0-1-0.1-2.1-0.2-3.1c1.3,0.5,1.5,0.6,2.7,1.2h2.9
            c0.8-1,1-1.1,1.3-1.1s0.5,0.1,1.3,0.8c0.2,0.2,0.4,0.4,0.5,0.7c0,0.3-0.2,0.5-0.7,0.8v4c0,0.4,0.2,0.5,1,0.5c0.6,0,1,0,1.2-0.3
            c0.5-0.7,0.7-1.5,0.9-2.3c0-0.1,0.3-0.1,0.3,0L195.6,494.2z M185.6,499.9c0.7,1.8,1.7,3.4,3.1,4.8c1.1-1.4,1.9-3.1,2.4-4.8H185.6z"
            />
        </g>
        <g class="an11">
          <path d="M263.5,489.9L263.5,489.9c-0.5,0-0.9,0.1-1.3,0.2l-0.3-1c0.5,0.1,1,0.2,1.6,0.2h4.6c1-1.1,1.3-1.4,1.5-1.4s0.5,0.2,1.5,0.9
            c0.4,0.4,0.6,0.6,0.6,0.8s-0.1,0.3-0.3,0.3h-1.5v7.4c0,5,0.1,11.2,0.1,13.3c0,0.8-0.5,1.1-1.3,1.1c-0.4,0-1-0.1-1-1
            c0-0.6,0.1-2.3,0.1-5.8c-1.1,1-2.4,1.9-3.8,2.6c-0.1,0.7-0.3,0.9-0.6,0.9s-0.6-0.5-1.4-2.9l1.5-0.2L263.5,489.9z M265.6,494.4h2.1
            v-4.5h-2.1V494.4z M265.6,499.1h2.1V495h-2.1V499.1z M265.6,504.9c0.7-0.2,1.4-0.5,2.1-0.7v-4.4h-2.1V504.9z M272,497.1
            c1-1.3,1.9-2.7,2.7-4.2c0.3-0.6,0.6-1.3,0.8-2c2,0.8,2.4,1.1,2.4,1.4s-0.2,0.4-1,0.4c-1.1,1.6-2.4,3.1-3.9,4.3
            c1.1,0,2.1-0.2,3.1-0.3c-0.1-0.6-0.3-1.1-0.6-1.6c0,0,0-0.1,0-0.2c0,0,0.1,0,0.1,0c1.7,0.8,2.5,1.8,2.5,2.8c0,0.7-0.4,1.3-1,1.3
            s-0.8-0.3-0.9-1.1c0-0.2,0-0.3,0-0.5c-1.4,0.6-2.9,1.1-4.4,1.5c0,0.5-0.2,0.6-0.4,0.6s-0.4-0.3-1-2.5c0.4,0,0.7,0,0.9,0L272,497.1z
            M273.2,489.6c0.2-0.6,0.4-1.2,0.5-1.8c2,0.6,2.6,0.9,2.6,1.2s-0.2,0.4-0.9,0.5c-0.7,0.9-1.5,1.7-2.4,2.4c0.5,0.3,0.9,0.9,0.9,1.6
            c0.1,0.5-0.3,1.1-0.9,1.1c0,0-0.1,0-0.1,0c-0.4,0-0.6-0.2-0.9-0.9c-0.2-0.9-0.7-1.8-1.4-2.5c-0.1-0.1,0-0.2,0.1-0.2
            c0.6,0.1,1.1,0.3,1.6,0.6C272.7,490.9,273,490.2,273.2,489.6L273.2,489.6z M275.2,505c0.1-1.2,0.2-2.5,0.1-3.7c0-0.8,0-1.1,0-1.7
            c2.7,0.2,3,0.3,3,0.6s-0.2,0.4-0.7,0.6c0,0.9,0,1.7-0.1,2.3c0,1.5-0.2,2.9-0.8,4.3c-0.9,2.1-2.8,3.5-5.5,4.3
            c-0.1,0-0.2-0.2-0.1-0.3c1.3-0.7,2.4-1.8,3.1-3.1c0.5-0.9,0.8-1.8,0.9-2.8h-1.7v0.6c0,0.7-0.4,0.9-1.2,0.9c-0.6,0-0.9-0.2-0.9-0.8
            c0-0.3,0.1-1.3,0.1-3.1c0-0.9,0-1.8-0.1-2.9c2.2,0.2,2.8,0.3,2.8,0.7c0,0.2-0.1,0.3-0.6,0.5v3.5L275.2,505z M280.4,489.8
            c0.3-0.6,0.5-1.3,0.6-2c2,0.6,2.6,0.9,2.6,1.2s-0.2,0.4-0.9,0.5c-0.8,0.9-1.6,1.6-2.5,2.3c0.6,0.3,1.1,0.9,1.1,1.6
            c0.1,0.5-0.3,1.1-0.9,1.1c0,0-0.1,0-0.1,0c-0.4,0-0.7-0.2-0.9-0.9c-0.2-1-0.7-1.8-1.5-2.5c-0.1-0.1,0-0.2,0.1-0.2
            c0.5,0.1,1,0.3,1.5,0.5C279.9,490.9,280.2,490.4,280.4,489.8L280.4,489.8z M282.3,492.8c0.3-0.7,0.6-1.3,0.8-2
            c1.7,0.6,2.5,1,2.5,1.4s-0.2,0.4-1,0.4c-1.2,1.6-2.5,3.1-4.1,4.4c1.1,0,2.3-0.1,3.5-0.2c-0.2-0.6-0.4-1.2-0.8-1.7
            c-0.1-0.1,0-0.2,0.2-0.1c1.8,0.8,2.8,1.9,2.8,3c0.1,0.6-0.4,1.2-1,1.2c0,0,0,0-0.1,0c-0.5,0-0.8-0.4-0.9-1.1c0-0.2,0-0.3-0.1-0.4
            c-1.4,0.5-2.8,1-4.2,1.3c-0.1,0.5-0.2,0.6-0.4,0.6s-0.4-0.4-1-2.5c0.4,0,0.8,0,1.1,0C280.6,495.7,281.5,494.3,282.3,492.8
            L282.3,492.8z M281.3,505.6c0,2.6,0.1,4.4,0.1,5.2s-0.4,0.9-1.4,0.9c-0.7,0-1-0.2-1-0.9s0.1-2.7,0.1-6.8c0-1.5,0-3-0.1-4.5
            c2.7,0.3,3,0.4,3,0.8c0,0.2-0.2,0.4-0.7,0.7v4h2v-3c0-0.4,0-0.7-0.1-1.8c2.2,0.2,2.8,0.3,2.8,0.7c0,0.2-0.1,0.3-0.6,0.5v0.8
            c0,2.1,0,3.6,0,4.1c0,0.7-0.3,1-1.2,1c-0.6,0-0.9-0.2-0.9-0.8v-1L281.3,505.6z M293.7,490.9c-0.2,2.3-0.9,4.5-1.9,6.5
            c0.1,0.1,0.2,0.1,0.3,0.2h2.1c0.8-0.9,1-1.1,1.3-1.1s0.5,0.1,1.3,0.9c0.3,0.3,0.5,0.5,0.5,0.7s-0.2,0.4-0.6,0.7v1.5
            c0,4,0.1,6.7,0.1,7.6s-0.4,1.1-1.4,1.1c-0.6,0-0.9-0.3-0.9-0.9v-0.6h-2.5v1.9c0,0.8-0.4,1.1-1.3,1.1s-1-0.2-1-0.9
            c0-1.5,0.1-3.2,0.1-7.4c0-0.3,0-0.9,0-1.6c-0.6,0.7-1.3,1.5-2,2.1c-0.1,0.1-0.3-0.1-0.3-0.2c2.2-3.5,3.5-7.5,3.7-11.6h-1.1
            c-0.6,0-1.2,0.1-1.7,0.2l-0.3-1c0.7,0.1,1.3,0.2,2,0.2h4c1-1.4,1.3-1.6,1.5-1.6s0.5,0.2,1.5,1.1c0.4,0.4,0.6,0.6,0.6,0.8
            s-0.1,0.3-0.3,0.3L293.7,490.9z M294.3,498.2h-2.5v8.6h2.5V498.2z M303.1,509.1c-2.3,1.6-5,2.5-7.7,2.7c-0.1,0-0.3-0.3-0.1-0.3
            c1.7-0.8,3.3-1.8,4.8-3c0.6-0.5,1-1,1.4-1.6c2.1,1.1,2.9,1.6,2.9,2S303.9,509.2,303.1,509.1L303.1,509.1z M306.1,490.1
            c-0.7,1.3-1.6,2.5-2.7,3.5h4.4c1-1,1.2-1.2,1.4-1.2s0.5,0.2,1.5,0.9c0.5,0.3,0.6,0.5,0.6,0.7s-0.2,0.5-0.8,0.8v1.8
            c0,4.8,0.1,8.1,0.1,9.2c0,0.9-0.4,1.2-1.5,1.2c-0.8,0-1-0.3-1-1h-6.9c0,0.7-0.5,1-1.3,1s-1.2-0.2-1.2-0.9c0-1.1,0.1-2.5,0.1-9.2
            c0-1.8,0-2.8-0.1-4.7c1,0.4,1.9,0.8,2.8,1.3h1.1c0.3-1.2,0.6-2.3,0.7-3.5H300c-0.6,0-1.2,0.1-1.8,0.1l-0.3-1c0.7,0.1,1.3,0.2,2,0.2
            h7.9c1.3-1.5,1.6-1.7,1.8-1.7s0.5,0.2,1.7,1.2c0.5,0.5,0.8,0.6,0.8,0.8s-0.1,0.3-0.3,0.3L306.1,490.1z M308.1,494.2h-6.9v3.2h6.9
            V494.2z M308.1,498h-6.9v3.2h6.9V498z M308.1,501.8h-6.9v3.6h6.9V501.8z M310.4,511.6c-0.7,0-0.7,0-1.7-1.4c-0.8-1.2-2-2.2-3.3-2.8
            c-0.1,0,0-0.3,0.1-0.3c4.3,0.5,6.2,1.5,6.2,3.1C311.8,511,311.2,511.6,310.4,511.6C310.4,511.7,310.4,511.7,310.4,511.6
            L310.4,511.6z M317.8,500.5c-1,0.8-2.1,1.5-3.3,2.1c-0.1,0-0.3-0.2-0.2-0.3c1.8-1.7,3.4-3.7,4.7-5.8c0.6-0.9,1-1.9,1.4-2.9
            c2.6,1.3,3,1.5,3,1.8s-0.3,0.5-1.1,0.5c-0.7,0.9-1.5,1.8-2.4,2.7c0.8,0.2,0.9,0.3,0.9,0.5s-0.2,0.4-0.7,0.6v10.9
            c0,0.8-0.4,1.1-1.4,1.1c-0.6,0-1.1-0.2-1.1-1s0.2-2.7,0.2-6.9L317.8,500.5z M321.8,490c-1.9,2.5-4.4,4.5-7.2,5.7
            c-0.1,0.1-0.3-0.2-0.2-0.3c1.6-1.5,3-3.1,4.1-4.9c0.6-0.9,1.1-1.9,1.4-2.9c2.3,1,3.1,1.5,3.1,1.9S322.9,490,321.8,490L321.8,490z
            M333,503.2c0,1.9,0.1,3.8,0.1,5.7c0,0.7-0.2,1.3-0.6,1.8c-0.7,0.6-1.5,0.9-2.4,0.8c-0.5,0-0.5,0-0.6-0.6c-0.2-1-0.9-1.4-3.3-2
            c-0.1,0-0.1-0.4,0-0.4c1.2,0.1,2.9,0.2,3.9,0.2c0.5,0,0.6-0.1,0.6-0.5v-11h-5.7c-0.6,0-1.1,0.1-1.7,0.1l-0.3-1
            c0.6,0.1,1.3,0.2,2,0.2h9.2c1.3-1.5,1.6-1.7,1.8-1.7s0.5,0.2,1.7,1.2c0.5,0.4,0.8,0.7,0.8,0.9s-0.1,0.3-0.3,0.3h-4.9L333,503.2z
            M332.6,490.2c1.3-1.5,1.6-1.7,1.8-1.7s0.5,0.2,1.8,1.2c0.5,0.4,0.8,0.6,0.8,0.8s-0.1,0.3-0.3,0.3h-10.7c-0.6,0-1.1,0.1-1.7,0.2
            l-0.3-1c0.7,0.1,1.3,0.2,2,0.2L332.6,490.2z M346.4,489.9c2.6,0.9,3.9,2,3.9,3.3c0,0.7-0.5,1.2-1.1,1.2c0,0,0,0,0,0
            c-0.5,0-0.6-0.1-1.1-1.3c-0.3-1.1-1-2.1-1.8-2.9c-1.1,1.8-2.4,3.5-3.9,4.9c0.3,0.1,0.7,0.1,1,0.1h2.9c0.7-0.9,1-1.1,1.2-1.1
            s0.5,0.2,1.3,0.8c0.3,0.3,0.5,0.5,0.5,0.7s-0.1,0.3-0.3,0.3h-2.5v3.6h0.9c0.8-1,1.1-1.3,1.3-1.3s0.5,0.2,1.3,0.9
            c0.3,0.3,0.5,0.5,0.5,0.7s-0.1,0.3-0.3,0.3h-3.7v7.9c1.3-0.2,2.7-0.4,3.8-0.6c0.1,0,0.2,0.3,0.1,0.3c-2.6,1.2-5.3,2.2-8.1,3
            c-0.1,0.6-0.3,0.9-0.5,0.9s-0.5-0.3-1.3-3.1c1.1,0,1.8,0,2.6-0.1c0.4-0.1,0.9-0.1,1.3-0.1v-8.2h-2.1c-0.5,0-1,0.1-1.4,0.2l-0.3-1
            c0.5,0.1,1.1,0.2,1.6,0.2h2.2v-3.6h-0.8c-0.4,0-0.8,0.1-1.2,0.2l-0.2-0.6c-0.6,0.5-1.2,1-1.9,1.4c-0.1,0.1-0.3-0.1-0.2-0.3
            c1.5-1.7,2.7-3.6,3.6-5.6c0.5-1,0.8-2,1-3c2.3,0.6,3,1,3,1.3s-0.3,0.5-1,0.5L346.4,489.9z M342.6,507c-0.6,0-0.8-0.3-0.9-1.2
            c0-1.3-0.2-2.6-0.7-3.8c0-0.1,0.1-0.3,0.2-0.2c1.7,1.5,2.4,2.7,2.4,3.8C343.7,506.4,343.2,507,342.6,507z M349.7,503.1
            c-0.6,1.5-1.4,2.9-2.4,4.2c-0.1,0.1-0.3,0-0.3-0.1c0.3-1.2,0.5-2.3,0.7-3.5c0.1-0.8,0.2-1.5,0.2-2.2c2,0.6,2.5,0.9,2.5,1.3
            S350.3,503,349.7,503.1L349.7,503.1z M353.7,504.7c0,3,0,4.6,0,6.1c0,0.7-0.3,1-1.3,1c-0.7,0-1.1-0.3-1.1-0.8
            c0-1.8,0.1-3.5,0.1-10.7c0-1.6,0-3.2-0.1-5.4c-0.6,0.6-1.3,1.2-2,1.7c-0.1,0.1-0.3-0.1-0.3-0.2c1-1.3,1.8-2.8,2.4-4.4
            c0.3-0.8,0.6-1.6,0.8-2.5c2.3,0.9,2.8,1.2,2.8,1.5s-0.2,0.4-0.9,0.5c-0.7,1.1-1.4,2.1-2.3,3c0.7,0.4,1.4,0.8,2,1.2h1.8v-5.4
            c0-0.8,0-1.6-0.1-2.4c2.9,0.3,3.3,0.6,3.3,0.8s-0.3,0.5-0.8,0.6v6.4h1.8c0.7-0.8,0.9-1,1.1-1s0.5,0.2,1.4,0.8
            c0.4,0.3,0.5,0.5,0.5,0.7s-0.1,0.4-0.6,0.7v2c0,9,0,9.8,0,10.2c0.1,0.7-0.1,1.4-0.5,2c-0.5,0.5-1.1,0.7-1.8,0.6
            c-0.6,0-0.7-0.1-0.8-0.9s-0.9-1.1-2.5-1.6c-0.1,0-0.1-0.3,0-0.3c1.3,0.1,2.3,0.2,2.9,0.2c0.4,0,0.5-0.1,0.5-0.3v-4.1L353.7,504.7z
            M360.1,496.3h-6.3v3.5h6.3V496.3z M353.7,504.1h6.3v-3.7h-6.3V504.1z M363.2,495.5c-0.4,0-0.8-0.3-1-0.9c-0.6-1.7-1.6-3.2-2.9-4.4
            c-0.1-0.1,0-0.3,0.1-0.3c3.2,0.9,4.9,2.4,4.9,4.1C364.3,494.8,363.9,495.4,363.2,495.5L363.2,495.5z"/>
          <path class="st2" d="M241.6,503.3v9.4l-0.4,0.9h8.9l-0.4-0.9v-25.6l0.4-0.9h-28.4l0.4,0.9v25.6l-0.4,0.9h8.9l-0.4-0.9v-9.4
            c-0.1-3.2,2.3-5.8,5.5-6c3.2-0.1,5.8,2.3,6,5.5C241.6,503,241.6,503.1,241.6,503.3L241.6,503.3z"/>
        </g>
        <path class="st3 an3" d="M378.9,52.3c0-1.2-0.9-2.3-2.9-4.1c-4.5-3.7-5.7-4.6-7.2-4.6c-1.1,0-2.3,0.8-5.2,4.7H359V18.1h22.3
          c1.7,0,2.3-0.5,2.3-1.4c0-1.2-0.9-2.4-3.5-4.8c-6-4.9-7.2-5.8-8.8-5.8c-1.1,0-2.2,0.8-6.8,6.3h-28c-3.9-2.3-8-4.3-12.2-6
          c0.8,15.5,0.9,30.9,0.9,46.2c0,54-0.9,67.4-0.9,80.9c0,2.9,1.7,4.5,5.7,4.5c5.8,0,8.1-2,8.1-6.4v-6.1h38.2c1.5,0,2-0.5,2-1.5
          s-1.2-2.8-2.8-4.4c-4.5-4.6-5.7-5.8-7.1-5.8c-1,0-2.5,1-6,6.2H359V85.2h4.9v2.2c0,3.5,1.5,4.6,4.9,4.6c4.9,0,7.4-1.2,7.4-5.2
          c0-2.5-0.5-10-0.5-23.5v-6.9C378.5,53.9,378.9,53.4,378.9,52.3z M338.1,79.6V53.9h25.8v25.7H338.1z M338.1,48.3V18.1h8.9v30.1H338.1
          z M347,119.8h-8.9V85.2h8.9V119.8z M414.7,87.3c-4.1-3.4-5.4-4.3-6.9-4.3c-1.1,0-2.3,0.8-5.6,4.9h-11.5c-4.3-2.3-5.7-2.8-11.6-4.9
          c0.5,9.8,0.6,19.7,0.6,29.3c0,17.2-0.6,21.2-0.6,25.3c0,3.1,1.4,4.3,4.9,4.3c5.5,0,7.4-1.5,7.4-5.4v-4.7h11.2v4
          c0,2.9,1.7,4.1,5.4,4.1c4.5,0,6.8-1.7,6.8-5.2c0-4-0.3-15.7-0.3-33.2v-6.8c2.1-1.2,3.1-2.3,3.1-3.5S416.5,89,414.7,87.3z
          M391.4,126.3V93.5h11.2v32.7L391.4,126.3z M458,19.6c-6.6-5.5-8.1-6.8-9.5-6.8c-1,0-2.6,1-7.9,7.5h-37.3c1.6-2.4,3-4.7,4.3-7
          c6-1.1,6.6-1.5,6.6-3.4c0-2.2-3.2-3.5-18.4-8.3c-1.1,5.5-2.4,10.8-4.1,16.1c-3.4,9.9-8.3,19.3-14.4,27.8c-0.5,0.6,0.6,1.8,1.2,1.4
          c4.8-3.4,9.2-7.3,13-11.7c0.7,5.9,1,11.8,1,17.7c0,14.4-0.5,18.1-0.5,21.6c0,3.2,1.8,4.6,5.7,4.6c4.8,0,7.5-1.7,7.5-5.1V72h27.6v2.3
          c0,3.1,2.1,4.3,5.8,4.3c4.9,0,8-1.8,8-5.2c0-2.4-0.5-10-0.5-21v-4.2c3-2.3,3.8-3.2,3.8-4.6s-0.9-2.3-3.2-4.1c-4.9-4-6.3-4.9-7.8-4.9
          c-1.2,0-2.3,0.8-6.8,5.8H405c-5.2-2.3-6.8-2.8-13.3-5.2c2.7-2.9,5.3-5.9,7.6-9.2h48.9l0,0H460c1.5,0,2-0.5,2-1.5S460.8,22,458,19.6z
          M432.8,46v20.4h-27.6V46H432.8z M458.5,87.1c-4-3.5-5.2-4.5-6.8-4.5c-1.1,0-2.3,0.9-6.6,5.3h-13.2c-3.5-1.8-7.2-3.3-10.9-4.6
          c0.5,15.2,0.6,30.3,0.6,45.3c0,4.4-0.2,6.6-0.2,7.8c0,2.9,1.7,4,5.2,4c4.6,0,7.1-1.5,7.1-4.6v-4h12v4.9c0,2.9,1.5,4.3,5.4,4.3
          c4.8,0,7.1-1.7,7.1-5.2c0-4-0.5-16.3-0.5-34.1V95c2.8-2,3.4-2.8,3.4-4S460.1,88.6,458.5,87.1z M433.7,126.3V93.5h12v32.7H433.7z"/>
        <path class="st3 an4" d="M494.7,21.2c3.5,8.1,4.3,8.9,8.1,8.9c4.3-0.1,7.6-3.7,7.5-7.9c0,0,0,0,0-0.1c0-8.8-9.5-14.6-25.8-15.8
          c-0.8,0-1.2,0.8-0.6,1.2C489.8,12.4,492.1,15.2,494.7,21.2z M486.1,56.8c3.7,8,4.1,8.5,7.8,8.5c4.3-0.1,7.7-3.6,7.7-7.8
          c0-8.3-9-13.7-25.5-14.9c-0.8,0-1.2,0.8-0.6,1.2C481.6,48.8,483.2,50.7,486.1,56.8z M611.1,51.3c-7.1-6-8.9-7.1-10.3-7.1
          c-1,0-2.6,1-8.1,8.1h-49.4c0.1-4.4,0.3-9,0.3-13.9v-9.1c19.7-2.3,39-7.4,53.9-14.4c1.7,0.7,3.5,1.1,5.4,1.2c2,0,2.8-0.9,2.8-2.1
          c0-2.1-3.1-5.1-15-14c-3.1,3.1-6.6,5.9-10.3,8.2c-12.3,7.9-26.9,15-36.2,17.2c-7.4-3.5-8.5-4-16.1-7.1c0.6,8.1,0.9,15.8,0.9,29.3
          c0,21.2-1.1,37.2-3.7,50.5c-3.4,16.9-9.5,29.3-19.6,42.4c-0.5,0.6,0.8,1.7,1.4,1.2c14.3-11.4,23.3-22.1,28.9-37.6
          c4.3-11.8,6.4-26.4,7.3-46.2h26.3v41.4c0,23.6-0.5,35.5-0.5,39.3c0,3.4,2.2,4.6,6.8,4.6c5.8,0,9.1-1.7,9.1-5.5
          c0-8.1-0.5-32.7-0.5-52.3V57.9h16.4l0,0h12.7c1.5,0,2-0.5,2-1.5S614.3,53.9,611.1,51.3z M499.5,96.4c6.1-15.3,12.1-30.7,18.3-46
          c0.3-0.8-1.5-1.4-1.8-0.8c-13.4,25.4-20.4,38-24.9,44.4c-3.8,5.5-6,6-9.2,6c-1.1,0-1.2,0-6.9-0.9c-0.8-0.2-1.2,2-0.5,2.3
          c11.7,4,13,5.5,13,15.8c0,4.9-1.2,10-1.2,14.9c0,5.4,2.5,8,7.4,8c5.4,0,9.4-4.4,9.4-10.9c0-8.1-5.8-14.7-5.8-22.7
          C497.4,103,498.2,99.6,499.5,96.4z"/>
        <path class="an2" d="M177.9,19.5H277c1.5,0,2-0.5,2-1.5s-1.1-2.6-4.9-5.5c-8-6-9.7-7.2-11-7.2c-1,0-2.6,1.1-9.2,8.6h-76L177.9,19.5z M295,122.1
          c-2.6-1.2-2.8-1.5-2.8-5.4v-19c0-0.8-1.7-0.9-1.8-0.2c-4,16.6-5.8,23.5-10.8,26.6c-3.5,2-8.3,2.2-16.3,2.2
          c-11.4,0-13.8-1.1-13.8-6.3V55.7h46.2c1.5,0,2-0.5,2-1.5s-1.2-2.3-5.1-5.5c-8.6-6.8-10.3-8-11.7-8c-1,0-2.7,1.1-9.5,9.4H158.8v5.6
          h40.6c0.1,20.6-2.1,35.6-6.8,47.4c-6.1,15.8-17.2,26.4-36.2,37.6c-0.3,0.3-0.4,0.8-0.1,1.1c0.2,0.2,0.4,0.3,0.7,0.3
          c23.8-7.4,38.4-16.3,47-31.8c7.1-12.7,10.4-30.2,10.6-54.7h20.3v37.6c0,11.5-0.1,20.4-0.1,30.3c0,12.7,5.4,15.5,27.6,15.5
          c13.5,0,22.6-0.8,28.3-2.6c5.5-1.7,7.8-4.4,7.8-8.6C298.4,125.1,297.2,123,295,122.1z"/>
        <path class="an1" d="M58.9,36.7h18.7v26H37.3v5.6h101.9c1.5,0,2-0.5,2-1.5s-1.2-2.4-4.2-4.9c-6.8-5.7-8.3-6.9-9.7-6.9c-1,0-2.6,1-8.2,7.7H92.2
          v-26h40.5c1.5,0,2-0.5,2-1.5s-1.2-2.5-4-4.8c-6.3-5.2-8-6.4-9.4-6.4c-1,0-2.6,1-7.8,7.1H92.2V13.5c2.9-1.1,4.4-2.3,4.4-3.8
          S95.1,6.6,77,4.9c0.3,5.7,0.6,11.4,0.6,17.8V31H44.8v5.6h1.7L58.9,36.7z M26.2,51.4c7.3-11.1,13.3-23.1,17.7-35.6
          c5.7-1.5,6.3-1.8,6.3-3.7c0-2-2-2.9-19.8-8.1c-1.1,8.5-3,16.9-5.7,25C18.8,46.7,10.5,63.5,0.1,79c-0.5,0.6,0.9,2.2,1.5,1.7
          C7.7,75.1,13.3,69,18.4,62.5v35.2c0,24.3-1.1,36.2-1.1,40.2c0,4.5,1.8,6,6.8,6c5.7,0,8.3-2.1,8.3-6.4V58.5c3.1-1.4,4-2.2,4-3.5
          C36.3,53.3,35.1,52.8,26.2,51.4z M138.4,129.7c-6.8-5.7-8.4-6.9-9.8-6.9c-1,0-2.6,1-8.5,7.9H92.2V99.8h41.5c1.5,0,2-0.5,2-1.5
          s-1.2-2.4-4-4.8c-6.1-5.2-7.8-6.4-9.2-6.4c-1,0-2.6,1-7.8,7.1H92.2V73H77.6v21.2H43.1v5.6h34.5v30.9H37.3v5.6h103.4
          c1.5,0,2-0.5,2-1.5S141.5,132.3,138.4,129.7z"/>
        <path class="an5" d="M663.9,45.6c0,5.7-2,9.2-5.5,9.2c-1.6,0.1-2.9-1.2-3-2.8c0-0.1,0-0.2,0-0.2c0-1.5,0.6-2.3,2.6-3.3c3.4-1.5,4.6-3.4,4.9-7.2
          c0-0.3,0.7-0.3,0.8-0.1C663.8,42.7,663.9,44.2,663.9,45.6z M682.4,29.4c0,2.1,0.1,3.6,0.1,4.5c0,1.7-0.9,2.4-3.3,2.4
          c-1.8,0-2.5-0.6-2.5-2.1c0-0.8,0.1-2.3,0.1-4.8h-10.9v1c0,1.9-0.9,2.4-3.2,2.4c-2,0-2.6-0.6-2.6-2.1s0.2-3.2,0.2-9.2
          c0-3.3-0.1-6.5-0.3-9.8c2.3,0.9,4.5,2,6.7,3.2H677c0-4.5-0.1-6.3-0.2-9c6.1,0.6,7.1,0.9,7.1,1.9c0,0.5-0.4,0.9-1.7,1.4V15h11.3
          c2.2-2.4,2.6-2.7,3.1-2.7s1,0.3,3.3,2.1c1,0.8,1.3,1.2,1.3,1.7c0,0.6-0.5,1.1-1.7,1.8v2.1c0,5.5,0.1,9.6,0.1,10.8
          c0,1.8-0.9,2.5-3.2,2.5c-1.7,0-2.4-0.6-2.4-2.2v-1.6L682.4,29.4z M676.9,28.1c0.1-2.4,0.1-5.4,0.1-9.2v-2.6h-11v11.9L676.9,28.1z
          M674.1,50.8c0,1.7,0.8,1.8,7.3,1.8c4.1,0,6.1,0,7.6-1.1s2.1-2.9,3.2-6.7c0.1-0.3,0.6-0.2,0.6,0.1c0,1.5-0.1,2.9-0.1,4.9
          c0,1.1,0.1,1.2,1.5,1.8c0.6,0.3,1,1,1,1.7c0,1.3-0.8,2.2-2.8,2.9c-2.3,0.7-6.1,1-12,1c-10,0-11.7-0.9-11.7-6.6
          c0-1.1,0.2-2.3,0.2-7.2c0-2-0.1-3.9-0.3-5.9c6.3,0.7,7.1,0.9,7.1,1.7c0,0.5-0.3,0.8-1.7,1.5L674.1,50.8z M684.9,47.8
          c-1.7,0-2.1-0.5-3-3.5c-0.7-2.2-1.8-3.5-4.1-5.7c-0.2-0.2,0.1-0.5,0.3-0.5c6.2,1,9.6,3.3,9.6,6.5c0.1,1.6-1,3-2.6,3.1
          C685,47.7,685,47.7,684.9,47.8L684.9,47.8z M694,28.1V16.2h-11.8l0.2,11.9H694z M702.6,54.6c-1.8,0-2.3-0.7-3.2-4.4
          c-1-4-1.9-5.7-4.2-8.2c-0.1-0.2,0-0.4,0.1-0.5c0.1,0,0.2-0.1,0.3,0c6.5,2.1,10,5.4,10,9.4C705.6,53,704.5,54.6,702.6,54.6
          L702.6,54.6z M753.8,23.6c2.9-3.2,3.5-3.7,3.9-3.7s1.2,0.5,3.7,2.6c1.1,0.9,1.5,1.4,1.5,1.8s-0.2,0.6-0.7,0.6h-17.1
          c-2.3,2.1-4.7,4.1-7.3,5.8h8.9c2.4-2.4,2.9-2.7,3.4-2.7s1,0.3,3.5,2.5c1.2,1,1.5,1.4,1.5,1.9c0,0.8-0.8,1.1-2.9,1.2
          c-3.3,1.9-6.8,3.4-10.5,4.5c-0.1,0.5-0.6,0.9-1.7,1.5v2.5h12.7c2.8-3.1,3.4-3.5,3.8-3.5s1.1,0.5,3.7,2.5c1.1,0.9,1.6,1.4,1.6,1.8
          s-0.2,0.6-0.7,0.6h-21v3.1c0,4.3,0.2,5.3,0.2,6.8c0,1.2-0.2,2.3-0.8,3.4c-1,1.7-2.9,2.5-5.7,2.5c-1.2,0-1.2-0.1-1.5-1.8
          c-0.3-1.5-2.1-2.6-6.4-3.6c-0.3-0.1-0.3-0.8,0-0.8c2.9,0.2,5.5,0.3,8.1,0.3c0.5,0,0.7-0.2,0.7-0.9v-9h-14c-1.2,0-2.4,0.1-3.6,0.3
          l-0.6-1.8c-2,0.7-4,1.3-6.1,1.8c-0.3,0.1-0.6-0.7-0.3-0.8c7.1-2.8,13.8-6.5,20-10.9h-7.4c-1.1,0-2.2,0.2-3.3,0.5l-0.6-2.2
          c1.2,0.3,2.5,0.5,3.8,0.5h9.4c2.4-1.8,4.8-3.8,7-5.8h-22.9c-1.3,0-2.7,0.1-4,0.3l-0.6-2.1c1.4,0.3,2.9,0.4,4.3,0.5h15.6v-8h-9.8
          c-1.2,0-2.3,0.1-3.5,0.3l-0.6-2.1c1.3,0.3,2.7,0.5,4,0.4h10v-1.9c0-2.1-0.1-4.3-0.4-6.4c7,0.6,7.7,1.3,7.7,1.9s-0.6,1-1.9,1.5v4.9
          h3.7c2.3-2.7,3-3.2,3.4-3.2s1.1,0.5,3.2,2.2c0.5,0.3,0.9,0.8,1.2,1.3c1.6-2.1,3-4.4,4.2-6.8c4.6,2.4,6.4,3.6,6.4,4.3
          s-0.8,1.1-2.9,1.1c-2.9,3.7-6.1,7.2-9.5,10.4L753.8,23.6z M736,32.1c-6,4-12.3,7.2-19,9.7c1.2,0.2,2.3,0.3,3.5,0.3h14.2v-0.7
          c0-1.8,0-3.6-0.2-5.4c3.3,0.5,5,0.8,6.1,1.1c2.2-1.4,4.2-3,6-4.9H736z M740.5,23.6c2.6-2.5,5-5.2,7.2-8h-10.7v8H740.5z M774.6,29
          c-2.1,2.3-4.3,4.4-6.7,6.2c-0.2,0.2-0.7-0.3-0.6-0.5c4.7-6.1,8.2-13,10.3-20.4c0.8-2.6,1.3-5.3,1.7-7.9c5.2,1.4,7.6,2.3,7.6,3.2
          c0,0.6-0.6,1-2,1.3c-1.6,4.5-3.7,8.8-6.2,12.9c2.3,0.4,2.7,0.7,2.7,1.2s-0.3,0.8-1.5,1.3V31c0,13,0.1,21.9,0.1,24.8
          c0,1.6-1,2.3-3.2,2.3c-1.8,0-2.5-0.6-2.5-1.9s0.3-6.1,0.3-15.2L774.6,29z M810.2,50.6c3-4,3.7-4.4,4.1-4.4s1.1,0.5,4.1,3
          c1.4,1.2,1.8,1.7,1.8,2.1s-0.2,0.6-0.7,0.6h-32c-1.6,0-3.2,0.1-4.8,0.3l-0.6-2.1c1.7,0.3,3.4,0.5,5.2,0.5L810.2,50.6z M808.2,18
          c2.9-3.7,3.6-4.1,4-4.1s1.1,0.4,3.8,2.9c1.2,1.1,1.7,1.6,1.7,2s-0.2,0.6-0.8,0.6h-27.5c-1.2,0-2.5,0.1-3.7,0.4l-0.6-2.1
          c1.4,0.3,2.8,0.5,4.2,0.5H808.2z M857.8,39c2.4-2.2,2.9-2.5,3.3-2.5s1,0.3,3.4,2.3c1,0.9,1.4,1.3,1.4,1.7c0,0.8-0.4,1.1-2.3,1.7
          c-2.4,2.7-5,5-7.8,7.2c1.1,0.4,2.2,0.8,3.3,1.1c5.2,1.4,11.5,2,19.6,2c0.3,0,0.2,0.6,0,0.6c-2.2,0.6-3.2,1.7-3.8,4.1
          c-0.2,1.2-0.6,1.6-1.5,1.6c-1-0.1-2.1-0.2-3.1-0.4c-4-0.8-7.8-2-11.6-3.5c-2.2-0.9-4.2-2-6.2-3.2c-7.1,4.2-15.5,6.4-26.6,7
          c-0.3,0-0.5-0.6-0.2-0.6c9.3-1.8,17.2-4.8,23.2-9c-2.3-2.1-4.2-4.6-5.7-7.3c-4.3,5.1-9.8,9-16,11.3c-0.3,0.1-0.6-0.3-0.3-0.5
          c5.1-3.6,9.5-8,13-13.2c1-1.5,1.8-3.1,2.4-4.9c4.3,0.9,5.9,1.6,5.9,2.4c0,0.5-0.3,0.6-1.8,0.9c-0.3,0.5-0.7,1-1,1.4L857.8,39z
          M839.6,19.9c-0.5-0.2-0.8-0.9-1-2.1c-0.2-1.8-1-3.5-2.3-4.8h-0.5c-1.8,0-3.5-0.1-5.1-0.2c-0.3,0-0.3-0.9,0-0.9
          c10.7-0.9,21.2-2.5,28-4.3c2.2-0.5,4.4-1.3,6.4-2.5c4.8,2.9,6.2,3.9,6.2,4.6s-1.2,0.9-3,0.4c-6.4,1.2-12.9,2-19.4,2.5
          c3.8,1.2,5.7,2.9,5.7,4.7c0,1.2-0.9,2.3-2.1,2.6h8c0.8-1.6,1.5-3.3,2.1-5c0.4-1.2,0.8-2.5,1-3.8c5.4,1.8,6.4,2.4,6.4,3.1
          s-0.5,1-2.3,1c-1.6,1.8-3.4,3.3-5.4,4.7h6.8c2-2.6,2.5-3,2.9-3s1,0.3,4.1,3.1c1.4,1.3,1.8,1.7,1.8,2.2c0,0.8-0.8,1.2-2.3,1.1
          c-0.8-0.2-1.7-0.1-2.4,0.3c-1.3,0.9-2.6,1.6-4,2.3c-0.3,0.1-0.5,0-0.4-0.3c0.6-1.4,1.1-2.9,1.4-4.5h-37.7c0.3,4.1-0.9,7.9-3.9,7.9
          c-1.6,0-2.9-1.2-2.9-2.8c0-0.1,0-0.1,0-0.2c0-1.1,0.6-2.1,1.5-2.5c3-1.8,3.5-2.9,3.7-6.6c0-0.3,0.5-0.4,0.6-0.1
          c0.4,1,0.6,2.1,0.8,3.1L839.6,19.9z M836.6,28.5c0,3.8-1.3,7.5-4.5,7.5c-1.5,0.1-2.7-1.1-2.8-2.6c0-0.1,0-0.2,0-0.3
          c0-1.3,0.5-2,2.1-2.7c2.6-1.3,3.7-3,4-6.2c0-0.3,0.5-0.4,0.6-0.1C836.4,25.5,836.6,27,836.6,28.5L836.6,28.5z M851.4,19.9
          c-0.7-0.2-1.2-0.8-1.4-2c-0.2-1.9-1-3.6-2.2-5.1l-0.1-0.1c-3.2,0.2-6.4,0.4-9.6,0.4c3.2,1,4.9,2.7,4.9,4.5c0,1-0.7,2-1.6,2.3H851.4z
          M861.8,28.1c0,0.9,0.1,1,1.6,1.4c0.7,0.2,1.3,0.9,1.3,1.7c0,1.2-0.9,2.2-2.1,2.4c-2,0.7-5.7,1-10,1c-10.1,0-11.7-0.8-11.7-4.9
          c0-0.6,0-1.1,0-3.5c0-1.3-0.1-2.7-0.2-4c5.8,0.7,6.3,0.8,6.3,1.6c0,0.6-0.5,1-1.5,1.2V29c0,1.5,0.9,1.8,5.3,1.8c5,0,6.8-0.1,8-0.9
          s1.7-2.1,2.6-4.8c0-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2L861.8,28.1z M844.3,40.4c-0.2,0.2-0.3,0.3-0.5,0.5
          c2.1,2.5,4.6,4.6,7.4,6.3c2.5-1.9,4.8-4.3,6.6-6.9H844.3z M853.7,29.4c-1.3,0-1.5-0.3-2.2-3c-0.6-1.6-1.6-3-2.9-4.1
          c-0.2-0.2-0.1-0.5,0.2-0.4c4.9,0.8,7.4,2.4,7.4,4.9C856.3,28.3,855.2,29.4,853.7,29.4C853.7,29.4,853.7,29.4,853.7,29.4L853.7,29.4z
          M870.7,35.2c-1.6,0-2-0.5-2.9-3.5c-1.1-3.3-2.2-4.9-4.2-6.5c-0.2-0.2,0-0.6,0.3-0.5c6.6,1.4,9.6,3.7,9.6,7.1
          C873.4,33.8,872.3,35.2,870.7,35.2L870.7,35.2z M917.3,13.8c2.4-2.6,2.9-2.9,3.3-2.9s1,0.3,3.5,2.6c1.1,1,1.4,1.4,1.4,1.9
          c0,0.8-0.3,1-2.5,1.7c-5.4,8.9-11.6,15.3-19.7,20.3l0.1,0.1h19.1c2.1-2.4,2.6-2.8,3-2.8s1,0.3,3.2,2.2c1,0.9,1.3,1.3,1.3,1.7
          s-0.4,0.8-1.6,1.7v2.7c0,7,0.2,11.8,0.2,13.5s-1.1,2.2-3.4,2.2c-1.8,0-2.3-0.5-2.3-2V55H903v1.4c0,1.6-1,2.2-3.3,2.2
          c-1.8,0-2.4-0.5-2.4-2.1c0-2.3,0.3-4.7,0.3-14.1c0-0.7,0-1.4-0.1-2.1c-4.5,2.1-9.2,3.9-14,5.2c-0.3,0-0.6-0.6-0.3-0.7
          c7.7-3.3,14.8-7.8,21-13.4c-0.2,0.1-0.4,0.1-0.6,0.1c-1.2,0-1.8-0.7-2.4-2.8c-0.5-2.5-1.8-4.8-3.6-6.5c-3.8,3.1-8,5.6-12.5,7.5
          c-0.3,0.1-0.7-0.4-0.5-0.6c5.9-4.2,10.9-9.6,14.7-15.7c1.5-2.4,2.7-5,3.6-7.7c5.7,1.3,7,1.9,7,2.9c0,0.8-0.5,1.1-2.2,1.6
          c-0.8,1.3-1.5,2.5-2.3,3.7H917.3z M904.5,15.1c-1.8,2.4-3.9,4.6-6.1,6.5c5,1.3,8,3.8,8,6.6c0,0.5-0.1,1.1-0.3,1.5
          c4.6-4.3,8.4-9.2,11.5-14.7L904.5,15.1z M922.9,38.7H903v15h19.9V38.7z M940.7,57.8c-0.3,0.1-0.6-0.5-0.4-0.6
          C955.9,46,962,33.8,962,12.9c0-1.6-0.1-3.2-0.3-4.8c7.5,0.7,8.3,0.9,8.3,1.9c0,0.6-0.3,0.8-2,1.6c-0.1,1.8-0.1,3.5-0.2,5.3
          c0.6,7,2.9,13.7,6.6,19.6c4.4,6.9,11,12.1,18.7,15c0.3,0.1,0.3,0.6,0,0.7c-2,0.6-3,1.7-3.8,4.1c-0.3,1-0.6,1.2-1.1,1.2
          c-1.1-0.3-2.1-0.9-3-1.7c-6.2-4.8-11.1-11.2-14.2-18.4c-1.9-4.9-3.1-10.1-3.5-15.3C965.3,40.3,957.7,50,940.7,57.8z M1024.2,17.6
          c1.8-1.9,2.2-2.3,2.7-2.3s1,0.3,2.9,2.1c0.8,0.8,1.1,1.3,1.1,1.7c0,0.6-0.4,0.8-1.9,1c-0.5,0-1,0.2-1.4,0.5
          c-0.9,0.7-1.9,1.2-2.9,1.7c0.3,0.2,0.5,0.5,0.5,0.9c0,0.3-0.3,0.6-0.6,0.6l-10,1v1.4c0,1.1,0.5,1.4,2.1,1.4c2.9,0,4.4-0.1,5.3-0.5
          s1.2-1.3,1.7-2.8c0-0.3,0.7-0.2,0.7,0.1v1.3c0,0.6,0.1,0.8,0.7,1c0.9,0.4,1.3,0.9,1.3,1.6c0,1.2-0.9,2-2.9,2.5
          c-2.2,0.4-4.5,0.6-6.8,0.5c-4.7,0-6.3-0.6-6.3-3.8v-2l-1.7,0.2c-0.8,0.1-1.6,0.2-2.3,0.5l-0.7-2c1,0.2,2.1,0.2,3.1,0.1l1.7-0.2v-0.6
          c0-0.9-0.1-2.3-0.2-3.7c5.5,0.6,5.8,0.6,5.8,1.3c0,0.4-0.4,0.8-1.4,1.2v1.4l4.3-0.5c1.4-2.3,1.8-2.6,2.3-2.6s1.2,0.3,2.9,1.4
          c0.3-1,0.5-2,0.6-3h-19.5v2c0,10-0.5,17.1-1.6,22.8c-1,5.3-3.2,10.2-6.3,14.5c-0.2,0.2-0.7-0.2-0.6-0.5c1.6-4.7,2.6-9.6,3-14.5
          c0.5-6.2,0.8-12.4,0.7-18.7c0-3.8-0.1-6.1-0.2-10.2c2.8,1.5,3.2,1.7,5.8,3.1h4.8v-5.7c0-1.8-0.1-3.7-0.2-5.6c6.3,0.5,6.9,1,6.9,1.5
          s-0.6,0.9-1.7,1.4v3.3h3.5c2.2-2.7,2.9-3.1,3.3-3.1s1.2,0.5,3.1,2.2c1,0.8,1.4,1.3,1.4,1.7s-0.2,0.6-0.7,0.6h-10.7v3.7L1024.2,17.6z
          M1011,45.5c0,0.4-0.2,0.7-0.5,1c3,0.8,4.3,1.9,4.3,4c0,0.7-0.2,1.3-0.7,1.8c1.8-0.1,2.8-0.2,3.6-0.3c0.3-1,0.5-1.8,0.7-2.9
          c0.2-1,0.3-2,0.4-3c3.5,1.3,4.9,1.8,4.9,2.5c0,0.4-0.2,0.5-1.5,0.7c-0.7,0.9-1.5,1.7-2.3,2.5c2.7-0.3,5.4-0.8,8-1.4
          c0.3,0,0.4,0.5,0.2,0.6c-4.7,2.5-14.1,5.3-21.5,6.5c-0.3,1.2-0.5,1.6-1.1,1.6s-0.9-1-2.2-6.1c1.7,0,3.6-0.1,5.6-0.2l2.6-0.2
          c-0.4-0.5-0.6-1-0.6-1.6c-0.2-1.4-0.7-2.7-1.3-3.9c-0.4,0.1-0.8,0.1-1.1,0.1c-1.4,0-2-0.4-2-1.5c0-0.8,0.2-1.5,0.2-4.5
          c0-1.5-0.1-3-0.2-4.6c2.5,0.9,2.8,1.1,5.1,2.1h9.6c1.5-1.6,1.9-2,2.4-2s1.1,0.3,2.7,1.6c0.7,0.5,1,0.9,1,1.4s-0.2,0.6-1.2,1.3v0.6
          c0,1.5,0.2,2.6,0.2,3c0,1.5-0.8,2.1-2.8,2.1c-1.1,0-1.7-0.3-1.8-1.1L1011,45.5z M1021.4,33.8c1.7-2.1,2.3-2.5,2.7-2.5
          c0.4,0,1.2,0.5,2.9,1.8c0.8,0.6,1.3,1,1.3,1.4s-0.2,0.6-0.7,0.6h-18.8c-1,0-2,0.1-2.9,0.3l-0.6-2.1c1.1,0.3,2.3,0.5,3.4,0.5
          L1021.4,33.8z M1021.4,39.9H1011v4.3h10.4V39.9z M1037.3,25.9c0.1,4,0.7,8,1.5,11.9c0.5-1,0.9-2,1.2-2.9c0.7-2,1.2-4.2,1.5-6.3
          c4.9,1.6,6.5,2.5,6.5,3.1c0,0.7-0.5,0.9-2.1,1.3c-1.3,3.7-3,7.2-5.2,10.5c0.7,1.6,1.5,3.2,2.5,4.6c1,1.5,2,2.6,2.6,2.6
          s1.8-1.8,3.7-5.2c0.1-0.2,0.6,0,0.6,0.3c-1.2,5.7-1.2,6.1-1.2,6.4c0,0.5,0.1,0.8,0.9,2c0.3,0.5,0.5,1,0.5,1.6c0,1.4-1.1,2.6-2.5,2.6
          c-1.9,0-4.6-2-7-5.2c-1.3-1.7-2.5-3.5-3.4-5.4c-4.3,4.7-9.7,8.2-15.7,10.4c-0.3,0.1-0.6-0.5-0.3-0.7c5.6-3.6,10.4-8.3,14.1-13.8
          c-1.8-5.5-2.9-11.1-3.2-16.9l-2.1,0.4c-0.9,0.2-1.7,0.5-2.5,0.9l-0.9-2.1c1.1,0.1,2.2,0.1,3.3-0.1l2.2-0.4
          c-0.2-3.5-0.5-7.8-0.6-12.9c-0.1-2.5-0.1-3.4-0.3-5.8c6.1,0.8,7.2,1.1,7.2,1.9c0,0.5-0.4,0.8-1.7,1.3c0.1,6.2,0.2,10.9,0.4,14.6
          l4.8-0.9c1.9-3.4,2.3-3.7,2.8-3.7s1.1,0.3,3.5,1.7c1,0.6,1.4,1,1.4,1.5c0,0.2-0.2,0.5-0.4,0.5L1037.3,25.9z M1043.3,19.2
          c-1.2,0-1.7-0.6-2.3-3.1c-0.5-1.9-1.4-3.7-2.7-5.1c-0.2-0.2-0.2-0.5,0.1-0.4c4.6,0.9,7.3,2.9,7.3,5.7
          C1045.8,17.7,1044.8,19.1,1043.3,19.2L1043.3,19.2z M1097.5,13c2.8-3.1,3.2-3.5,3.7-3.5s1.1,0.4,3.9,2.4c1.3,1,1.7,1.4,1.7,1.8
          s-0.2,0.6-0.9,0.6h-43.1c0,1.5,0.1,2.9,0.1,5c0,10.3-0.3,16.3-1.8,22.4c-1.2,5.6-3.6,10.8-7.2,15.3c-0.2,0.2-0.8-0.2-0.7-0.5
          c1.9-4.9,3.2-10,3.6-15.3c0.6-5.7,0.7-12,0.7-21.7c0.1-3.2-0.1-6.5-0.5-9.7c2.3,0.8,4.5,1.9,6.6,3.1h15.2v-2.2
          c0-1.6-0.1-3.1-0.3-4.6c7,0.7,7.7,1.4,7.7,2s-0.7,1.1-2,1.7V13H1097.5z M1075.5,35.8c0,13.7,0.2,18.3,0.2,20.3c0,1.4-1,2.2-2.9,2.2
          c-1.5,0-2.3-0.5-2.3-1.9c0-2.2,0.5-11,0.5-18.7c-2.8,4.5-6.5,8.4-10.7,11.6c-0.2,0.2-0.7-0.2-0.5-0.5c4.8-6.2,8.2-13.3,10-20.9h-2.6
          c-1.1,0-2.2,0.1-3.3,0.3l-0.6-2.1c1.2,0.3,2.5,0.5,3.7,0.5h3.8v-5.4c0-1.5-0.1-3.1-0.2-4.6c5.6,0.5,6.8,0.8,6.8,1.7
          c0,0.7-0.3,1-1.7,1.6v6.8h0.5c1.8-2.3,2.5-2.7,2.9-2.7s1.1,0.5,3,1.9c0.8,0.6,1.3,1.1,1.3,1.5s-0.2,0.6-0.7,0.6h-6.9v5.8
          c4.3,0.8,6.9,2.7,6.9,5.1c0.1,1.4-1,2.7-2.4,2.8c0,0,0,0,0,0c-1.1,0-1.5-0.4-2.2-2.2c-0.4-1.5-1.2-2.9-2.2-4.1L1075.5,35.8z
          M1088.5,55.8c0,1.4-1,2.3-2.9,2.3c-1.4,0-2.2-0.7-2.2-2c0-4,0.3-8.1,0.3-24.3c0-4.7-0.1-9.4-0.5-14.1c2.1,0.8,4.1,1.8,6,2.9h9.3
          c1.9-2.2,2.3-2.6,2.8-2.6s1,0.3,3.1,2c0.9,0.8,1.3,1.2,1.3,1.7c0,0.6-0.4,1-1.8,2v5.2c0,13.9,0.3,23.5,0.3,26.6c0,1.7-1,2.6-2.9,2.6
          c-1.6,0-2.3-0.7-2.3-2.1v-1.5h-10.5L1088.5,55.8z M1098.9,21.8h-10.5V31h10.5V21.8z M1088.5,41.8h10.5v-9.5h-10.5V41.8z
          M1088.5,53.1h10.5v-10h-10.5V53.1z M660.5,102.9c-2.2,2.6-4.7,5-7.4,7.2c-0.2,0.2-0.7-0.3-0.6-0.5c4.5-5.7,8-12.1,10.3-19.1
          c1.1-3.1,1.9-6.3,2.3-9.6c5.2,1.4,7.6,2.4,7.6,3.1s-0.6,1-2,1.3c-1.9,4.8-4.3,9.4-7.1,13.6c3.2,0.5,3.6,0.6,3.6,1.3
          s-0.3,0.8-1.5,1.4v29.6c0,1.7-1,2.4-2.9,2.4s-2.5-0.3-2.5-2.2c0-1.5,0.3-6,0.3-15.1V102.9z M688.3,128.9h6.7c3-4,3.7-4.5,4.1-4.5
          s1.1,0.5,4.1,3.1c1.4,1.2,1.8,1.7,1.8,2.1s-0.2,0.6-0.8,0.6h-30.4c-1.6,0-3.3,0.1-4.9,0.4l-0.6-2.1c1.7,0.3,3.4,0.5,5.2,0.5h9.1
          v-25.4h-10.1c-1.2,0-2.5,0.1-3.7,0.3l-0.6-2.1c1.4,0.3,2.8,0.5,4.2,0.5h10.2V90.4c0-2.7-0.1-5.5-0.3-8.2c7.3,0.7,8,1.3,8,2
          s-0.7,1.1-2.1,1.6v16.4h8.4c3-3.5,3.7-4,4.1-4s1.1,0.5,3.8,2.7c1.1,1,1.6,1.6,1.6,1.9s-0.2,0.6-0.8,0.6h-17.2V128.9z M717.5,105.1
          c2.3-3.4,4.3-7.1,6-10.8c0.9-1.9,1.6-3.8,2.2-5.8c4.5,2.1,6.2,3.2,6.2,3.9c0,0.5-0.6,0.9-2.2,0.9c-3,4.3-6.5,8.3-10.4,11.8
          c2.8-0.1,5.6-0.2,8.4-0.3c-0.4-1.6-1-3.2-2-4.6c-0.2-0.2,0.2-0.5,0.5-0.3c4.2,2.5,6.2,4.8,6.2,7.5c0,1.8-1,3.2-2.3,3.2
          c-1.2,0-1.7-0.6-2.1-4.3c-1.2,0.4-2.3,0.8-3.4,1.1v7.9c0,6.1,0.2,13.8,0.2,16.3c0,1.5-1,2.2-2.9,2.2c-1.6,0-2.3-0.5-2.3-1.8
          s0.3-5,0.3-12.4v-4c-0.4,0.2-0.9,0.4-1.3,0.5c-1.5,4.8-3.9,9.2-7.1,13c-0.2,0.2-0.8-0.1-0.6-0.3c1-2.9,1.8-5.9,2.3-9
          c0.5-2.5,0.8-5,0.9-7.6c3.6,1.1,5.2,1.6,5.8,2.1v-5.7c-1.4,0.3-3.1,0.7-5.2,1.2c-0.3,1.1-0.7,1.6-1.2,1.6c-0.8,0-1.2-1.1-2.1-6.2
          c1,0.1,2,0.1,3,0.1L717.5,105.1z M719.2,85.1c0.4-1.5,0.7-2.9,0.9-4.4c5.1,1.8,6.9,2.7,6.9,3.4s-0.7,1-2.3,1c-1.9,2.6-4,5-6.3,7.3
          c1.5,0.9,2.4,2.5,2.3,4.2c0,1.8-1.2,3.1-2.6,3.1s-1.7-0.2-2.6-3.4c-0.5-2.2-1.7-4.2-3.4-5.8c-0.2-0.2,0-0.6,0.3-0.6
          c1.6,0.4,3.2,1,4.7,1.8C718,89.5,718.7,87.3,719.2,85.1L719.2,85.1z M729.2,124.9c-1.5,0-1.8-0.6-2.1-4.5c-0.1-2.6-0.8-5.1-1.9-7.4
          c-0.1-0.2,0.3-0.5,0.5-0.3c4.3,3.3,6,5.7,6,8.7C731.8,123.4,730.8,124.9,729.2,124.9L729.2,124.9z M749.8,116
          c0.1,7.4,0.2,12.7,0.2,15.2c0,1.8-1,2.6-3.2,2.6c-1.9,0-2.5-0.7-2.5-2.1c0-2.3,0.1-7.3,0.2-15.7h-6.1v1.8c0,1.5-1.2,2.1-3,2.1
          c-1.6,0-2.1-0.6-2.1-1.7c0-2.9,0.2-5.9,0.2-17.6c0-3.8,0-7.6-0.3-11.3c2,0.9,3.9,1.9,5.7,3h5.7v-0.9c0.1-3.4,0-6.7-0.3-10.1
          c6.5,0.6,7.4,0.9,7.4,1.9c0,0.7-0.6,1.1-2,1.7v7.3h6.2c2-2.3,2.5-2.6,2.9-2.6s1,0.3,3,2c0.9,0.8,1.2,1.3,1.2,1.7
          c0,0.6-0.5,1.1-1.7,1.8v3.7c0,9.8,0.3,16.5,0.3,18.8c0,1.5-1.1,2.2-3.1,2.2c-1.5,0-2.2-0.5-2.2-1.7v-2H749.8z M744.6,93.6h-6.2v9.4
          h6.2V93.6z M744.5,114.7c0-0.8,0-1.4,0.1-2.2v-8.2h-6.1v10.4H744.5z M756.4,103v-9.5h-6.7v9.5H756.4z M756.4,114.7v-10.4h-6.6
          c0,2.9,0,5.7,0.1,8.6v1.8L756.4,114.7z M783.5,93.8h2.2c2-2.5,2.5-2.9,2.9-2.9s1.2,0.3,2.9,2c0.8,0.7,1.2,1.3,1.2,1.7
          s-0.2,0.6-0.7,0.6h-8.5v5.2h3.7c2-2.6,2.7-3,3.1-3s1.2,0.5,3,2.1c0.8,0.8,1.4,1.3,1.4,1.7s-0.2,0.6-0.8,0.6h-22.3
          c-1,0-1.9,0.1-2.9,0.3l-0.7-2.2c1.2,0.3,2.4,0.5,3.7,0.5h6.8v-5.2h-4.3c-0.9,0-1.7,0.1-2.5,0.3l-0.6-2.1c1,0.3,2,0.5,3,0.5h4.5v-4.8
          h-6.1c-0.9,0-1.7,0.1-2.6,0.3l-0.6-2.1c1,0.3,2,0.5,3.1,0.5h6.2v-1.3c0-1.9-0.1-3.8-0.3-5.7c6.4,0.6,7,1.2,7,1.8s-0.6,0.9-1.8,1.4
          v3.8h3.2c2.1-2.6,2.7-3.1,3.1-3.1s1.2,0.3,3.2,2.1c0.9,0.8,1.4,1.3,1.4,1.7s-0.2,0.6-0.8,0.6h-10V93.8z M775.3,119.3
          c0,8.4,0.1,10.8,0.1,12c0,1.6-1,2.3-3,2.3c-1.5,0-2.2-0.5-2.2-2.1c0-3.2,0.3-7.7,0.3-16.4c0-6.8,0-8.2-0.2-11.4
          c1.8,0.7,3.6,1.6,5.3,2.6h10.5c1.5-2,2.1-2.3,2.5-2.3s1,0.2,2.8,1.9c0.8,0.7,1.1,1.2,1.1,1.7s-0.2,0.7-1.2,1.6v3.2
          c0,11.8,0.2,13.9,0.2,15.1c0,2.2-0.5,3.8-1.6,4.8c-1.3,0.8-2.8,1.2-4.3,1.1c-0.7,0-0.8-0.1-1-1.3c-0.3-2-1.4-3-4.6-4.2
          c-0.3-0.1-0.3-0.8,0-0.7c2,0.3,4,0.4,6,0.4c0.5,0,0.6-0.2,0.6-0.9v-7.4L775.3,119.3z M778.8,118v-10.4h-3.5V118L778.8,118z
          M786.6,107.6h-3.4V118h3.4V107.6z M817.2,85.4c-0.6,0-1.3-0.1-1.9-0.3c-4.7,1.4-9.5,2.4-14.4,2.8c-1.9,3.9-4.7,7.3-8.2,9.7
          c-0.2,0.2-0.6-0.2-0.5-0.4c1.5-2.7,2.6-5.6,3.4-8.6c0.4-1.4,0.6-2.9,0.6-4.4c1.6,0.6,3.1,1.4,4.5,2.2c3.1-0.9,6.1-2.2,8.9-3.8
          c1.1-0.6,2.2-1.4,3.2-2.2c4.5,2.8,5.6,3.7,5.6,4.2S818,85.4,817.2,85.4z M807.8,118.1c0,3.3,0.1,8.1,0.1,9.7c0.1,1-0.1,2-0.6,2.9
          c-0.8,1.5-2.9,2.5-5.2,2.5c-1,0-1.4-0.2-1.5-0.8c-0.3-2.7-1.5-3.7-5.4-4.6c-0.3-0.1-0.3-0.8,0-0.8c2.3,0.2,4.7,0.3,6.1,0.3
          c1.2,0,1.4-0.2,1.4-0.8v-8.6h-5.3c-1,0-2,0.1-3,0.4l-0.6-2.1c1.2,0.3,2.4,0.4,3.6,0.4h5.3v-8.2h-5.3c-1.3,0-2.6,0.1-3.9,0.3
          l-0.6-2.1c1.5,0.3,3,0.4,4.5,0.4h5.3v-7.5h-3.4c-1.3,0-2.5,0.2-3.8,0.4l-0.6-2.2c1.4,0.3,2.9,0.5,4.3,0.5h12.3c1.5-2,2-2.3,2.5-2.3
          s1.1,0.3,3.2,1.9c0.9,0.7,1.3,1.1,1.3,1.5s-0.2,0.6-1.5,1.6v6.1h0.2c1.4-2.7,1.9-3.2,2.3-3.2s1.1,0.4,2.4,2.3c0.5,0.6,1,1.3,1,1.7
          s-0.2,0.6-0.7,0.6H817c0.1,5.9,0.2,9.9,0.2,11.4s-1,2.1-3,2.1c-1.4,0-2.1-0.5-2.1-1.9v-2.1L807.8,118.1z M806.6,97.2
          c-1.4,0-2.1-0.8-2.1-2.8c0-2-0.4-4.1-1.3-5.9c-0.1-0.3,0.3-0.5,0.5-0.3c3.8,2.5,5.4,4.2,5.4,6.4C809.2,96,808,97.2,806.6,97.2z
          M807.8,107.2h4.3v-7.5h-4.3V107.2z M812.1,108.6h-4.3v8.2h4.3V108.6z M817.6,96.7c-1.6,0-2.1-0.7-2.7-3c-0.5-2.4-1.6-4.7-3.3-6.5
          c-0.2-0.2,0.2-0.5,0.5-0.4c5.5,2.4,8,4.7,8,7C820.2,95.2,819.1,96.5,817.6,96.7L817.6,96.7z M837.6,111.8c-3.7,2.8-7.9,4.9-12.3,6.4
          c-0.3,0.1-0.6-0.5-0.4-0.6c7.4-4.3,13.1-10.3,15.9-16.8h-10c-1.4,0-2.7,0.1-4.1,0.3l-0.6-2.1c1.4,0.3,2.9,0.5,4.4,0.5h10.2v-8.7
          h-6.6c-1.4,0-2.8,0.1-4.1,0.3l-0.6-2.1c1.5,0.3,3,0.4,4.6,0.4h6.8v-2.7c0-2.1-0.1-3.8-0.3-5.6c6.7,0.6,7.3,1.1,7.3,1.7
          c0,0.4-0.6,0.9-1.9,1.6v5.1h10v-2.7c0-2.1-0.1-3.8-0.3-5.6c6.8,0.6,7.3,1.1,7.3,1.7c0,0.4-0.6,0.9-1.8,1.6v5.1h4.8
          c2.6-3,3.3-3.4,3.7-3.4s1.2,0.5,3.7,2.5c1,0.8,1.4,1.3,1.4,1.7s-0.2,0.6-0.8,0.6h-12.9v8.6h7c3-3.4,3.7-3.9,4.1-3.9s1.1,0.5,3.9,2.8
          c1.2,1,1.7,1.5,1.7,1.9s-0.2,0.6-0.7,0.6h-18.2c1.9,3.1,4.6,5.6,7.8,7.2c3.8,2,7.9,3.4,12.1,4.1c0.3,0.1,0.2,0.8-0.1,0.9
          c-1.9,0.9-2.2,1.2-3.2,3.8c-0.3,0.7-0.5,0.9-0.9,0.9c-0.6-0.1-1.2-0.3-1.7-0.6c-2.8-1.2-5.5-2.9-7.8-5c-0.3-0.3-0.7-0.6-1-1
          c-0.2,0.1-0.3,0.3-0.5,0.4v1.9c0,3.8,0.1,5.7,0.1,6.4c0,1.4-0.8,2-2.8,2c-1.5,0-2.2-0.5-2.2-1.7v-1h-15.9v7.3c0,1.5,1,1.7,10.5,1.7
          c8.8,0,10.9-0.5,12.1-1.8c0.8-0.8,1.4-2.4,2.5-6.1c0-0.3,0.7-0.3,0.7,0c-0.1,1.7-0.1,4.2-0.1,5.3c0,0.5,0.1,0.7,0.9,1
          c0.8,0.4,1.2,1.2,1.2,2c0,1.4-0.8,2.4-2.9,3c-2.7,0.9-7.7,1.1-16.4,1.1c-11.7,0-13.6-0.8-13.6-5.6c0-1.9,0.1-7.6,0.1-13.9V111.8z
          M846.8,100.7c-1.4,2.7-3.2,5.3-5.2,7.6c0.3,0.2,0.7,0.4,1.1,0.6h15.3c1.5-1.7,2-2,2.5-2c-1.2-1.9-2.3-4-3.2-6.1L846.8,100.7z
          M858.5,110.2h-15.8v7.4h15.8V110.2z M845.8,90.8v8.6h10v-8.6L845.8,90.8z M925.6,89.7c2.3-2.7,2.8-3.1,3.3-3.1s1,0.3,4.4,3.4
          c1.5,1.4,1.9,1.8,1.9,2.3c0,0.8-0.6,1.2-2.5,1.2c-1.3-0.1-1.7-0.1-2.4,0.5c-1.6,1.2-3.3,2.2-5.2,3.1c-0.2,0.1-0.7-0.2-0.6-0.5
          c0.9-1.8,1.4-3.7,1.8-5.6h-35.1c0.2,4.5-1.5,7.6-4.5,7.6c-1.6,0-2.9-1.2-2.9-2.8c0-0.1,0-0.1,0-0.2c-0.1-1.2,0.7-2.4,1.8-2.8
          c3-1.6,3.8-2.7,4.2-6.3c0-0.3,0.6-0.3,0.6-0.1c0.3,1.1,0.6,2.2,0.7,3.3h14.5V86c0-1.9-0.1-3.4-0.2-4.6c7,0.7,7.7,1.3,7.7,2
          s-0.7,1.1-2,1.6v4.8L925.6,89.7z M902.5,103.8c-5.2,1-10.5,1.6-15.8,1.7c-0.3,0-0.3-0.7,0-0.8c8.2-1.5,16.2-4.1,23.8-7.7
          c3-1.5,5.2-3.1,6.2-4.5c4.8,3.1,6.1,4.3,6.1,5.2c0,0.6-0.6,0.9-3.5,0.5c-3.6,1.9-7.4,3.3-11.4,4.4v9.6l15.9-2.2
          c2.9-4.2,3.3-4.5,3.8-4.5s1.2,0.3,4.2,2.2c1.5,1,1.8,1.3,1.8,1.8s-0.3,0.6-0.7,0.6l-25,3.4v9.4c0,4.3,0.4,4.5,8.9,4.5
          c6.5,0,8.6-0.4,9.7-1.7c0.7-0.8,1.4-2.6,3.2-7.7c0.1-0.3,0.6-0.3,0.6,0c-0.1,2-0.2,4.5-0.2,6.5c0,0.7,0.2,1,1,1.3
          c1,0.2,1.7,1.1,1.7,2.1c0,1.7-1.2,2.9-3.9,3.6s-7,1-13.2,1c-10.9,0-13.1-1.8-13.1-7.4v-11l-13.7,2c-1.2,0.2-2.4,0.5-3.5,0.8l-0.8-2
          c1.4,0,2.8,0,4.1-0.1l13.9-1.9L902.5,103.8z M963,100c2.1-2.9,2.6-3.6,3.2-3.6s1.2,0.4,3.4,2.3c1.1,1,1.4,1.4,1.4,1.8
          c0,0.3-0.3,0.5-0.8,0.5l-9.5,0.3c0.1,0.7,0.1,1.4,0.2,2c0.4,4.2,1.6,8.3,3.5,12c0.6,1,1.3,1.9,2.2,2.7c0.2,0,0.7-0.9,2.5-5.7
          c0.1-0.3,0.7-0.2,0.6,0.1c-0.3,2.1-0.7,5.8-0.7,6.7c0,0.6,0.3,1.3,0.7,1.8c0.5,0.5,0.7,1.2,0.8,1.9c0,1.4-1,2.5-2.4,2.5
          c-0.1,0-0.2,0-0.3,0c-1.8,0-3.8-1.6-5.7-4.2c-2.6-4-4.5-8.5-5.4-13.3c-0.4-2.1-0.7-4.3-0.9-6.5l-7.6,0.3V117
          c3.1-0.7,6.2-1.5,9.3-2.6c0.3-0.1,0.6,0.7,0.4,0.8c-4.4,3.2-9.2,5.8-14.2,7.8c-0.2,1.4-0.6,2.1-1.4,2.1s-1.4-1.7-2.8-6.9
          c1.1-0.1,2.3-0.2,3.5-0.3v-26c0-2.4-0.1-4.8-0.3-7.2c2.1,0.8,4.2,1.8,6.1,3c3.5-0.8,6.8-2.1,10-3.7c1.6-0.8,3.1-1.7,4.5-2.8
          c3.8,2.4,5.6,4,5.6,4.6s-1,1.2-3.4,0.6c-2.2,0.7-4.5,1.3-6.9,1.7c3.1,0.3,3.4,0.6,3.4,1.3c0,0.5-0.4,0.8-1.8,1.4
          c0,3.4,0.1,6.5,0.3,9.3L963,100z M956.5,128.4c2.4-3,3.1-3.4,3.5-3.4s1.2,0.4,3.7,2.4c1.1,0.9,1.5,1.4,1.5,1.8s-0.2,0.6-0.7,0.6
          h-17.2c-1.2,0-2.5,0.1-3.7,0.3l-0.6-2.1c1.4,0.3,2.8,0.5,4.2,0.5H956.5z M955.8,100.3c-0.3-4-0.5-7.8-0.6-11.8
          c-2.3,0.3-4.6,0.5-6.9,0.5v11.5L955.8,100.3z M985.1,84.9c2-2.2,2.5-2.6,2.9-2.6s1,0.3,3.4,2.5c1,1,1.4,1.4,1.4,1.9
          c0,0.6-0.3,0.9-2,1.3c-2.8,5.1-5.9,10-9.4,14.6c7.5,5.2,10.1,9.2,10.1,15c0.1,2.2-0.6,4.3-2.1,5.9c-1.2,1.2-2.8,1.8-4.4,1.8
          c-0.9,0-1-0.2-1.4-1.8s-2.2-2.8-5.6-3.4c-0.3-0.1-0.3-0.9,0-0.9c2.2,0.2,3.5,0.2,5.4,0.2c1.7,0,2-0.1,2.2-0.4
          c0.4-0.5,0.5-1.5,0.5-3.5c0-4.9-2-9-6-12.7c2.1-5.4,3.8-11,5.2-16.7h-8.1v35.3c0,4.7,0.1,8.4,0.1,9.9c0,1.8-1.2,2.4-3.1,2.4
          s-2.2-0.7-2.2-2c0-4.4,0.2-10.9,0.2-31.6c0-10.1-0.1-14.7-0.3-18c2,0.8,3.9,1.7,5.8,2.8L985.1,84.9z"/>
        <path class="an6" d="M11.2,268.5c0,7-2.5,11.4-6.8,11.4c-2,0.1-3.6-1.4-3.7-3.4c0-0.1,0-0.2,0-0.4c0-1.8,0.8-2.9,3.2-4c4.2-1.8,5.7-4.1,6.1-8.9
          c0-0.4,0.9-0.4,0.9-0.1C11,264.9,11.2,266.7,11.2,268.5z M34,248.5c0,2.5,0.1,4.4,0.1,5.5c0,2.1-1.1,3-4,3c-2.3,0-3-0.7-3-2.6
          c0-0.9,0.1-2.8,0.1-5.9H13.7v1.2c0,2.3-1.1,3-3.9,3c-2.5,0-3.2-0.7-3.2-2.5s0.2-4,0.2-11.3c0-4-0.1-8-0.4-12.1
          c2.8,1.1,5.6,2.4,8.3,3.9h12.6c0-5.5-0.1-7.8-0.3-11.1c7.5,0.8,8.7,1.1,8.7,2.3c0,0.6-0.5,1.1-2,1.8v7h14c2.7-3,3.2-3.4,3.8-3.4
          s1.3,0.4,4,2.6c1.2,1,1.6,1.5,1.6,2.1c0,0.8-0.6,1.3-2,2.2v2.5c0,6.8,0.1,11.8,0.1,13.3c0,2.2-1.1,3-4,3c-2.1,0-3-0.7-3-2.7v-1.8
          L34,248.5z M27.2,246.9c0.1-2.9,0.1-6.6,0.1-11.4v-3.2H13.7v14.6H27.2z M23.8,274.9c0,2,1,2.3,9,2.3c5,0,7.5-0.1,9.3-1.3
          s2.5-3.6,4-8.3c0.1-0.4,0.8-0.2,0.8,0.1c-0.1,1.8-0.1,3.6-0.1,6c0,1.3,0.1,1.5,1.8,2.3c0.8,0.4,1.2,1.2,1.2,2c0,1.6-1,2.8-3.4,3.5
          c-2.8,0.8-7.5,1.2-14.7,1.2c-12.3,0-14.4-1.1-14.4-8.1c0-1.4,0.2-2.9,0.2-8.8c0-2.4-0.1-4.8-0.4-7.3c7.8,0.9,8.7,1.1,8.7,2
          c0,0.6-0.4,1-2,1.9V274.9z M37.1,271.2c-2,0-2.5-0.6-3.7-4.3c-0.9-2.7-2.2-4.4-5.1-7c-0.3-0.2,0.1-0.6,0.4-0.6
          c7.6,1.3,11.8,4,11.8,8C40.5,269.5,39.1,271.1,37.1,271.2L37.1,271.2z M48.4,246.9v-14.6H33.8l0.2,14.6H48.4z M58.9,279.5
          c-2.3,0-2.9-0.9-4-5.4c-1.3-4.9-2.3-7-5.2-10.1c-0.1-0.2,0-0.5,0.2-0.6c0.1-0.1,0.2-0.1,0.3,0c8,2.6,12.3,6.6,12.3,11.6
          C62.6,277.6,61.2,279.5,58.9,279.5L58.9,279.5z M122,241.4c3.5-3.9,4.4-4.5,4.9-4.5s1.4,0.6,4.5,3.3c1.3,1.1,1.9,1.7,1.9,2.2
          s-0.2,0.7-0.9,0.7h-21c-2.8,2.6-5.8,5-9,7.2h10.9c3-2.9,3.6-3.3,4.2-3.3s1.3,0.4,4.4,3c1.5,1.3,1.9,1.8,1.9,2.3c0,1-0.9,1.4-3.5,1.5
          c-4.1,2.4-8.4,4.2-12.9,5.6c-0.1,0.6-0.8,1.1-2.1,1.8v3h15.6c3.4-3.8,4.2-4.4,4.7-4.4s1.4,0.6,4.5,3c1.4,1.1,2,1.8,2,2.3
          s-0.2,0.7-0.9,0.7h-25.9v3.8c0,5.4,0.2,6.5,0.2,8.3c0,1.5-0.3,2.9-1,4.2c-1.2,2-3.6,3-7.1,3c-1.4,0-1.4-0.1-1.8-2.2
          c-0.3-1.8-2.5-3.3-7.9-4.5c-0.3-0.1-0.3-1,0-1c3.5,0.2,6.8,0.4,10,0.4c0.6,0,0.8-0.3,0.8-1.1v-10.9H81.2c-1.5,0-3,0.2-4.4,0.4
          l-0.7-2.3c-2.5,0.9-4.9,1.6-7.5,2.3c-0.4,0.1-0.7-0.9-0.4-1c8.7-3.5,17-8,24.7-13.4h-9.1c-1.4,0-2.8,0.2-4.1,0.6l-0.7-2.7
          c1.5,0.4,3.1,0.6,4.7,0.6h11.5c3-2.3,5.9-4.6,8.7-7.2H75.7c-1.7,0-3.3,0.2-4.9,0.4l-0.8-2.6c1.8,0.4,3.6,0.6,5.3,0.6h19.2v-9.9h-12
          c-1.4,0-2.9,0.2-4.3,0.4l-0.8-2.6c1.6,0.4,3.2,0.6,4.9,0.6h12.2v-2.3c0-2.6-0.1-5.2-0.4-7.8c8.7,0.8,9.4,1.6,9.4,2.3
          s-0.8,1.3-2.3,1.8v6h4.5c2.8-3.4,3.7-3.9,4.2-3.9s1.3,0.6,4,2.8c0.6,0.4,1.1,0.9,1.5,1.6c1.9-2.6,3.7-5.4,5.2-8.3
          c5.6,3,7.8,4.4,7.8,5.3c0,1-1,1.3-3.5,1.3c-3.6,4.6-7.5,8.9-11.7,12.8L122,241.4z M100.1,251.8c-7.3,4.9-15.2,8.9-23.5,11.9
          c1.4,0.3,2.9,0.4,4.3,0.4h17.4v-0.9c0-2.2,0-4.5-0.2-6.7c4,0.6,6.2,1,7.5,1.3c2.7-1.7,5.2-3.7,7.4-6.1H100.1z M105.6,241.4
          c3.2-3.1,6.1-6.4,8.8-9.9h-13.2v9.9H105.6z M159,265c0,12.3,0.2,15.6,0.2,17.1c0,2-1.2,2.8-3.8,2.8c-2,0-2.7-0.6-2.7-2.5
          c0-1.7,0.4-8.3,0.6-15.3c-4.9,6.2-8,8.8-14,12c-0.3,0.1-0.7-0.5-0.5-0.8c6.6-6.8,10.2-12.4,13.5-20.7h-6.8c-1.4,0-2.9,0.2-4.3,0.4
          l-0.8-2.6c1.6,0.4,3.2,0.6,4.8,0.6h8v-2.1c0-1.9-0.1-3.7-0.3-5.6c6,0.5,8.1,1,8.1,2c0,0.6-0.5,1.1-2,1.7v4h4.9
          c2.5-3,3.3-3.6,3.8-3.6s1.4,0.6,3.8,2.5c1.1,0.9,1.6,1.5,1.6,2s-0.2,0.7-0.9,0.7H159v3.5c6.8,1.3,10.3,3.9,10.3,7.3
          c0.2,1.6-1,3.1-2.7,3.3c-0.1,0-0.2,0-0.3,0c-1.7,0-2.4-0.7-3.3-3.3c-0.8-2.4-2.1-4.5-4-6.2L159,265z M157.8,245.4
          c1.1-2.8,2.1-5.6,2.8-8.5c0.4-1.6,0.7-3.2,0.9-4.8c5.5,1.6,7.7,2.7,7.7,3.7c0,0.8-0.6,1.1-2.5,1.2c-2.1,3-4.4,5.9-7,8.5h4.5
          c2.9-3.5,3.7-4.1,4.2-4.1s1.3,0.6,4.1,2.8c1.2,1.1,1.8,1.7,1.8,2.2s-0.2,0.7-0.9,0.7h-28.1c-1.4,0-2.8,0.2-4.2,0.4l-0.8-2.6
          c1.6,0.4,3.2,0.6,4.9,0.6L157.8,245.4z M153.1,225.2c0-1.8-0.1-3.5-0.2-5.3c7.7,0.6,8.6,1.3,8.6,1.9s-0.7,1.2-2.2,1.8v6.3h4.7
          c2.2-3,3-3.6,3.5-3.6s1.3,0.6,3.7,2.5c1,0.9,1.6,1.5,1.6,2s-0.2,0.7-0.9,0.7h-25c-1.4,0-2.8,0.2-4.2,0.4l-0.8-2.6
          c1.6,0.4,3.2,0.6,4.8,0.6h6.5L153.1,225.2z M151,244c-2.1,0-2.3-0.6-2.9-4.8c-0.2-2-0.8-4-1.8-5.7c-0.2-0.3,0.1-0.7,0.5-0.6
          c4.9,1.8,7.4,4.4,7.4,7.4C154.2,242.5,152.9,244,151,244z M181.4,245c-0.1,5-0.6,10-1.5,15c-2.3,11.9-6.8,18.3-14.5,23.7
          c-0.3,0.2-1.1-0.5-0.8-0.8c5.9-7.1,8.7-14.3,10.1-26.9c0.6-5.4,0.6-11.9,0.6-19.8c0-3.8-0.1-5.8-0.4-9.2c3.4,1.4,4.1,1.6,7.4,3.2
          c3.3-1.6,6.4-3.6,9.2-6.1c1.4-1.1,2.7-2.4,3.9-3.8c4.8,2.8,7.1,4.6,7.1,5.5c0,0.6-0.5,1.1-3.1,0.9c-5.6,2.5-11.5,4.3-17.5,5.3v1.7
          c0,3.7-0.1,6.8-0.1,9.7h14.2c2.9-3.7,3.7-4.2,4.2-4.2c0.6,0,1.3,0.6,4.2,3c1.3,1.1,1.8,1.7,1.8,2.2s-0.2,0.7-0.9,0.7h-9.6v13.1
          c0,9.1,0.1,20.4,0.1,24.1c0,1.8-1.5,2.5-4,2.5c-1.8,0-2.8-0.6-2.8-2.1c0-1.8,0.3-7.2,0.3-18V245H181.4z M246.2,257.2h10.4
          c3.5-4.5,4.4-5.1,4.9-5.1s1.3,0.6,4.8,3.5c1.5,1.3,2.1,2,2.1,2.5s-0.2,0.7-0.9,0.7h-21.3v19.7h15.4c4-4.7,4.9-5.3,5.4-5.3
          s1.4,0.6,5.1,3.7c1.7,1.4,2.3,2,2.3,2.5s-0.2,0.7-0.9,0.7h-54.2c-2.3,0-4.5,0.2-6.8,0.4l-0.8-2.6c2.5,0.4,5,0.6,7.5,0.6h20.2v-19.7
          h-12.3c-2,0-4,0.2-5.9,0.4l-0.8-2.6c2.1,0.4,4.2,0.6,6.3,0.6h12.6v-16.6h-12.7c-3.2,6.2-7.8,11.4-13.6,15.2
          c-0.3,0.2-0.9-0.3-0.7-0.6c4.3-5.3,8.2-13.6,10.4-21.6c0.7-2.7,1.3-5.4,1.7-8.2c6.6,1.7,9.1,2.7,9.1,3.8c0,0.8-0.8,1.3-2.8,1.8
          c-0.9,2.7-2,5.3-3.2,7.9h11.9v-8.6c0-3.3-0.1-6.7-0.4-10c9,0.9,9.9,1.7,9.9,2.5s-0.8,1.4-2.6,2v14.1h12.1c3.6-4.7,4.5-5.2,4.9-5.2
          s1.3,0.6,4.9,3.6c1.6,1.3,2.1,2,2.1,2.5s-0.2,0.7-0.9,0.7h-23.2V257.2z M297.8,231.2c0.7-1.7,1.3-3.4,1.8-5.2c0.5-1.8,1-3.6,1.3-5.5
          c5.6,1.8,7.3,2.5,7.3,3.3c0,0.6-0.6,0.9-2.2,1.1c-1.6,2.4-3.6,4.5-5.9,6.3h4.8c2.4-2.8,3-3.2,3.5-3.2s1.3,0.4,3.6,2.2
          c1.1,0.8,1.5,1.3,1.5,1.9s-0.3,0.7-1.1,0.7h-10.1v6.1h1.7c1.6-2.3,2.5-2.9,3-2.9c0.6,0,1.4,0.6,3.3,2c0.8,0.6,1.3,1.3,1.3,1.8
          s-0.2,0.7-0.9,0.7h-8.4v6.1h1.5c1.6-2.3,2.5-2.8,3-2.8s1.4,0.6,3.4,2c0.8,0.6,1.3,1.2,1.3,1.7s-0.2,0.7-0.9,0.7h-8.3v6.8h3.2
          c1.8-2.5,2.7-3.1,3.2-3.1c0.6,0,1.4,0.6,3.5,2.2c0.8,0.7,1.4,1.3,1.4,1.8s-0.2,0.7-0.9,0.7h-20.9v1c0,1.7-1.1,2.3-3.5,2.3
          c-1.6,0-2.3-0.4-2.3-1.8c0-3.3,0.4-6.5,0.4-19.6v-2.8c-1.9,1.7-4.1,3.2-6.3,4.4c-0.3,0.1-0.6-0.3-0.4-0.6c3.4-3.7,6.1-8,8-12.7
          c0.9-2.2,1.7-4.5,2.3-6.9c5.6,1.5,7.7,2.3,7.7,3.1s-0.5,1-2.3,1.2c-1.3,2.4-2.8,4.7-4.5,6.8L297.8,231.2z M314.3,277.5
          c-8.8,4.1-19.3,6.5-32.4,7.4c-0.4,0-0.8-1.1-0.4-1.2c11.6-2.3,20.7-5.2,28-9.2c-3.7-2.9-6.9-6.4-9.3-10.4h-8.7
          c-1.5,0-2.9,0.2-4.3,0.6l-0.8-2.8c1.6,0.4,3.3,0.6,5,0.6h31.4c2.9-2.5,3.5-2.9,4-2.9s1.3,0.4,4.4,2.9c1.5,1.3,1.9,1.8,1.9,2.3
          c0,0.9-0.6,1.2-3.1,1.5c-3.1,3.1-6.5,5.9-10.2,8.3c6.1,1.7,14,2.5,26.5,2.3c0.4,0,0.3,0.6-0.1,0.8c-2.6,1.1-3.3,2.2-4.2,5.6
          c-0.2,1-0.6,1.3-1.7,1.3c-2.2-0.1-4.3-0.3-6.5-0.7c-6.4-1.1-12.8-2.8-18.5-5.8L314.3,277.5z M297,238.9v-6.1h-5.4v6.1H297z
          M291.6,246.7h5.4v-6.1h-5.4V246.7z M291.6,255h5.4v-6.7h-5.4V255z M302,264c3.2,3.4,7,6.2,11.3,8.2c3.5-2.3,6.7-5.1,9.5-8.2H302z
          M320,257.3c0,1.7-0.9,2.4-3.3,2.4c-1.6,0-2.4-0.6-2.4-1.7c0-2.3,0.2-4.6,0.2-13.8c0-2.8,0-5.5,0.1-8.3c-1.1,0.8-2.1,1.6-3.4,2.4
          c-0.3,0.2-0.7-0.2-0.5-0.5c2.6-3.5,4.6-7.3,6-11.4c0.8-2,1.3-4.1,1.7-6.3c5.9,1.7,7.5,2.3,7.5,3.2c0,0.6-0.4,0.9-2.2,1.1
          c-1.2,2.4-2.7,4.7-4.4,6.8h6.9c0.8-1.9,1.5-3.9,2.1-5.9c0.4-1.6,0.8-3.2,1-4.8c5.6,1.8,7.3,2.5,7.3,3.3c0,0.6-0.6,0.9-2.2,1.1
          c-1.7,2.4-3.6,4.5-5.9,6.3h6.6c2.5-2.9,3-3.3,3.6-3.3c0.7,0,1.3,0.4,3.9,2.3c1.2,0.9,1.6,1.4,1.6,2s-0.3,0.7-1.1,0.7h-11.5v6.1h2.5
          c1.8-2.5,2.6-3,3.1-3s1.4,0.6,3.5,2.1c0.9,0.8,1.5,1.3,1.5,1.8s-0.2,0.7-0.9,0.7h-9.7v6.1h2.5c1.8-2.4,2.6-3,3.1-3s1.4,0.6,3.5,2.1
          c0.8,0.7,1.4,1.3,1.4,1.8s-0.2,0.7-0.9,0.7h-9.6v6.7h4.1c2.1-2.8,3-3.3,3.5-3.3s1.4,0.6,3.7,2.3c1,0.8,1.5,1.4,1.5,1.9
          c0,0.5-0.2,0.7-0.9,0.7H320V257.3z M326.3,238.9v-6.1H320v6.1H326.3z M320,246.7h6.3v-6.1H320V246.7z M320,255h6.3v-6.7H320V255z
          M365.6,251.7c2-0.6,4.2-1.3,6.7-2.2c0.4-0.1,0.6,0.6,0.3,0.9c-2.3,1.7-4.7,3.3-7,4.7v6.6c0,6.9,0.1,13,0.1,15.7s-0.6,4.2-1.8,5.4
          s-3.2,1.7-5.9,1.7c-0.6,0-1-0.5-1.1-1.5c-0.1-2.8-1.3-3.7-6.3-5.2c-0.4-0.1-0.4-0.9,0-0.9c3,0.3,5.6,0.4,7.7,0.4c0.9,0,1-0.1,1-1.1
          v-17.4c-1.2,0.6-2.3,1.1-3.5,1.6c-0.2,1.8-0.7,2.5-1.5,2.5s-1-0.6-3.5-8.1c1.5-0.2,2.8-0.4,4.1-0.6c1.5-0.3,3-0.6,4.3-0.9v-15.8
          h-3.7c-1.2,0-2.5,0.2-3.7,0.4l-0.8-2.6c1.5,0.4,2.9,0.6,4.4,0.6h3.7v-7.7c0.1-2.8-0.1-5.6-0.4-8.3c7.3,0.6,8.9,1,8.9,2
          c0,0.7-0.6,1.2-2.3,1.9v12.1h0.4c2.1-3.1,3-3.7,3.5-3.7c0.6,0,1.3,0.6,3.5,2.5c0.9,0.9,1.5,1.6,1.5,2s-0.2,0.7-0.9,0.7h-8
          L365.6,251.7z M402.7,237.5c2-2.3,2.6-2.8,3.2-2.8s1.3,0.4,3.7,2.3c1.1,0.8,1.5,1.3,1.5,1.8s-0.2,0.7-1.7,2v5.2h0.2
          c2-3.3,2.9-3.9,3.4-3.9s1.2,0.6,3.2,2.8c0.6,0.5,1.1,1.3,1.3,2.1c0,0.5-0.2,0.7-0.9,0.7h-7.1c0.1,4.7,0.2,8,0.2,9.2
          c0,1.7-1.3,2.5-3.5,2.5s-2.8-0.8-2.8-2.2V257h-9v8.3h8.6c2.9-3.6,3.7-4.2,4.2-4.2s1.3,0.6,4.2,2.9c1.2,1.1,1.8,1.7,1.8,2.2
          s-0.2,0.7-0.9,0.7h-17.9v10.2c2.5,0.2,5.5,0.3,9.1,0.3c4.5,0.1,9.1-0.2,13.6-0.6c0.4,0,0.3,0.6,0,0.9c-2,1.3-2.9,2.3-3.3,4.6
          c-0.2,1.4-0.4,1.7-1.2,1.8c-1.1,0.1-2.3,0.2-3.4,0.1c-8,0-15.2-0.8-20.3-3c-4.6-1.9-7.5-4.9-10.1-9.6c-3,6.1-7.1,10.4-12.5,12.8
          c-0.4,0.1-0.9-0.6-0.6-0.8c4-3.2,7.4-10,8.8-17.3c0.4-2,0.6-4,0.7-6.1c6.8,1.1,8.3,1.5,8.3,2.4c0,0.6-0.5,1.1-1.9,1.5
          c-0.5,1.9-1.1,3.8-1.8,5.6c2,3.2,5,5.5,8.6,6.6V257h-9.6c-1.4,0-2.9,0.2-4.3,0.5l-0.8-2.6c1.7,0.3,3.4,0.5,5.1,0.5h9.6v-7.6h-11.8
          c-1.4,0-2.9,0.2-4.3,0.4l-0.8-2.6c1.7,0.4,3.4,0.6,5.1,0.6h11.8v-7h-7.3c-1.6,0-3.3,0.2-4.9,0.6l-0.8-2.8c1.8,0.4,3.7,0.6,5.6,0.6
          h7.4v-6.1h-11.4c-1.5,0-3,0.2-4.4,0.4l-0.8-2.6c1.7,0.4,3.4,0.6,5.1,0.6h11.6V227c0.1-2.4,0-4.8-0.2-7.1c7.6,0.8,8.3,1.5,8.3,2.2
          c0,0.6-0.6,1-2,1.6v6.1h9.7c3.5-4.1,4.3-4.6,4.8-4.6s1.4,0.6,4.8,3.2c1.5,1.2,2,1.8,2,2.3s-0.2,0.7-0.9,0.7h-20.4v6.1L402.7,237.5z
          M394.3,246.1h9v-7h-9V246.1z M394.3,247.7v7.6h9v-7.6H394.3z"/>
        <path class="st4 an7" d="M453.6,219.4h3.1v65h-3.1V219.4z"/>
        <path class="an8" d="M530.9,267.8l-4,11h-33.7v-1.6c9.9-9,16.9-16.4,21-22.2c4.1-5.7,6.1-11,6.1-15.7c0.1-3.3-1.1-6.5-3.3-8.9
          c-2-2.3-5-3.5-8-3.5c-2.7,0-5.4,0.8-7.6,2.5c-2.4,1.8-4.1,4.4-5,7.2h-1.6c0.7-5.2,2.5-9.2,5.4-12c2.9-2.8,6.8-4.3,10.9-4.2
          c4.3-0.1,8.5,1.5,11.6,4.5c3,2.7,4.7,6.5,4.7,10.5c0,3-0.7,6-2,8.7c-2.6,5.3-6,10.2-10.1,14.5c-7,7.6-11.4,12.2-13.1,13.8H517
          c2.1,0.1,4.3-0.1,6.4-0.3c1.2-0.2,2.3-0.7,3.3-1.4c1.1-0.8,1.9-1.8,2.6-2.9L530.9,267.8z M538.8,232.5c1.3-3.4,3.4-6.5,6.2-8.9
          c2.6-2.1,5.9-3.3,9.3-3.1c4.6,0,8.1,1.5,10.5,4.5c1.8,2,2.7,4.5,2.8,7.1c0,4.2-2.6,8.4-7.8,12.9c3.2,1.1,6,3.2,8,5.9
          c1.8,2.6,2.8,5.8,2.7,9c0.1,4.7-1.6,9.3-4.7,12.9c-4.1,4.7-10,7.1-17.8,7.1c-3.8,0-6.5-0.5-7.9-1.4c-1.4-1-2.1-2-2.1-3.1
          c0-0.8,0.4-1.6,1-2.1c0.6-0.6,1.5-0.9,2.4-0.9c0.7,0,1.5,0.1,2.2,0.3c1.1,0.4,2.2,0.9,3.3,1.5c1.1,0.6,2.3,1.2,3.5,1.6
          c1.1,0.3,2.2,0.5,3.3,0.5c2.9,0,5.6-1.2,7.5-3.3c2.1-2.1,3.2-4.9,3.2-7.9c0-2.2-0.5-4.5-1.5-6.5c-0.6-1.3-1.4-2.5-2.4-3.6
          c-1.5-1.3-3.2-2.3-5-3.1c-2-0.9-4.2-1.4-6.5-1.4h-1.3v-1.3c2.4-0.3,4.6-1.1,6.7-2.4c2-1.1,3.7-2.7,4.9-4.7c1-1.9,1.5-4,1.5-6.1
          c0.1-2.6-0.9-5.1-2.7-7c-1.8-1.8-4.2-2.7-6.8-2.7c-4.3,0-8,2.3-10.9,7L538.8,232.5z M618.6,255.1c0.4-0.4,0.7-0.3,1.1,0.1
          s0.4,0.7,0.1,1.2c-3,3.7-6.6,7.1-10.5,7.1c-3.1,0.1-6.2-0.7-8.9-2.3c-4.2-2.3-7.1-4-9.9-4c-3,0-4.7,0.3-8.4,2.8
          c-0.3,0.3-0.6,0.1-1-0.3s-0.3-0.6,0-1c3.1-3.6,6.6-6.6,10.6-6.6c3,0,6,0.7,8.7,2.1c4.1,2,7.1,4.1,10,4.1S615.5,257.6,618.6,255.1z
          M660.7,221.6l-3.3,7.2h-17.2l-3.8,7.7c7.4,1.1,13.3,3.9,17.7,8.3c3.6,3.5,5.7,8.4,5.6,13.5c0,2.9-0.6,5.7-1.8,8.4
          c-1.1,2.4-2.7,4.6-4.6,6.5c-1.8,1.8-3.9,3.2-6.2,4.3c-3.1,1.5-6.5,2.3-10,2.3c-3.4,0-5.9-0.6-7.5-1.8c-1.4-0.8-2.2-2.3-2.3-3.9
          c0-0.8,0.4-1.6,1-2.1c0.7-0.6,1.5-0.9,2.4-0.9c0.7,0,1.3,0.1,1.9,0.3c1,0.5,1.9,1.1,2.8,1.7c1.9,1.4,4.1,2.1,6.4,2.2
          c3.3,0,6.4-1.3,8.7-3.7c2.4-2.4,3.8-5.7,3.7-9.1c0-3.5-1.2-6.9-3.3-9.7c-2.4-3.1-5.6-5.5-9.2-6.9c-3.1-1.3-7.2-2-12.5-2.2l11-22.2
          L660.7,221.6z M670.8,232.5c1.3-3.4,3.4-6.5,6.2-8.9c2.6-2.1,5.9-3.3,9.3-3.1c4.6,0,8.1,1.5,10.5,4.5c1.8,2,2.8,4.5,2.8,7.1
          c0,4.2-2.6,8.4-7.8,12.9c3.2,1.1,6,3.2,8,5.9c1.8,2.6,2.8,5.8,2.7,9c0.1,4.7-1.6,9.3-4.7,12.9c-4.1,4.7-10,7.1-17.8,7.1
          c-3.8,0-6.5-0.5-7.9-1.4c-1.4-1-2.1-2-2.1-3.1c0-0.8,0.4-1.6,1-2.1c0.6-0.6,1.5-0.9,2.4-0.9c0.7,0,1.5,0.1,2.2,0.3
          c1.1,0.4,2.2,1,3.2,1.5c1.1,0.6,2.3,1.2,3.5,1.6c1.1,0.3,2.2,0.5,3.3,0.5c2.9,0,5.6-1.2,7.5-3.3c2.1-2.1,3.2-4.9,3.2-7.9
          c0-2.2-0.5-4.5-1.5-6.5c-0.6-1.3-1.4-2.5-2.4-3.6c-1.5-1.3-3.2-2.3-5-3.1c-2-0.9-4.2-1.4-6.5-1.4h-1.3v-1.3c2.4-0.3,4.6-1.1,6.7-2.4
          c2-1.1,3.7-2.7,4.8-4.7c1-1.9,1.5-4,1.5-6.1c0.1-2.6-0.9-5.1-2.7-7c-1.8-1.8-4.2-2.7-6.8-2.7c-4.4,0-8,2.3-10.9,7L670.8,232.5z
          M726.8,262.5c3.1-1,6.1-2.2,8.8-3.3c0.4-0.1,0.6,0.7,0.4,0.9c-5.6,4.7-11.9,8.7-18.5,11.9c-0.1,2.2-0.6,3-1.5,3s-2-1.9-4.3-8.5
          c1.9-0.2,3.7-0.6,5.5-1.1l3.3-0.9v-22.8h-4.1c-1.1,0-2.3,0.2-3.4,0.4l-0.8-2.6c1.3,0.4,2.6,0.6,3.9,0.6h4.4v-11
          c-0.1-3.4-0.2-6.2-0.4-8.6c8.5,0.9,9.3,1.6,9.3,2.4c0,0.6-0.8,1.3-2.5,2v15.2h1.2c2.3-3.2,3.2-3.7,3.7-3.7s1.3,0.6,3.6,2.6
          c0.9,0.9,1.5,1.6,1.5,2s-0.2,0.7-0.9,0.7h-9L726.8,262.5z M756.4,255.5h9.7c3.3-4.1,4.5-4.7,4.9-4.7s1.4,0.4,4.7,3.3
          c1.4,1.2,2,1.8,2,2.3s-0.2,0.7-0.9,0.7h-20.4v3.7c0,10.6,0.4,18.7,0.4,20.7s-1.3,2.9-4.2,2.9c-2.1,0-3-0.7-3-2.2
          c0-1.7,0.2-11.1,0.2-21.4v-3.8H736c-1.3,0-2.6,0.2-4,0.4l-0.8-2.6c1.5,0.4,3.1,0.6,4.7,0.6h13.9v-28.3h-10.7c-1.4,0-2.9,0.2-4.3,0.4
          l-0.8-2.6c1.6,0.4,3.1,0.6,4.7,0.6h25.7c2.9-3.7,3.7-4.2,4.2-4.2s1.4,0.6,4.4,3c1.3,1.1,1.9,1.7,1.9,2.2s-0.2,0.7-0.9,0.7h-17.7
          L756.4,255.5z M742.4,249.9c-1.9,0-2.6-1.2-2.9-4.7c-0.5-5.8-1.3-9-3.2-12.4c-0.1-0.3,0.3-0.8,0.6-0.6c6.2,3.8,9.4,8.5,9.4,13.2
          C746.3,248,744.6,249.8,742.4,249.9L742.4,249.9z M771.2,236.9c-2.9,5.3-6.4,10.1-10.6,14.5c-0.2,0.3-1.2-0.2-1-0.6
          c1.6-3.7,2.9-7.6,3.9-11.5c0.7-2.6,1.2-5.2,1.6-7.8c7.5,2.5,8.8,3,8.8,4C773.8,236.2,773.1,236.6,771.2,236.9L771.2,236.9z
          M843.1,267.8l-4,11h-33.7v-1.6c9.9-9,16.9-16.4,20.9-22.2s6.1-11,6.1-15.7c0.1-3.3-1.1-6.5-3.3-8.9c-2-2.3-5-3.5-8-3.5
          c-2.7,0-5.4,0.8-7.6,2.5c-2.4,1.8-4.1,4.4-5,7.2H807c0.7-5.2,2.5-9.2,5.4-12c2.9-2.8,6.9-4.3,10.9-4.2c4.3-0.1,8.5,1.5,11.6,4.5
          c3,2.7,4.7,6.5,4.7,10.5c0,3-0.7,6-2,8.7c-2.6,5.3-6,10.2-10.1,14.5c-7,7.6-11.3,12.3-13.1,13.9h14.9c2.1,0.1,4.3-0.1,6.4-0.3
          c1.2-0.2,2.3-0.7,3.3-1.4c1.1-0.8,1.9-1.8,2.6-2.9L843.1,267.8z M887.7,255.1c0.4-0.4,0.7-0.3,1,0.1s0.4,0.7,0.1,1.2
          c-3,3.7-6.6,7.1-10.5,7.1c-3.1,0.1-6.2-0.7-8.9-2.3c-4.2-2.3-7.1-4-9.9-4c-3,0-4.7,0.3-8.4,2.8c-0.3,0.3-0.6,0.1-1-0.3s-0.3-0.6,0-1
          c3.1-3.6,6.6-6.6,10.6-6.6c3,0,6,0.7,8.7,2.1c4.1,2,7.1,4.1,10,4.1S884.5,257.6,887.7,255.1z M932.5,257.7v6h-7.7v15.1h-7v-15.1
          h-24.2v-5.4l26.5-37.8h4.6v37.3H932.5z M917.8,257.7v-28.4l-20.1,28.4L917.8,257.7z M951.5,256.5c-0.4,3.9-1.2,7.8-2.4,11.5
          c-2.1,6.4-5.6,10.9-11.1,15.9c-0.3,0.2-0.8-0.3-0.6-0.6c3.2-5.4,5.4-11.3,6.4-17.5c1.2-6.6,1.7-14.9,1.7-26.4
          c0-4.4-0.1-6.5-0.4-10.4c3.8,1.7,4.5,1.9,8.3,3.9h35.2c2.7-2.9,3.2-3.3,3.8-3.3s1.3,0.4,3.9,2.5c1.1,0.9,1.6,1.4,1.6,2s-0.2,0.8-2,2
          v2c0,2.7,0.1,5.2,0.1,7c0,2.3-1.1,3.3-3.7,3.3c-2.2,0-3.1-0.9-3.1-2.8V245h-37c-0.1,3.3-0.3,6.6-0.6,9.9h17.1v-3.2
          c0-1.1-0.2-2.8-0.3-4.9c8,0.8,8.8,1.6,8.8,2.3c0,0.5-0.7,1.1-2,1.9v3.9h15.6c3.4-3.9,4.2-4.4,4.7-4.4s1.4,0.6,4.7,3.1
          c1.5,1.2,2,1.8,2,2.3c0,0.5-0.2,0.7-0.9,0.7H972c-0.2,2.4-0.6,4.8-1.2,7.1h16.5c2.5-2.8,3-3.3,3.6-3.3s1.3,0.4,3.9,2.8
          c1.1,1.1,1.6,1.6,1.6,2.1s-0.2,0.7-2.2,1.9c-0.6,5.4-1.2,8.9-2.3,11.4c-0.7,1.9-2,3.5-3.8,4.5c-1.7,0.7-3.5,1.1-5.4,1
          c-1.8,0-2.1-0.3-2.4-1.8c-0.3-2-2-3.4-7-4.8c-0.3-0.1-0.3-1.1,0.1-1.1c3.1,0.4,6.2,0.7,9.4,0.9c2,0,2.8-0.6,3.5-2.7
          c0.9-3.1,1.4-6.2,1.6-9.5h-17.5c-3.3,9.8-10.7,15.8-23.4,19.4c-0.4,0.1-0.7-0.7-0.4-0.9c12-6.4,17.8-14.8,18.8-27.3H951.5z
          M990.2,223.8c3.7-4.1,4.6-4.6,5.1-4.6s1.5,0.6,5.1,3.2c1.7,1.3,2.3,1.8,2.3,2.3s-0.2,0.7-0.9,0.7h-55.5c-1.8,0-3.6,0.2-5.4,0.4
          l-0.8-2.6c2,0.4,4,0.6,6,0.6L990.2,223.8z M989.3,234.6h-36.9v2.9c0,2.1-0.1,4-0.1,5.9h36.9L989.3,234.6z"/>
        <path class="st4 an9" d="M1040.5,219.4h3.1v65h-3.1V219.4z"/>
        <path class="st5 an12" d="M401.6,505.4c0,1.3,0,3,0.4,3.8s1.6,0.9,3,0.9c1.9,0.1,3.8-0.4,5.3-1.6c1-0.9,1.8-1.9,2.4-3.1
          c0.1-0.2,0.2-0.3,0.5-0.2s0.3,0.3,0.3,0.4c-0.4,1.9-1,3.7-1.6,5.5c-2.8-0.1-5.6-0.1-8.2-0.1s-5.7,0-8.4,0.1c-0.2,0-0.3-0.1-0.3-0.4
          s0.1-0.4,0.3-0.4c1.2-0.1,1.8-0.2,2.1-0.8s0.4-2,0.4-4.1v-11.3c0-3,0-3.4-0.4-4.1s-1-0.6-2.2-0.7c-0.3,0-0.3-0.1-0.3-0.4
          s0.1-0.4,0.3-0.4c1.3,0,3,0.1,4.4,0.1s3.1-0.1,4.4-0.1c0.3,0,0.3,0.1,0.3,0.4s-0.1,0.4-0.3,0.4c-1.2,0.1-1.8,0.1-2.2,0.7
          s-0.3,2.1-0.3,4.1L401.6,505.4z M429.5,504.1c0,4.1-2.9,7.5-7.3,7.5c-3.9,0.1-7.2-3.1-7.2-7c0-0.2,0-0.3,0-0.5c0-4.2,3-7.6,7.2-7.6
          c4,0,7.2,3.2,7.3,7.2C429.5,503.8,429.5,503.9,429.5,504.1z M418.6,504.1c0,3.9,1.1,6.6,3.6,6.6s3.6-2.9,3.6-6.6
          c0-3.9-1.1-6.6-3.6-6.6S418.6,500.3,418.6,504.1z M438.6,505.6c0.4,1,0.5,1.5,0.8,2.6c0.4-1.1,0.6-1.6,1-2.7l1.3-4.1
          c0.5-1.5,0.7-2.6,0.4-3.1s-0.8-0.5-1.6-0.6c-0.2,0-0.2-0.1-0.2-0.4s0.1-0.4,0.3-0.4c0.9,0.1,2.1,0.1,2.9,0.1s1.8,0,2.6-0.1
          c0.2,0,0.3,0.1,0.3,0.4s-0.1,0.3-0.3,0.4c-0.6,0.1-1.2,0.3-1.6,0.7c-0.7,0.9-1.2,1.9-1.5,3l-2.1,5.8c-0.6,1.6-0.8,2.3-1.3,3.6
          c-0.6,0.3-1.2,0.6-1.9,0.7c-0.1,0-0.2-0.1-0.3-0.2c-0.4-1.3-0.8-2.4-1.4-4.1l-2.2-5.9c-0.4-1.1-0.8-2.2-1.5-3.2
          c-0.4-0.4-0.8-0.4-1.6-0.5c-0.2,0-0.3-0.1-0.3-0.4s0-0.4,0.3-0.4c1.2,0,2.5,0.1,3.6,0.1s2.6,0,3.8-0.1c0.2,0,0.3,0.1,0.3,0.4
          s0,0.4-0.2,0.4c-0.9,0-1.5,0.1-1.7,0.5s0.2,1.4,0.9,3.2L438.6,505.6z M450.9,504c0,4.1,2.5,6.1,4.4,6.1c1.7,0.1,3.4-0.8,4.3-2.2
          c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-1.2,2-3.4,3.2-5.7,3.1
          c-4.3,0-7.2-3.2-7.2-7.4s3-7.7,7.2-7.7c3.2,0.1,5.8,2.6,6,5.8c0,0.2,0,0.4-0.1,0.5c-0.2,0.2-0.3,0.2-0.5,0.2c-3.1-0.1-6.3-0.1-9-0.1
          C450.9,503.3,450.9,503.6,450.9,504z M457,501.7c0.2,0,0.3-0.2,0.3-0.5c-0.1-1.7-0.8-3.8-2.6-3.8s-3,1.8-3.4,4.3
          C453.1,501.8,455.1,501.8,457,501.7L457,501.7z M484.3,507.6c0,2,0.4,2.7,1.1,2.7c0.3,0,0.6-0.1,0.9-0.2c0.1-0.1,0.3-0.1,0.4,0.2
          s0.1,0.3-0.1,0.5c-0.8,0.5-1.7,0.8-2.6,0.7c-1.2,0.1-2.2-0.6-2.6-1.7c-1.1,1.2-2.7,1.9-4.3,1.8c-3,0-4-1.9-4-3.3
          c-0.1-1,0.3-2,1.1-2.8c0.9-0.8,2-1.1,4-1.7c0.9-0.2,1.7-0.6,2.4-1.1c0.4-0.3,0.7-0.7,0.7-1.2v-0.9c0-2.3-0.6-3.3-1.9-3.3
          c-1.1-0.1-2.1,0.7-2.2,1.8c0,0.2,0,0.4,0,0.6c0,0.8-0.8,1.5-2.4,1.5c-1,0-1.2-0.4-1.2-1c0-1.5,2.4-3.7,6.2-3.7
          c4.6,0,4.6,2.2,4.6,4.6L484.3,507.6z M481.2,503.2c-0.3,0.5-0.8,0.7-2.5,1.4c-1.4,0.4-2.4,1.8-2.3,3.3c-0.1,1.2,0.8,2.4,2,2.5
          c1.5,0,2.7-1.1,2.8-2.6c0,0,0,0,0-0.1L481.2,503.2z M487.5,498.3c-0.3,0-0.3-0.1-0.3-0.4s0-0.4,0.4-0.4c1-0.2,2-0.6,2.7-1.3
          c0.7-0.6,1.2-1.4,1.6-2.2c0.1-0.3,0.2-0.3,0.5-0.3s0.4,0.1,0.4,0.3c0,1-0.1,2.2-0.1,3.2c1.4,0,3.1,0,4.4-0.1c0.1,0,0.1,0.1,0.1,0.2
          l-0.3,0.9c0,0.1-0.2,0.2-0.3,0.2c-1.1-0.1-2.6-0.1-3.9-0.1l-0.1,7.9c0,1.7,0.2,2.6,0.6,3.2c0.4,0.5,1,0.8,1.7,0.8
          c0.7,0,1.5-0.3,2-0.8c0.2-0.1,0.3-0.1,0.5,0.1s0.3,0.3,0.1,0.6c-1,1.1-2.3,1.6-3.8,1.6c-1.2,0.1-2.4-0.3-3.3-1c-0.8-1-1.2-2.2-1-3.5
          c0-1.5,0-2.9,0.1-4.4l0.1-4.4L487.5,498.3z M518.2,497.3c1.8,0,3.5-0.2,5.3-0.5c0.2,0,0.3,0.1,0.3,0.3c-0.1,2.2-0.1,4.1-0.1,5.5v3.9
          c-0.1,1.1,0,2.2,0.2,3.3c0.3,0.5,0.8,0.5,1.8,0.5c0.2,0,0.2,0.1,0.2,0.4s0,0.4-0.2,0.4c-1.1,0-2.4-0.1-3.4-0.1s-2.4,0.1-3.4,0.1
          c-0.3,0-0.3-0.1-0.3-0.4s0.1-0.4,0.3-0.4c0.9-0.1,1.3-0.1,1.5-0.5s0.2-1.5,0.2-3.3v-4.9c0.1-0.9,0-1.8-0.2-2.6
          c-0.2-0.5-0.5-0.6-2.1-0.6h-3.8v8.1c-0.1,1.1,0,2.2,0.3,3.3c0.2,0.4,1,0.5,1.9,0.6c0.2,0,0.3,0.1,0.3,0.4s-0.1,0.4-0.3,0.4
          c-1.2,0-2.6-0.1-3.7-0.1s-2.6,0.1-3.7,0.1c-0.3,0-0.3-0.1-0.3-0.4s0.1-0.3,0.3-0.4c0.8-0.1,1.6-0.1,1.8-0.6s0.3-1.4,0.3-3.3v-4.4
          c0-1.3,0-2.6,0-3.7c-0.7,0-1.5,0-2.6,0.1c-0.1,0-0.2-0.1-0.2-0.2l0.3-0.9c0-0.1,0.2-0.2,0.3-0.2c0.8,0,1.5,0,2.2,0
          c0-0.6,0-1.4,0-1.8c0-2.4,1.1-4.7,3-6.2c1.7-1.4,3.8-2.1,5.9-2c2.1,0,3.5,0.5,3.5,1.6c0,1.3-0.7,2.5-1.5,2.5s-1.4-0.7-1.9-1.4
          c-0.7-0.8-1.2-1.3-2-1.3c-1.1,0-2.4,1.1-3.1,3.3c-0.4,1.8-0.6,3.6-0.6,5.4L518.2,497.3z M527.5,498.8c-0.3,0-0.4,0-0.4-0.3
          s0-0.4,0.4-0.5c1.7-0.4,3.4-0.8,5-1.4c0.2,0,0.4,0.1,0.4,0.3c0,0.9-0.1,1.8-0.1,3c0.9-1.7,2.2-3.4,4-3.4c1.1,0,1.7,0.4,1.7,1
          c0,1.2-0.9,2.3-1.7,2.3s-1.1-0.7-1.7-0.7c-0.4,0-0.8,0.2-1.4,0.9c-0.6,0.8-0.9,1.7-0.9,2.7v3.8c-0.1,1.1,0,2.2,0.2,3.3
          c0.3,0.5,0.7,0.5,1.8,0.6c0.2,0,0.2,0.1,0.2,0.4s-0.1,0.3-0.2,0.3c-1.2,0-2.5-0.1-3.5-0.1s-2.5,0.1-3.6,0.1c-0.2,0-0.3,0-0.3-0.3
          s0.1-0.4,0.3-0.4c0.9-0.1,1.4-0.1,1.6-0.6c0.3-1.1,0.4-2.2,0.3-3.3v-4.6c0-0.9,0-1.7-0.3-2.5c-0.1-0.5-0.5-0.8-1-0.7L527.5,498.8z
          M543.7,504.6c-2.3-1.1-3.6-1.8-3.6-4.5c0-1.7,1.5-3.7,4.9-3.7c0.8,0,1.5,0.1,2.2,0.3c0.7,0.1,1.3,0.1,2,0c0.1,1.1,0.2,2.6,0.3,3.6
          c0,0.2-0.1,0.3-0.3,0.3c-0.2,0.1-0.4,0-0.5-0.2c0,0,0,0,0,0c-0.8-1.7-2.1-3.1-3.6-3.1c-1.7,0-2.4,0.9-2.4,2.2c0,0.9,0.7,1.5,2.3,2.2
          l1.1,0.5c2.7,1.2,4.3,2.1,4.3,4.9c0,2.1-1.6,4.5-5.5,4.5c-1.5,0-3-0.3-4.4-0.9c-0.3-1.1-0.5-2.2-0.7-3.4c0-0.2,0-0.3,0.3-0.3
          s0.3,0,0.4,0.2c1,2,2.7,3.4,4.3,3.4c1.4,0.1,2.6-0.9,2.7-2.3c0-0.2,0-0.3,0-0.5c0-1.3-1-1.9-2.8-2.8L543.7,504.6z M552.3,498.3
          c-0.3,0-0.3-0.1-0.3-0.4s0-0.4,0.4-0.4c1-0.2,2-0.6,2.7-1.3c0.7-0.6,1.2-1.4,1.6-2.2c0.1-0.3,0.2-0.3,0.5-0.3s0.4,0.1,0.4,0.3
          c0,1-0.1,2.2-0.1,3.2c1.4,0,3.1,0,4.4-0.1c0.1,0,0.2,0.1,0.1,0.2l-0.3,0.9c0,0.1-0.2,0.2-0.3,0.2c-1.2-0.1-2.6-0.1-3.9-0.1l-0.1,7.9
          c0,1.7,0.2,2.6,0.6,3.2c0.4,0.5,1,0.8,1.7,0.8c0.7,0,1.5-0.3,2-0.8c0.2-0.1,0.3-0.1,0.5,0.1s0.3,0.3,0.1,0.6c-1,1.1-2.3,1.6-3.8,1.6
          c-1.2,0.1-2.4-0.3-3.3-1c-0.8-1-1.2-2.2-1.1-3.5c0-1.5,0-2.9,0.1-4.4l0.1-4.4L552.3,498.3z M578,504.6c-2.3-1.1-3.6-1.8-3.6-4.5
          c0-1.7,1.5-3.7,4.9-3.7c0.8,0,1.5,0.1,2.2,0.3c0.7,0.1,1.3,0.1,2,0c0.1,1.1,0.2,2.6,0.3,3.6c0,0.2-0.1,0.3-0.3,0.3
          c-0.2,0.1-0.4,0-0.5-0.2c0,0,0,0,0,0c-0.8-1.7-2.1-3.1-3.6-3.1c-1.7,0-2.4,0.9-2.4,2.2c0,0.9,0.7,1.5,2.3,2.2l1.1,0.5
          c2.7,1.2,4.3,2.1,4.3,4.9c0,2.1-1.6,4.5-5.5,4.5c-1.5,0-3-0.3-4.4-0.9c-0.3-1.1-0.5-2.2-0.7-3.4c0-0.2,0-0.3,0.3-0.3s0.3,0,0.4,0.2
          c1,2,2.7,3.4,4.3,3.4c1.4,0.1,2.6-0.9,2.7-2.3c0-0.2,0-0.3,0-0.5c0-1.3-1.1-1.9-2.8-2.8L578,504.6z M589,502c0-0.9,0-1.8-0.2-2.6
          c-0.2-0.5-0.5-0.7-1.2-0.6l-0.8,0.1c-0.3,0-0.4,0-0.4-0.3s0-0.4,0.3-0.5c1.2-0.3,3.6-0.9,5-1.4c0.2,0,0.3,0.1,0.4,0.3c0,0,0,0,0,0
          c-0.1,2.3-0.1,4-0.1,5.5v4.1c-0.1,1.1,0,2.2,0.2,3.3c0.3,0.5,0.8,0.5,1.8,0.5c0.2,0,0.2,0.1,0.2,0.4s0,0.4-0.2,0.4
          c-1.1,0-2.4-0.1-3.4-0.1s-2.5,0.1-3.5,0.1c-0.3,0-0.3-0.1-0.3-0.4s0.1-0.4,0.3-0.4c0.9-0.1,1.4-0.1,1.7-0.5s0.3-1.5,0.3-3.3L589,502
          z M592.5,490c0,1.2-0.9,2.1-2.1,2.1c-1.2,0-2.1-0.9-2.1-2.1c0-1.2,0.9-2.1,2.1-2.1c0,0,0,0,0.1,0C591.5,488,592.4,488.9,592.5,490
          L592.5,490z M604.5,508.8c5.4-0.1,6,2.5,6,4.1c0,2.5-1.5,5.6-8.1,5.6c-4.2,0-6.5-1.8-6.5-3.8c0-1.7,1-2.8,3.4-3.6
          c-1.9-0.5-2.5-1.3-2.5-2.3c0-1.3,1.1-2.1,3.4-3.1c-2.3-0.6-3.9-1.8-3.9-4.4s2.3-4.9,5.7-4.9c1.2,0,2.3,0.2,3.4,0.7
          c2.1,0,3.7-0.1,5-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0,0l-0.4,1c-0.1,0.1-0.1,0.2-0.3,0.2c-1.1-0.1-2-0.1-3.3-0.1
          c0.9,0.7,1.4,1.8,1.4,2.9c0,2.7-1.9,4.8-5.7,4.8c-0.4,0-0.8,0-1.1-0.1c-1,0.6-1.6,1.1-1.6,1.9s0.7,1,1.9,1L604.5,508.8z
          M602.5,511.5c-0.7,0-1.4-0.1-2.1-0.1c-0.2,0.2-0.5,0.3-0.7,0.6c-0.6,0.7-0.9,1.5-0.8,2.4c0,1.9,1.6,3.3,4.2,3.3
          c2.9,0,4.6-1.5,4.6-3.5c0-1.3-0.4-2.6-3.9-2.6H602.5z M599.7,501.3c0,2.4,1,3.9,2.6,3.9s2.6-1.5,2.6-3.9s-1-3.9-2.6-3.9
          S599.7,499,599.7,501.3L599.7,501.3z M616.9,499.4c0.9-1.7,2.7-2.9,4.7-2.9c1.1,0,4.7,0.4,4.7,4.4c0,1.7-0.1,2.7-0.1,3.5v2.1
          c0,1.8,0,2.8,0.2,3.3s0.8,0.5,1.7,0.6c0.2,0,0.2,0.1,0.2,0.4s-0.1,0.3-0.3,0.3c-1.2,0-2.4-0.1-3.5-0.1s-2.3,0.1-3.5,0.1
          c-0.3,0-0.3,0-0.3-0.3s0.1-0.3,0.3-0.4c0.9-0.1,1.4-0.1,1.6-0.6s0.2-1.5,0.2-3.3v-4.2c0.1-0.9,0-1.8-0.3-2.6c-0.4-1-1.3-1.6-2.4-1.6
          c-1.2,0.1-2.2,0.7-2.8,1.8c-0.5,0.7-0.7,1.6-0.7,2.5v4.2c-0.1,1.1,0,2.2,0.2,3.3c0.2,0.4,0.7,0.5,1.7,0.6c0.2,0,0.3,0.1,0.3,0.3
          s-0.1,0.4-0.3,0.4c-1.2,0-2.3-0.1-3.3-0.1s-2.5,0.1-3.7,0.1c-0.2,0-0.3-0.1-0.3-0.4s0.1-0.3,0.3-0.3c1-0.1,1.4-0.1,1.6-0.6
          c0.3-1.1,0.4-2.2,0.3-3.3v-14c0-0.8,0-1.7-0.1-2.5c-0.1-0.5-0.5-0.8-0.9-0.7c0,0,0,0-0.1,0l-0.9,0.1c-0.3,0-0.3,0-0.4-0.4
          s0-0.4,0.3-0.4c1.7-0.4,3.4-0.8,5.1-1.4c0.1,0,0.3,0.1,0.3,0.3c-0.1,2.3-0.2,4.5-0.2,7.6L616.9,499.4z M629.3,498.3
          c-0.3,0-0.3-0.1-0.3-0.4s0-0.4,0.4-0.4c1-0.2,2-0.6,2.7-1.3c0.7-0.6,1.2-1.4,1.6-2.2c0.1-0.3,0.2-0.3,0.5-0.3s0.4,0.1,0.4,0.3
          c0,1-0.1,2.2-0.1,3.2c1.4,0,3.1,0,4.4-0.1c0.1,0,0.2,0.1,0.1,0.2l-0.3,0.9c0,0.1-0.2,0.2-0.3,0.2c-1.2-0.1-2.6-0.1-3.9-0.1l-0.1,7.9
          c0,1.7,0.2,2.6,0.6,3.2c0.4,0.5,1,0.8,1.7,0.8c0.7,0,1.5-0.3,2-0.8c0.2-0.1,0.3-0.1,0.5,0.1s0.3,0.3,0.1,0.6c-1,1-2.3,1.6-3.8,1.6
          c-1.2,0.1-2.4-0.3-3.3-1c-0.8-1-1.2-2.2-1.1-3.5c0-1.5,0-2.9,0.1-4.4l0.1-4.4L629.3,498.3z M641.9,515.2c-0.1,0.1-0.3,0.1-0.6-0.1
          s-0.2-0.5,0-0.6c1.3-0.9,2-1.9,2-2.8c0-0.4-0.5-0.8-0.8-1c-0.5-0.2-1.1-0.4-1.1-1.2c0.1-1.2,1-2.1,2.2-2.2c1.2,0.2,2.1,1.3,2,2.5
          C645.5,511.2,645,513,641.9,515.2z M655.7,505.4c0,1.3,0,3,0.4,3.8s1.6,0.9,3,0.9c1.9,0.1,3.8-0.4,5.3-1.6c1-0.9,1.8-1.9,2.4-3.1
          c0.1-0.2,0.2-0.3,0.5-0.2s0.3,0.3,0.3,0.4c-0.4,1.9-1,3.7-1.6,5.5c-2.8-0.1-5.6-0.1-8.2-0.1c-2.7,0-5.7,0-8.4,0.1
          c-0.2,0-0.3-0.1-0.3-0.4s0.1-0.4,0.3-0.4c1.2-0.1,1.8-0.2,2.1-0.8s0.4-2,0.4-4.1v-11.3c0-3,0-3.4-0.4-4.1s-1-0.6-2.2-0.7
          c-0.3,0-0.3-0.1-0.3-0.4s0.1-0.4,0.3-0.4c1.3,0,3,0.1,4.4,0.1s3.1-0.1,4.4-0.1c0.3,0,0.3,0.1,0.3,0.4s-0.1,0.4-0.3,0.4
          c-1.2,0.1-1.8,0.1-2.2,0.7s-0.3,2.1-0.3,4.1L655.7,505.4z M670.9,502c0-0.9,0-1.8-0.2-2.6c-0.2-0.5-0.5-0.7-1.2-0.6l-0.8,0.1
          c-0.3,0-0.4,0-0.4-0.3s0-0.4,0.3-0.5c1.2-0.3,3.6-0.9,5-1.4c0.2,0,0.3,0.1,0.4,0.3c0,0,0,0,0,0c-0.1,2.3-0.1,4-0.1,5.5v4.1
          c-0.1,1.1,0,2.2,0.2,3.3c0.3,0.5,0.8,0.5,1.8,0.5c0.2,0,0.2,0.1,0.2,0.4s0,0.4-0.2,0.4c-1.1,0-2.4-0.1-3.4-0.1s-2.5,0.1-3.5,0.1
          c-0.2,0-0.3-0.1-0.3-0.4s0.1-0.4,0.3-0.4c0.9-0.1,1.4-0.1,1.7-0.5s0.2-1.5,0.2-3.3L670.9,502z M674.3,490c0,1.2-0.9,2.1-2.1,2.1
          c-1.2,0-2.1-0.9-2.1-2.1s0.9-2.1,2.1-2.1c0,0,0.1,0,0.1,0C673.4,488,674.3,488.9,674.3,490z M684.7,505.6c0.3,1,0.5,1.5,0.8,2.6
          c0.4-1.1,0.6-1.6,1-2.7l1.3-4.1c0.5-1.5,0.7-2.6,0.4-3.1s-0.8-0.5-1.6-0.6c-0.2,0-0.2-0.1-0.2-0.4s0.1-0.4,0.3-0.4
          c0.9,0.1,2.1,0.1,2.9,0.1s1.8,0,2.6-0.1c0.3,0,0.3,0.1,0.3,0.4s-0.1,0.3-0.3,0.4c-0.6,0.1-1.1,0.3-1.6,0.7c-0.7,0.9-1.2,1.9-1.5,3
          l-2.1,5.8c-0.6,1.6-0.8,2.3-1.3,3.6c-0.6,0.3-1.2,0.6-1.9,0.7c-0.1,0-0.2-0.1-0.3-0.2c-0.4-1.3-0.8-2.4-1.4-4.1l-2.2-5.9
          c-0.4-1.1-0.8-2.2-1.5-3.2c-0.4-0.4-0.7-0.4-1.6-0.5c-0.2,0-0.3-0.1-0.3-0.4s0-0.4,0.3-0.4c1.2,0,2.5,0.1,3.6,0.1s2.6,0,3.8-0.1
          c0.2,0,0.3,0.1,0.3,0.4s0,0.4-0.2,0.4c-0.9,0-1.5,0.1-1.7,0.5s0.2,1.4,0.9,3.2L684.7,505.6z M696.9,504c0,4.1,2.5,6.1,4.4,6.1
          c1.7,0.1,3.4-0.8,4.3-2.2c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3
          c-1.2,2-3.4,3.2-5.7,3.1c-4.3,0-7.2-3.2-7.2-7.4s3-7.7,7.2-7.7c3.2,0,5.9,2.6,6,5.8c0,0.2,0,0.4-0.2,0.5c-0.2,0.2-0.3,0.2-0.5,0.2
          c-3.1-0.1-6.3-0.1-9-0.1C697,503.3,696.9,503.6,696.9,504z M703,501.7c0.3,0,0.3-0.2,0.3-0.5c-0.1-1.7-0.8-3.8-2.6-3.8
          s-3,1.8-3.4,4.3C699.1,501.8,701.1,501.8,703,501.7L703,501.7z M724.2,506.4c-0.1,1.1,0,2.2,0.3,3.3c0.2,0.4,1.1,0.5,2,0.6
          c0.2,0,0.3,0.1,0.3,0.4s-0.1,0.4-0.3,0.4c-1.2,0-2.8-0.1-3.8-0.1s-2.6,0.1-3.8,0.1c-0.3,0-0.3-0.1-0.3-0.4s0.1-0.3,0.3-0.4
          c0.8-0.1,1.6-0.1,1.8-0.6s0.3-1.4,0.3-3.3V502c0-1.4,0-2.6,0-3.6c-0.8,0-1.5,0-2.6,0.1c-0.1,0-0.2-0.1-0.2-0.2l0.2-0.9
          c0-0.1,0.2-0.2,0.3-0.2c0.8,0,1.5,0,2.2,0c0-0.6,0-1.2,0-1.6c0-2.3,0.9-4.6,2.6-6.2c1.2-1.4,2.9-2.2,4.8-2.1c1.1,0,1.8,0.3,1.8,1
          c0,1.5-0.8,2.4-1.4,2.4s-0.9-0.3-1.3-0.8s-0.9-1-1.3-0.9c-1,0.2-1.5,1.1-1.7,3.3c-0.2,1.7-0.2,3.3-0.2,5c1.2,0,2.5,0,3.8-0.1
          c0.2,0,0.2,0.1,0.2,0.2l-0.3,0.9c0,0.1-0.1,0.2-0.3,0.2c-1.2-0.1-2.3-0.1-3.5-0.1L724.2,506.4z M744.4,504.1c0,4.1-2.9,7.5-7.3,7.5
          c-3.9,0.1-7.2-3.1-7.2-7c0-0.2,0-0.3,0-0.5c0-4.2,3-7.6,7.2-7.6c4,0,7.2,3.2,7.3,7.2C744.4,503.8,744.4,503.9,744.4,504.1z
          M733.5,504.1c0,3.9,1.2,6.6,3.6,6.6s3.6-2.9,3.6-6.6c0-3.9-1.1-6.6-3.6-6.6S733.5,500.3,733.5,504.1z M746.8,498.8
          c-0.3,0-0.4,0-0.4-0.3s0-0.4,0.4-0.5c1.7-0.4,3.4-0.8,5-1.4c0.2,0,0.4,0.1,0.4,0.3c0,0.9-0.1,1.8-0.1,3c0.9-1.7,2.2-3.4,4-3.4
          c1.1,0,1.7,0.4,1.7,1c0,1.2-0.9,2.3-1.7,2.3s-1.1-0.7-1.7-0.7c-0.4,0-0.8,0.2-1.4,0.9c-0.6,0.8-0.9,1.7-0.9,2.7v3.8
          c-0.1,1.1,0,2.2,0.2,3.3c0.3,0.5,0.7,0.5,1.8,0.6c0.2,0,0.2,0.1,0.2,0.4s-0.1,0.3-0.2,0.3c-1.2,0-2.5-0.1-3.5-0.1s-2.5,0.1-3.6,0.1
          c-0.2,0-0.3,0-0.3-0.3s0.1-0.4,0.3-0.4c0.9-0.1,1.4-0.1,1.6-0.6c0.3-1.1,0.4-2.2,0.3-3.3v-4.6c0-0.9,0-1.7-0.3-2.5
          c-0.1-0.5-0.6-0.8-1-0.7c0,0,0,0,0,0L746.8,498.8z M762.5,504c0,4.1,2.5,6.1,4.4,6.1c1.7,0.1,3.4-0.8,4.3-2.2
          c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.3c-1.2,2-3.4,3.2-5.7,3.1
          c-4.3,0-7.2-3.2-7.2-7.4s3-7.7,7.2-7.7c3.2,0.1,5.8,2.6,6,5.8c0,0.2,0,0.4-0.2,0.5c-0.2,0.2-0.3,0.2-0.5,0.2c-3.1-0.1-6.3-0.1-9-0.1
          C762.6,503.3,762.5,503.6,762.5,504z M768.6,501.7c0.3,0,0.3-0.2,0.3-0.5c-0.1-1.7-0.8-3.8-2.6-3.8s-3,1.8-3.4,4.3
          C764.8,501.8,766.7,501.8,768.6,501.7L768.6,501.7z M781,505.6c0.3,1,0.5,1.5,0.8,2.6c0.4-1.1,0.6-1.6,1-2.7l1.3-4.1
          c0.5-1.5,0.7-2.6,0.4-3.1s-0.8-0.5-1.6-0.6c-0.2,0-0.2-0.1-0.2-0.4s0.1-0.4,0.3-0.4c0.9,0.1,2.1,0.1,2.9,0.1s1.8,0,2.6-0.1
          c0.3,0,0.3,0.1,0.3,0.4s-0.1,0.3-0.3,0.4c-0.6,0.1-1.1,0.3-1.6,0.7c-0.7,0.9-1.2,1.9-1.5,3l-2.1,5.8c-0.6,1.6-0.8,2.3-1.3,3.6
          c-0.6,0.3-1.2,0.5-1.9,0.7c-0.1,0-0.2-0.1-0.3-0.2c-0.4-1.3-0.8-2.4-1.4-4.1l-2.2-5.9c-0.3-1.1-0.8-2.2-1.5-3.2
          c-0.4-0.4-0.7-0.4-1.6-0.5c-0.2,0-0.3-0.1-0.3-0.4s0-0.4,0.3-0.4c1.2,0,2.5,0.1,3.6,0.1s2.6,0,3.8-0.1c0.2,0,0.3,0.1,0.3,0.4
          s0,0.4-0.2,0.4c-0.9,0-1.5,0.1-1.7,0.5s0.2,1.4,0.9,3.2L781,505.6z M793.3,504c0,4.1,2.5,6.1,4.4,6.1c1.7,0.1,3.4-0.8,4.3-2.2
          c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1-0.1,0.2-0.2,0.3c-1.2,2-3.4,3.2-5.7,3.1
          c-4.3,0-7.2-3.2-7.2-7.4s3-7.7,7.2-7.7c3.2,0.1,5.8,2.6,6,5.8c0,0.2,0,0.4-0.2,0.5c-0.2,0.2-0.3,0.2-0.5,0.2c-3.1-0.1-6.3-0.1-9-0.1
          C793.3,503.2,793.2,503.6,793.3,504z M799.3,501.7c0.3,0,0.3-0.2,0.3-0.5c-0.1-1.7-0.8-3.8-2.6-3.8s-3,1.8-3.4,4.3
          C795.5,501.8,797.4,501.8,799.3,501.7L799.3,501.7z M805,498.8c-0.3,0-0.4,0-0.4-0.3s0-0.4,0.4-0.5c1.7-0.4,3.4-0.8,5-1.4
          c0.2,0,0.4,0.1,0.4,0.3c0,0.9-0.1,1.8-0.1,3c0.9-1.7,2.2-3.4,4-3.4c1,0,1.7,0.4,1.7,1c0,1.2-0.9,2.3-1.7,2.3s-1.1-0.7-1.7-0.7
          c-0.4,0-0.8,0.2-1.4,0.9c-0.6,0.8-0.9,1.7-0.9,2.7v3.8c-0.1,1.1,0,2.2,0.2,3.3c0.2,0.5,0.7,0.5,1.8,0.6c0.2,0,0.2,0.1,0.2,0.4
          s-0.1,0.3-0.2,0.3c-1.2,0-2.5-0.1-3.5-0.1s-2.5,0.1-3.6,0.1c-0.2,0-0.2,0-0.2-0.3s0.1-0.4,0.2-0.4c0.9-0.1,1.4-0.1,1.7-0.6
          c0.3-1.1,0.4-2.2,0.3-3.3v-4.6c0-0.9,0-1.7-0.3-2.5c-0.1-0.5-0.5-0.7-1-0.7L805,498.8z M838.9,507.6c0,2,0.4,2.7,1.1,2.7
          c0.3,0,0.6-0.1,0.9-0.2c0.1-0.1,0.3-0.1,0.4,0.2s0.1,0.3-0.1,0.5c-0.8,0.5-1.7,0.8-2.6,0.7c-1.1,0.1-2.2-0.6-2.6-1.7
          c-1.1,1.2-2.7,1.9-4.3,1.8c-3,0-4-1.9-4-3.3c-0.1-1,0.3-2,1-2.8c0.9-0.8,2-1.1,4-1.7c0.9-0.2,1.7-0.6,2.4-1.1
          c0.4-0.3,0.7-0.7,0.7-1.2v-0.9c0-2.3-0.6-3.3-1.9-3.3c-0.6,0-1.2,0.2-1.6,0.6c-0.4,0.5-0.6,1.1-0.6,1.7c0,0.8-0.8,1.5-2.4,1.5
          c-1,0-1.2-0.4-1.2-1c0-1.5,2.4-3.7,6.2-3.7c4.5,0,4.6,2.2,4.6,4.6L838.9,507.6z M835.8,503.2c-0.3,0.5-0.7,0.7-2.5,1.4
          c-1.4,0.4-2.4,1.8-2.3,3.3c-0.1,1.2,0.8,2.4,2,2.5c1.5,0,2.7-1.1,2.8-2.6c0,0,0,0,0-0.1L835.8,503.2z M842.5,498.7
          c-0.3,0-0.4,0-0.4-0.3s0-0.4,0.4-0.5c1.7-0.4,3.3-0.8,4.9-1.4c0.2,0,0.4,0.1,0.4,0.3c-0.1,0.9-0.1,1.8-0.1,2.6
          c0.9-1.8,2.8-2.9,4.8-3c0.9,0,4.5,0.5,4.5,4.4c0,1.1,0,2.7,0,3.5v2.1c-0.1,1.1,0,2.2,0.2,3.3c0.2,0.5,0.7,0.5,1.6,0.6
          c0.2,0,0.3,0.1,0.3,0.4s0,0.3-0.3,0.3c-1.2,0-2.3-0.1-3.4-0.1s-2.4,0.1-3.5,0.1c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.4,0.3-0.4
          c0.8-0.1,1.3-0.1,1.6-0.6c0.3-1.1,0.4-2.2,0.3-3.3v-4.2c0.1-0.9,0-1.8-0.4-2.7c-0.3-1-1.2-1.6-2.3-1.5c-1.2,0.1-2.2,0.7-2.8,1.8
          c-0.5,0.7-0.7,1.6-0.7,2.5v4.2c-0.1,1.1,0,2.2,0.2,3.3c0.3,0.5,0.7,0.5,1.7,0.6c0.2,0,0.3,0.1,0.3,0.4s-0.1,0.3-0.3,0.3
          c-1.1,0-2.3-0.1-3.3-0.1s-2.4,0.1-3.6,0.1c-0.2,0-0.2-0.1-0.2-0.3s0.1-0.3,0.2-0.4c0.9-0.1,1.4-0.1,1.7-0.6c0.2-1.1,0.3-2.2,0.2-3.3
          v-4.6c0.1-0.9,0-1.8-0.2-2.6c-0.1-0.4-0.5-0.7-1-0.7c0,0,0,0,0,0L842.5,498.7z M871.5,492.5c0.1-0.9,0-1.8-0.1-2.7
          c-0.1-0.5-0.6-0.6-1.1-0.6l-0.7,0.1c-0.4,0-0.4,0-0.5-0.3s0.1-0.4,0.4-0.5c1.7-0.4,3.4-0.8,5.1-1.4c0.2,0,0.4,0.1,0.4,0.3
          c-0.1,2.1-0.2,4.5-0.2,7.5v11.6c-0.1,1.1,0,2.2,0.2,3.2c0.2,0.5,0.5,0.5,1,0.5h0.7c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.4-0.3,0.4
          c-1.2,0.1-3.4,0.3-4.8,0.5c-0.2,0-0.3-0.1-0.3-0.4c0-0.8,0.1-1.3,0.1-2.1c-0.8,1.5-2.4,2.4-4.1,2.5c-4.2,0-6.7-3.1-6.7-7.4
          s2.7-7.7,7.1-7.7c1.4,0,2.7,0.6,3.7,1.6L871.5,492.5z M871.5,502c0.1-0.9-0.1-1.9-0.6-2.7c-0.5-1-1.5-1.7-2.7-1.8
          c-2.4,0-3.8,2.7-3.8,6.6s1.6,6.1,3.8,6.1c1.1-0.1,2.1-0.7,2.7-1.6c0.5-0.7,0.7-1.6,0.6-2.5V502z M892.1,504c0,4.1,2.5,6.1,4.4,6.1
          c1.7,0.1,3.4-0.8,4.3-2.2c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3
          c-1.2,2-3.4,3.2-5.7,3.1c-4.3,0-7.2-3.2-7.2-7.4s3-7.7,7.2-7.7c3.2,0.1,5.8,2.6,6,5.8c0,0.2,0,0.4-0.2,0.5c-0.2,0.2-0.3,0.2-0.5,0.2
          c-3.1-0.1-6.3-0.1-9-0.1C892.1,503.3,892.1,503.6,892.1,504z M898.2,501.7c0.3,0,0.3-0.2,0.3-0.5c-0.1-1.7-0.8-3.8-2.6-3.8
          s-3,1.8-3.4,4.3C894.3,501.8,896.3,501.8,898.2,501.7L898.2,501.7z M910.6,505.6c0.3,1,0.5,1.5,0.8,2.6c0.4-1.1,0.6-1.6,1-2.7
          l1.3-4.1c0.5-1.5,0.7-2.6,0.4-3.1s-0.8-0.5-1.6-0.6c-0.2,0-0.2-0.1-0.2-0.4s0.1-0.4,0.2-0.4c0.9,0.1,2.1,0.1,2.9,0.1s1.8,0,2.6-0.1
          c0.3,0,0.3,0.1,0.3,0.4s-0.1,0.3-0.3,0.4c-0.6,0.1-1.1,0.3-1.6,0.7c-0.7,0.9-1.2,1.9-1.5,3l-2.1,5.8c-0.6,1.6-0.8,2.3-1.3,3.6
          c-0.6,0.3-1.2,0.6-1.9,0.7c-0.1,0-0.2-0.1-0.3-0.2c-0.4-1.3-0.8-2.4-1.4-4.1l-2.2-5.9c-0.3-1.1-0.8-2.2-1.5-3.2
          c-0.4-0.4-0.8-0.4-1.7-0.5c-0.2,0-0.3-0.1-0.3-0.4s0-0.4,0.3-0.4c1.2,0,2.5,0.1,3.6,0.1s2.6,0,3.8-0.1c0.2,0,0.3,0.1,0.3,0.4
          s0,0.4-0.2,0.4c-0.9,0-1.5,0.1-1.7,0.5s0.2,1.4,0.9,3.2L910.6,505.6z M922.8,504c0,4.1,2.5,6.1,4.4,6.1c1.7,0.1,3.4-0.8,4.3-2.2
          c0.1-0.1,0.2-0.2,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.2,0.3,0.3c0,0.1-0.1,0.2-0.2,0.3c-1.2,2-3.4,3.2-5.7,3.1
          c-4.3,0-7.2-3.2-7.2-7.4s3-7.7,7.2-7.7c3.2,0.1,5.8,2.6,6,5.8c0,0.2,0,0.4-0.2,0.5c-0.2,0.2-0.3,0.2-0.5,0.2c-3.1-0.1-6.3-0.1-9-0.1
          C922.9,503.3,922.8,503.6,922.8,504z M928.9,501.7c0.3,0,0.3-0.2,0.3-0.5c-0.1-1.7-0.8-3.8-2.6-3.8s-3,1.8-3.4,4.3
          C925.1,501.8,927,501.8,928.9,501.7L928.9,501.7z M934.5,498.8c-0.3,0-0.4,0-0.4-0.3s0-0.4,0.4-0.5c1.7-0.4,3.4-0.8,5-1.4
          c0.2,0,0.4,0.1,0.4,0.3c0,0.9-0.1,1.8-0.1,3c0.9-1.7,2.2-3.4,4-3.4c1,0,1.7,0.4,1.7,1c0,1.2-0.9,2.3-1.7,2.3s-1.1-0.7-1.7-0.7
          c-0.4,0-0.8,0.2-1.4,0.9c-0.6,0.8-0.9,1.7-0.9,2.7v3.8c-0.1,1.1,0,2.2,0.2,3.3c0.3,0.5,0.7,0.5,1.8,0.6c0.2,0,0.2,0.1,0.2,0.4
          s-0.1,0.3-0.2,0.3c-1.2,0-2.5-0.1-3.5-0.1s-2.5,0.1-3.6,0.1c-0.2,0-0.3,0-0.3-0.3s0.1-0.4,0.3-0.4c0.9-0.1,1.4-0.1,1.6-0.6
          c0.3-1.1,0.4-2.2,0.3-3.3v-4.6c0-0.9,0-1.7-0.3-2.5c-0.1-0.5-0.5-0.7-1-0.7L934.5,498.8z"/>
        <g class="an10">
          <path class="st4" d="M4.7,367.7c-1,0-2,0.2-3,0.5L1,365.8c1.2,0.3,2.5,0.5,3.8,0.5h15c1.9-2.1,2.4-2.4,2.9-2.4s1,0.4,3,2.2
            c0.9,0.9,1.3,1.3,1.3,1.8s-0.5,1-1.8,1.3c-0.3,0.1-0.6,0.3-0.8,0.6c-2,2.6-4.3,4.9-6.9,6.9c-0.2,0.2-0.7-0.1-0.6-0.4
            c1.4-2.7,2.4-5.6,3.1-8.6h-4.1v18.4c0,3.4,0.1,6.4,0.1,7.8c0,2.4-0.6,3.6-2.4,4.6c-1,0.7-2.3,1-3.5,1.1c-1.1,0-1.1-0.1-1.4-1.4
            c-0.4-2.1-1.8-3.1-6.3-4.1c-0.3-0.1-0.3-0.9,0-0.9c3,0.2,5.3,0.3,7.3,0.3c0.6,0,0.8-0.3,0.8-0.9v-24.9H4.7z M17.8,349.8
            c2.1-2.3,2.6-2.6,3.1-2.6s1,0.4,3.4,2.6c1.1,1.1,1.4,1.5,1.4,2c0,0.6-0.4,0.9-2.1,1.1c-2.4,2.1-5,4-7.7,5.8c1.2,0.8,1.9,2.1,2,3.5
            c0.1,1.7-1.3,3.2-3,3.3c0,0,0,0,0,0c-1.5,0-2.1-0.6-2.9-3c-0.9-2.7-2.4-5.1-4.5-7c-0.2-0.2-0.1-0.7,0.2-0.6c2.5,0.6,4.8,1.6,7,3
            c1.4-2.1,2.5-4.3,3.3-6.7H6c-1,0-2,0.2-2.9,0.5l-0.7-2.3c1.1,0.3,2.2,0.5,3.4,0.5L17.8,349.8z M35.9,393.8c-5.5,3.7-9.9,5.2-17,6
            c-0.3,0.1-0.5-0.6-0.2-0.7c3.8-1.5,7.3-3.6,10.4-6.3c1.3-1.2,2.4-2.5,3.3-4c4.4,2.4,6.3,3.6,6.3,4.3
            C38.6,393.6,38.1,393.9,35.9,393.8z M43.6,351.1c-1.7,2.7-3.7,5.2-6,7.5h9.1c2.2-2.3,2.7-2.7,3.1-2.7s1.1,0.4,3.3,2.1
            c1,0.8,1.4,1.2,1.4,1.7c0,0.7-0.4,1.2-1.7,1.9v4.1c0,11.1,0.2,18.6,0.2,21c0,1.5-1.1,2.4-3.3,2.4c-1.8,0-2.4-0.7-2.4-2v-0.5H32.8
            v0.3c0,1.4-1.2,2.3-3.2,2.3c-1.6,0-2.4-0.6-2.4-1.8c0-2.6,0.3-5,0.3-15.5c0-5.3-0.1-10.7-0.4-16.1c2.1,0.8,4.2,1.7,6.2,2.9h2.5
            c0.6-2.5,0.9-5,1.1-7.5h-6.3c-1.3,0-2.5,0.1-3.8,0.4l-0.6-2.2c1.4,0.3,2.9,0.5,4.3,0.5h16.3c2.8-3.1,3.5-3.5,3.9-3.5
            s1.2,0.5,3.9,2.5c1.2,1,1.7,1.4,1.7,1.8s-0.2,0.6-0.8,0.6H43.6z M47.3,360H32.8v7h14.5V360z M47.3,368.4H32.8v7.5h14.5V368.4z
            M47.3,377.3H32.8v8.1h14.5V377.3z M52.3,400c-1,0-2-0.7-2.4-1.6c-2-3.4-5-6.2-8.6-7.9c-0.2-0.1-0.1-0.7,0.2-0.7
            c9.3,0.9,13.6,3.3,13.6,7.2c0.1,1.5-1.1,2.9-2.6,3C52.4,399.9,52.4,399.9,52.3,400L52.3,400z M68.7,382.2c-2.1,6.1-5.2,11.4-8.1,14
            c-0.2,0.1-0.4,0-0.5-0.1c-0.1-0.1-0.1-0.2,0-0.3c1.6-3.6,2.7-7.3,3.4-11.1c0.4-2.1,0.5-4.1,0.5-6.2c6,1.7,6.5,2,6.5,2.7
            S69.9,382,68.7,382.2z M67.5,370.8c2.6-3.6,4.9-7.3,6.9-11.2c1.1-2,2-4,2.7-6.1c5,2.3,6.6,3.3,6.6,4c0,0.5-0.5,0.9-2.3,0.8
            c-6.2,7.2-6.5,7.6-12.2,12.5c3.4,0,6.8-0.1,10.1-0.2c-0.5-1.8-1.4-3.5-2.6-5c-0.2-0.2,0.3-0.5,0.5-0.4c5.2,2.8,7.5,5.4,7.5,8.4
            c0,2.1-1.1,3.5-2.7,3.5s-2.2-0.5-2.5-4.4l-0.1-0.6c-1.3,0.5-2.6,0.8-3.9,1.2v8.2c0,6.3,0.2,14.3,0.2,16.9c0,1.6-1,2.3-3.1,2.3
            c-1.5,0-2.4-0.5-2.4-1.8s0.3-5.2,0.3-12.9v-11.5c-2,0.4-4.1,0.8-6.3,1.1c-0.4,1.2-0.8,1.7-1.3,1.7c-0.7,0-1.2-1.2-2.2-6.5
            c1.5,0.1,2.1,0.1,3.1,0.1H67.5z M70,349.2c0.4-1.2,0.7-2.4,0.9-3.6c4.7,1.7,7.1,2.9,7.1,3.7c0,0.6-0.7,1-2.4,1
            c-3.9,4.3-4.9,5.3-6.8,6.8c2,1.3,2.8,2.7,2.8,4.5c0,2.1-1.1,3.4-2.8,3.4s-2.1-0.4-3.1-3.7c-0.6-2.5-2-4.7-3.9-6.4
            c-0.2-0.2,0.1-0.6,0.3-0.5c1.8,0.5,3.5,1.1,5.2,1.9C68.4,354,69.3,351.6,70,349.2z M81.9,391.6c-1.5,0-2.1-0.9-2.6-4.9
            c-0.3-2.8-1.2-5.6-2.7-8c-0.2-0.2,0.2-0.5,0.5-0.4c5.3,3.3,7.7,6.4,7.7,9.5C84.8,390,83.6,391.6,81.9,391.6L81.9,391.6z
            M91.7,359.5c-2.1,3.5-4.8,6.6-7.9,9.2c-0.2,0.2-0.8-0.2-0.7-0.4c2.6-4.6,4.6-9.6,5.9-14.7c0.7-2.5,1.2-5,1.4-7.6
            c6.7,1.7,8.1,2.4,8.1,3.1s-0.6,1.1-2.4,1.4c-1.1,2.6-2.3,5.2-3.7,7.6h14.1c2.3-2.7,2.8-3.1,3.3-3.1s1.1,0.4,3.4,2.5
            c1.1,1,1.4,1.4,1.4,1.9s-0.2,0.6-1.8,1.8c-0.4,13.1-1,22.5-3.1,31c-0.7,4.2-4.5,7.3-8.8,7.1c-1.2,0-1.4-0.2-1.7-1.7
            c-0.6-2.5-1.8-3.3-6.6-4.7c-0.3-0.1-0.3-1,0-1c3.9,0.4,6.1,0.5,6.9,0.5c3.9,0,4.4-0.4,4.8-1.9c1.8-6.4,2.7-15.8,2.9-31.1
            L91.7,359.5z M96.4,381c-1.3,0-2-0.6-2.4-2.6c-1.4-5-2.5-7.8-4.7-10.1c-0.1-0.2-0.1-0.4,0.1-0.5c0.1,0,0.2-0.1,0.2,0
            c5.6,1.6,10,5.6,10,9.4C99.7,379.1,98.4,381,96.4,381z M160.1,389.9c0,1.2,0.1,2.2,0.1,4.9s-0.6,3.9-2.9,4.9
            c-1.3,0.5-2.8,0.8-4.2,0.8c-0.6,0-0.8-0.2-1-1.7c-0.3-1.8-1.9-2.7-6.1-3.9c-0.3-0.1-0.2-0.8,0.1-0.8c2.8,0.2,5.8,0.4,7.6,0.4
            c0.8,0,1-0.2,1-0.9v-6.9h-30.4c-1,0-2,0.1-3,0.4l-0.7-2.2c1.2,0.3,2.5,0.5,3.7,0.5h30.2v-1.5c0-1.2-0.1-2.3-0.3-3.4
            c6.9,0.9,7.2,1,7.2,1.9c0,0.5-0.4,0.8-1.5,1.2v1.8h5.6c2.4-3.1,3.1-3.6,3.6-3.6s1.2,0.5,3.7,2.5c1.1,0.9,1.6,1.4,1.6,1.8
            s-0.2,0.6-0.8,0.6h-13.7L160.1,389.9z M149.4,377.3c3.5-0.1,6.9-0.3,10.4-0.5c-1.1-0.9-2.4-1.6-3.8-2.1c-0.3-0.1-0.2-0.7,0.1-0.7
            c8.1,0.7,12.3,2.8,12.3,6.2c0.1,1.3-0.9,2.5-2.2,2.6c0,0,0,0-0.1,0c-1,0-1.3-0.2-2.9-2.3c-0.6-0.9-1.3-1.7-2.1-2.4
            c-8.5,1.8-14.2,2.5-33.6,4.1c-0.5,1.4-0.8,1.7-1.4,1.7c-0.8,0-1.2-1.4-2.6-6.8c3.9,0.4,6.6,0.4,14.6,0.4h6.1v-5.2h-10.7v0.7
            c0,1.5-0.9,2.3-3.4,2.3c-1.4,0-2.2-0.5-2.2-1.8s0.3-3.6,0.3-10.5c0-2.5-0.1-5-0.3-7.5c2.2,0.7,4.3,1.7,6.2,2.9h10v-4.4h-16.9
            c-1.7,0-3.3,0.1-5,0.4l-0.7-2.2c1.8,0.3,3.5,0.5,5.3,0.5h17.2v-1.1c-0.1-1.8-0.2-3.7-0.4-5.6c6.9,0.6,7.5,1.3,7.5,2s-0.6,1.1-2,1.7
            v3.1h14.2c2.7-3.3,3.4-3.7,3.8-3.7s1.3,0.5,4.2,2.6c1.3,1,1.8,1.5,1.8,1.9s-0.2,0.6-0.8,0.6h-23.1v4.4h11c2.1-2.3,2.6-2.6,3.1-2.6
            s1.1,0.4,3.3,2.1c1,0.9,1.4,1.3,1.4,1.8s-0.3,1-1.7,2v1.8c0,4.6,0.2,7.8,0.2,8.9c0,1.5-1,2.1-3.4,2.1c-1.3,0-2.1-0.4-2.1-1.7v-0.4
            h-11.6V377.3z M140.2,396.8c-1.3,0-1.9-0.5-2.8-2.8c-0.8-2.2-2.4-4-4.4-5.2c-0.1-0.1-0.2-0.3-0.1-0.5c0.1-0.1,0.1-0.1,0.2-0.1
            c6.4,0.1,10,2.1,10,5.5C143.2,395.4,141.9,396.7,140.2,396.8C140.2,396.8,140.2,396.8,140.2,396.8z M144.3,359.7h-10.7v4.8h10.7
            L144.3,359.7z M144.3,365.8h-10.7v5h10.7L144.3,365.8z M161.1,364.5v-4.8h-11.7v4.8H161.1z M161.1,370.9v-5h-11.7v5H161.1z
            M188.7,374.3c-1.5,0.3-3.3,0.7-5.5,1.2c-0.4,1.2-0.7,1.7-1.2,1.7c-0.8,0-1.2-1.1-2.2-6.5c1.5,0.1,2.1,0.1,3.1,0.1h3.1
            c2.3-3.4,4.3-7,6-10.8c1-2.1,1.8-4.2,2.4-6.4c4.6,2.1,6.5,3.3,6.5,4.1c0,0.5-0.7,1-2.3,0.9c-3.1,4.4-6.7,8.5-10.7,12.2
            c3.1-0.1,6.2-0.2,9.3-0.3c-0.4-1.7-1.1-3.3-2.1-4.7c-0.2-0.2,0.2-0.5,0.5-0.4c4.6,2.5,6.7,5,6.7,7.8c0,1.8-1.2,3.3-2.6,3.3
            c-1.2,0-1.9-0.8-2.2-4l-0.1-0.6c-1.3,0.4-2.6,0.9-3.9,1.2v8.3c0,6.3,0.2,14.3,0.2,16.9c0,1.6-1.1,2.3-3,2.3c-1.7,0-2.4-0.6-2.4-1.9
            s0.4-5.2,0.4-12.9v-3.9c-0.5,0.3-1,0.5-1.5,0.6c-1.6,5-4.1,9.6-7.4,13.5c-0.2,0.2-0.8-0.1-0.6-0.4c1.1-3.2,1.9-6.4,2.5-9.7
            c0.5-2.4,0.8-4.9,0.8-7.4c4.1,1.3,5.7,1.8,6.2,2.3V374.3z M187.6,350.8c0.5-1.9,0.9-3.8,1.1-5.3c5.3,1.9,7.1,2.8,7.1,3.5
            s-0.7,1-2.4,1c-2,2.7-4.1,5.1-6.5,7.5c1.6,0.9,2.6,2.6,2.6,4.4c0,1.9-1.2,3.3-2.7,3.3s-1.7-0.2-2.7-3.6c-0.5-2.3-1.7-4.4-3.5-6
            c-0.2-0.2,0.1-0.7,0.4-0.6c1.6,0.4,3.1,1,4.6,1.7C186.4,354.9,187,352.8,187.6,350.8z M198.4,389.8c-1.5,0-1.9-1.2-2.1-3.9
            c-0.1-2.5-0.7-4.9-1.8-7.2c-0.1-0.2,0.2-0.5,0.5-0.3c4.2,2.9,6.1,5.3,6.1,8C201.1,388.4,200.1,389.8,198.4,389.8L198.4,389.8z
            M218.4,385.1c0,4.3,0.2,8.1,0.2,9.7c0.1,1.5-0.5,3-1.6,4.1c-1.2,1-2.7,1.4-4.3,1.4c-1,0-1.2-0.2-1.5-1.6c-0.4-2.1-1.2-2.6-5.4-3.7
            c-0.3-0.1-0.2-0.7,0.1-0.7c2.7,0.2,4.7,0.4,6.4,0.4c0.8,0,1-0.2,1.1-1.1v-13.1c-0.4,0.4-1,0.7-1.5,0.8c-3,7.6-7.1,12.1-15.4,16.1
            c-0.2,0.1-0.7-0.4-0.5-0.5c5.2-4.5,8.9-10.5,10.5-17.2h-3.3c-1.2,0-2.3,0.2-3.4,0.5l-0.6-2.3c1.3,0.3,2.6,0.5,3.9,0.5h3.3
            c1.8-2.3,2.3-2.6,2.8-2.6s1,0.4,3.3,2.4c0.4,0.4,0.8,0.8,1.1,1.2v-8.6h-4.3v0.6c0,1.5-1.1,2.1-3,2.1c-1.3,0-2.1-0.4-2.1-1.7
            c0-2.3,0.2-4,0.2-13.2c0-2.9-0.1-5.7-0.2-8.5c2,0.8,4,1.8,5.8,2.9h2.7c0.2-0.9,0.4-2,0.5-3.1c0.2-1.4,0.3-2.9,0.4-4.2
            c5.3,1.3,7.1,2,7.1,2.7s-0.6,1-2.1,1c-1.2,1.3-2.4,2.4-3.7,3.5h8c2.2-2.3,2.7-2.7,3.1-2.7s1.1,0.4,3.1,2.1c0.9,0.8,1.3,1.2,1.3,1.7
            s-0.1,0.7-1.7,1.9v2.4c0,6.5,0.2,11.3,0.2,12.8c0.1,0.8-0.4,1.5-1.1,1.8c4.7,2.8,5.9,3.6,5.9,4.3s-0.6,1-2.4,1.1
            c-4.3,2.7-5.4,3.4-7.6,4.3c3,3.7,7.1,6.5,11.6,8c0.3,0.1,0.2,0.4,0,0.5c-1.7,1-3,2.6-3.5,4.6c-0.5,1.5-0.7,1.6-1.2,1.6
            s-0.8-0.3-1.6-1c-2.7-2.6-5-5.7-6.7-9c-1.8-4-2.7-7.2-3.8-13.6L218.4,385.1z M223.4,354h-14.3v6.6h14.3V354z M223.4,362h-14.3v7.2
            h14.3V362z M218.5,370.6c0.7,3.9,2.1,7.6,4.2,10.9c1-1.3,1.9-2.6,2.7-4c0.9-1.5,1.6-3,2.1-4.7c-0.6,0.2-1.2,0.3-1.8,0.3
            c-1.8,0-2.4-0.5-2.4-2v-0.5L218.5,370.6z"/>
          <path d="M314.3,395.7l-5.1,13.9h-42.7v-2c12.5-11.5,21.4-20.8,26.5-28c5.1-7.2,7.7-13.9,7.7-19.9c0.2-4.2-1.4-8.3-4.2-11.3
            c-2.6-2.8-6.2-4.4-10.1-4.4c-3.4,0-6.8,1.1-9.6,3.1c-3,2.3-5.2,5.5-6.3,9.1h-2c0.9-6.6,3.2-11.6,6.9-15.1c3.7-3.5,8.7-5.4,13.8-5.3
            c5.5-0.2,10.8,1.9,14.7,5.7c3.8,3.4,5.9,8.3,5.9,13.3c0,3.8-0.9,7.6-2.6,11c-2.7,5.8-6.9,11.9-12.8,18.3
            c-8.9,9.7-14.4,15.5-16.6,17.5h18.8c2.7,0.1,5.4-0.1,8.1-0.4c1.5-0.3,2.9-0.9,4.2-1.7c1.3-1,2.4-2.3,3.3-3.7L314.3,395.7z
            M324.6,411.1v-2c4.5,0,8.9-1.1,12.9-3.1c4-2.1,7.9-5.7,11.5-10.9c3.7-5.1,6.3-11,7.8-17.1c-5.6,3.6-10.6,5.4-15.1,5.4
            c-5,0-9.8-2.1-13.1-5.9c-3.6-3.9-5.4-9.2-5.4-15.7c-0.1-6.1,1.8-12,5.4-16.9c4.4-6,10.1-9,17.1-9c5.9,0,11,2.5,15.3,7.4
            c5.2,6.1,7.8,13.6,7.8,22.5c0,7.9-2,15.6-5.9,22.5c-4,7-9.7,13-16.5,17.3c-5.5,3.7-12.1,5.7-18.7,5.7L324.6,411.1z M357.6,374
            c0.6-3.7,0.9-7.4,1-11.1c-0.1-3.8-0.6-7.5-1.8-11.2c-0.9-3.4-2.6-6.6-5-9.2c-1.9-2-4.6-3.2-7.3-3.2c-3.2,0-5.9,1.4-8.3,4.3
            c-2.4,2.8-3.6,7.1-3.6,12.7c0,7.5,1.6,13.4,4.7,17.6c2.3,3.1,5.2,4.6,8.5,4.6c2-0.1,4-0.5,5.8-1.2
            C353.9,376.6,355.9,375.4,357.6,374z M422.4,335.9v2c-4,0.3-7.8,1.2-11.5,2.8c-3.3,1.6-6.3,3.8-8.8,6.4c-2.9,2.8-5.3,6.1-7.2,9.6
            c-2.1,4-3.7,8.2-4.8,12.6c5.1-3.5,10.3-5.3,15.4-5.3c5,0,9.7,2.2,12.9,6c3.6,4,5.4,9.1,5.4,15.4c0,6-1.9,11.8-5.5,16.5
            c-4.4,6-10.2,9.1-17.5,9.1c-4.7,0.1-9.2-1.7-12.6-4.9c-6.8-6.4-10.1-14.6-10.1-24.8c0-6.4,1.3-12.7,3.9-18.5
            c2.6-5.9,6.4-11.1,11.1-15.5c4.8-4.5,9.4-7.5,13.8-9.1c3.9-1.5,8.1-2.3,12.3-2.3H422.4z M389.2,373.2c-0.6,3.8-0.9,7.7-1,11.6
            c0.1,3.8,0.7,7.5,1.9,11.1c1,3.6,3,6.9,5.6,9.5c1.8,1.7,4.2,2.6,6.6,2.5c3.1,0,5.9-1.5,8.3-4.4c2.4-2.9,3.7-7.1,3.7-12.5
            c0.1-5.5-1.1-10.9-3.6-15.8c-2.4-4.4-5.9-6.7-10.3-6.7c-1.5,0-3,0.3-4.4,0.9C393.7,370.5,391.4,371.7,389.2,373.2L389.2,373.2z
            M477,335.9v2c-4,0.3-7.9,1.2-11.5,2.8c-3.3,1.6-6.3,3.8-8.8,6.4c-2.9,2.8-5.3,6.1-7.2,9.6c-2.1,4-3.7,8.2-4.8,12.6
            c5.1-3.5,10.3-5.3,15.4-5.3c5,0,9.7,2.2,12.9,6c3.6,4,5.4,9.1,5.4,15.4c0,6-1.9,11.8-5.5,16.5c-4.4,6-10.2,9.1-17.5,9.1
            c-4.7,0.1-9.2-1.7-12.6-4.9c-6.8-6.4-10.1-14.6-10.1-24.8c0-6.4,1.3-12.6,3.9-18.5c2.6-5.9,6.4-11.1,11.1-15.5
            c4.8-4.5,9.4-7.5,13.8-9.1c3.9-1.5,8.1-2.3,12.3-2.3L477,335.9z M443.8,373.2c-0.6,3.8-0.9,7.7-1,11.6c0.1,3.8,0.7,7.5,1.9,11.1
            c1,3.6,3,6.9,5.6,9.5c1.8,1.7,4.2,2.6,6.6,2.6c3.1,0,5.9-1.5,8.3-4.4s3.7-7.1,3.6-12.5c0.1-5.5-1.1-10.9-3.6-15.8
            c-2.4-4.4-5.9-6.7-10.3-6.7c-1.5,0-3,0.3-4.3,0.9C448.3,370.5,446,371.8,443.8,373.2L443.8,373.2z M487.1,381.1h27.6v8h-27.6V381.1
            z M540,373.2c-5.7-4.7-9.4-8.4-11.1-11.3c-1.6-2.7-2.5-5.7-2.5-8.9c0-4.6,2-9.1,5.4-12.1c3.6-3.4,8.5-5.1,14.5-5.1
            c5.8,0,10.5,1.6,14.1,4.7c3.6,3.2,5.3,6.8,5.3,10.8c-0.1,3-1.1,5.9-2.9,8.3c-1.9,2.8-5.9,6.1-12,9.9c6.3,4.8,10.4,8.6,12.4,11.4
            c2.6,3.3,4,7.3,4,11.5c0,5-2.1,9.7-5.8,13c-3.9,3.6-9,5.4-15.3,5.4c-6.9,0-12.2-2.2-16-6.5c-5.5-5.7-6.1-14.5-1.4-20.8
            C531,380.5,534.7,377,540,373.2z M542.5,375.2c-2.7,2.2-5,5-6.6,8.1c-1.4,3-2.2,6.2-2.1,9.5c0,4.6,1.3,8.3,3.8,11
            c2.4,2.7,5.9,4.2,9.6,4.1c3.4,0.2,6.7-1,9.2-3.3c2.2-2,3.5-4.9,3.5-7.9c0-2.4-0.7-4.8-2-6.9C555.3,386.3,550.2,381.3,542.5,375.2
            L542.5,375.2z M548.4,367.5c4.3-3.8,7-6.9,8.1-9.1c1.2-2.3,1.8-4.9,1.7-7.5c0-3.7-1.1-6.7-3.2-8.8c-2.3-2.2-5.4-3.3-8.6-3.2
            c-3.2-0.1-6.4,1-8.8,3.1c-2.2,1.8-3.4,4.5-3.4,7.4c0,1.9,0.5,3.8,1.4,5.5c1,2,2.4,3.8,4,5.3L548.4,367.5z M594.6,373.2
            c-5.7-4.7-9.4-8.4-11.1-11.3c-1.6-2.7-2.5-5.7-2.5-8.9c0-4.6,2-9.1,5.4-12.1c3.6-3.4,8.4-5.1,14.5-5.1c5.8,0,10.5,1.6,14.1,4.7
            c3.6,3.2,5.3,6.8,5.3,10.8c-0.1,3-1.1,5.9-2.9,8.3c-1.9,2.8-5.9,6.1-12,9.9c6.3,4.8,10.4,8.6,12.4,11.4c2.6,3.3,4,7.3,4,11.5
            c0,5-2.1,9.7-5.8,13c-3.9,3.6-9,5.4-15.3,5.4c-6.9,0-12.2-2.2-16.1-6.5c-5.5-5.7-6.1-14.5-1.3-20.8
            C585.6,380.5,589.3,377,594.6,373.2z M597.1,375.2c-2.7,2.2-5,5-6.6,8.1c-1.4,3-2.2,6.2-2.1,9.5c0,4.6,1.3,8.3,3.8,11
            c2.4,2.7,5.9,4.2,9.6,4.1c3.4,0.2,6.7-1,9.3-3.2c2.2-2,3.5-4.9,3.5-7.9c0-2.4-0.7-4.8-2-6.9C609.9,386.3,604.8,381.3,597.1,375.2
            L597.1,375.2z M603,367.5c4.3-3.8,7-6.9,8.1-9.1c1.2-2.3,1.8-4.9,1.7-7.5c0-3.7-1-6.7-3.2-8.8c-2.3-2.2-5.4-3.3-8.6-3.2
            c-3.2-0.1-6.4,1-8.9,3.1c-2.2,1.8-3.4,4.5-3.4,7.4c0,1.9,0.5,3.8,1.4,5.5c1,2,2.4,3.8,4,5.3L603,367.5z M649.2,373.2
            c-5.7-4.7-9.4-8.4-11.1-11.3c-1.6-2.7-2.5-5.7-2.5-8.9c0-4.6,2-9.1,5.4-12.1c3.6-3.4,8.4-5.1,14.5-5.1c5.8,0,10.5,1.6,14.1,4.7
            c3.6,3.2,5.3,6.8,5.4,10.8c-0.1,3-1.1,5.9-2.9,8.3c-1.9,2.8-5.9,6.1-12,9.9c6.3,4.8,10.4,8.6,12.4,11.4c2.6,3.3,4,7.3,4,11.5
            c0,5-2.1,9.7-5.8,13c-3.9,3.6-9,5.4-15.3,5.4c-6.9,0-12.2-2.2-16-6.5c-5.5-5.7-6.1-14.5-1.4-20.8
            C640.2,380.5,643.9,377,649.2,373.2z M651.7,375.2c-2.7,2.2-5,5-6.6,8.1c-1.4,3-2.2,6.2-2.1,9.5c0,4.6,1.3,8.3,3.8,11
            c2.4,2.7,5.9,4.2,9.6,4.1c3.4,0.2,6.7-1,9.2-3.3c2.2-2,3.5-4.9,3.5-7.9c0-2.4-0.7-4.8-2-6.9C664.5,386.3,659.4,381.3,651.7,375.2
            L651.7,375.2z M657.6,367.5c4.3-3.8,7-6.9,8.1-9.1c1.2-2.3,1.8-4.9,1.7-7.5c0-3.7-1-6.7-3.2-8.8c-2.3-2.2-5.4-3.3-8.6-3.2
            c-3.2-0.1-6.4,1-8.8,3.1c-2.2,1.8-3.4,4.5-3.4,7.4c0,1.9,0.5,3.8,1.4,5.5c1,2,2.4,3.8,4,5.3L657.6,367.5z M703.8,373.2
            c-5.7-4.7-9.4-8.4-11.1-11.3c-1.6-2.7-2.5-5.7-2.5-8.9c0-4.6,2-9.1,5.4-12.1c3.6-3.4,8.4-5.1,14.5-5.1c5.8,0,10.5,1.6,14.1,4.7
            c3.5,3.2,5.3,6.8,5.3,10.8c-0.1,3-1.1,5.9-2.9,8.3c-1.9,2.8-5.9,6.1-12,9.9c6.3,4.8,10.4,8.6,12.4,11.4c2.6,3.3,4,7.3,4.1,11.5
            c0,5-2.1,9.7-5.8,13c-3.9,3.6-8.9,5.4-15.3,5.4c-6.9,0-12.2-2.2-16-6.5c-5.5-5.7-6.1-14.5-1.4-20.8
            C694.8,380.5,698.5,377,703.8,373.2z M706.3,375.2c-2.7,2.2-5,5-6.6,8.1c-1.4,3-2.2,6.2-2.1,9.5c0,4.6,1.3,8.3,3.8,11
            c2.4,2.7,5.9,4.2,9.6,4.1c3.4,0.2,6.7-1,9.2-3.3c2.2-2,3.5-4.9,3.5-7.9c0-2.4-0.7-4.8-2-6.9C719.1,386.3,714,381.3,706.3,375.2
            L706.3,375.2z M712.2,367.5c4.3-3.8,7-6.9,8.1-9.1c1.2-2.3,1.8-4.9,1.7-7.5c0-3.7-1.1-6.7-3.2-8.8c-2.3-2.2-5.4-3.3-8.6-3.2
            c-3.2-0.1-6.4,1-8.8,3.1c-2.2,1.8-3.4,4.5-3.4,7.4c0,1.9,0.5,3.9,1.4,5.6c1,2,2.4,3.8,4,5.3L712.2,367.5z"/>
        </g>
      </svg>
      <svg v-if="isMobile"  version="1.1" id="svg1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 229 431" style="enable-background:new 0 0 229 431;" xml:space="preserve">
        <g class="an12">
          <polygon class="st0" points="11.9,415.1 8.7,415.1 8.3,415.1 2.2,415.1 2.2,418 8.3,418 8.3,418 8.3,418 10.1,417.3 11.9,418 
            18,418 18,415.1 	"/>
          <polygon class="st1" points="10.1,418 6.4,419.4 2.2,419.4 2.2,431 3.6,431 4.1,431 7.8,431 7.8,424.8 10.1,423.9 12.3,424.8 
            12.3,431 16.1,431 17.2,431 18,431 18,419.4 13.7,419.4 	"/>
          <path d="M26.8,422.1c-0.5,0.6-1,1.2-1.6,1.7c-0.1,0-0.2-0.1-0.1-0.2c1-1.5,1.8-3,2.3-4.7c0.3-0.8,0.4-1.6,0.5-2.4
            c1.7,0.5,1.9,0.6,1.9,0.8s0,0.2-0.6,0.4c-0.4,1.2-1,2.3-1.7,3.3c0.8,0.1,1,0.2,1,0.3s-0.1,0.2-0.4,0.3v7.5c0,0.4-0.3,0.6-0.8,0.6
            s-0.6-0.1-0.6-0.6s0.1-1.5,0.1-3.8L26.8,422.1z M33.8,422.3h2.4c0.7-0.8,0.8-0.9,0.9-0.9s0.3,0.1,0.9,0.6c0.3,0.2,0.4,0.4,0.4,0.5
            s0,0.1-0.2,0.1h-8.3c-0.4,0-0.8,0-1.2,0.1l-0.2-0.5c0.5,0.1,0.9,0.1,1.4,0.1h2.5v-2.7h-1.8c-0.4,0-0.8,0-1.1,0.1l-0.2-0.5
            c0.4,0.1,0.9,0.1,1.3,0.1h1.8v-1c0-0.6,0-1.1-0.1-1.7c1.7,0.2,1.9,0.3,1.9,0.5s-0.2,0.3-0.4,0.4v1.9h1.9c0.6-0.8,0.8-0.9,0.9-0.9
            s0.3,0.1,0.9,0.6c0.3,0.2,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-3.8L33.8,422.3z M33.8,428.7h2.5c0.7-0.8,0.9-0.9,1-0.9s0.3,0.1,0.9,0.6
            c0.3,0.2,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-8.5c-0.4,0-0.8,0-1.2,0.1l-0.2-0.5c0.5,0.1,0.9,0.1,1.4,0.1h2.5v-3.1h-2
            c-0.4,0-0.7,0-1.1,0.1l-0.2-0.5c0.4,0.1,0.8,0.1,1.3,0.1h2.1v-0.5c0-0.6,0-1.2-0.1-1.7c1.7,0.1,1.9,0.3,1.9,0.4s-0.2,0.3-0.4,0.4
            v1.4h2c0.6-0.8,0.8-0.9,0.9-0.9s0.3,0.1,0.9,0.6c0.3,0.2,0.4,0.3,0.4,0.5s0,0.1-0.2,0.1h-4L33.8,428.7z M48.6,427.5
            c0,0.5,0.2,0.6,1.3,0.6c0.5,0.1,1.1,0,1.5-0.2c0.5-0.3,0.6-0.9,1-2.5c0-0.1,0.2,0,0.2,0v1.8c0,0.4,0,0.4,0.3,0.5
            c0.2,0.1,0.3,0.3,0.3,0.5c0,0.4-0.2,0.7-0.7,0.8c-0.9,0.2-1.8,0.3-2.7,0.2c-2.1,0-2.6-0.3-2.6-1.5c0-0.9,0-1.8,0-2.9v-3.5h-1.9
            c0.1,1.8-0.2,3.6-1,5.2c-0.8,1.5-2.2,2.3-4.4,3c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c1.5-0.7,2.7-2,3.4-3.5c0.5-1.4,0.7-3,0.6-4.5h-2.4
            c-0.4,0-0.9,0-1.3,0.1L40,421c0.5,0.1,1,0.1,1.5,0.1h9c0.8-1,1-1.1,1.1-1.1s0.3,0.1,1.1,0.8c0.4,0.3,0.5,0.4,0.5,0.5s0,0.1-0.2,0.1
            h-4.3V427.5z M48.8,417.7c0.8-0.9,0.9-1,1-1s0.3,0.1,1,0.7c0.4,0.3,0.5,0.4,0.5,0.5s0,0.1-0.2,0.1h-8c-0.4,0-0.8,0-1.3,0.1
            l-0.2-0.5c0.5,0.1,0.9,0.1,1.4,0.1L48.8,417.7z M56.2,429.2c0,0.4-0.3,0.6-0.8,0.6s-0.6-0.1-0.6-0.5c0-0.9,0.1-2.1,0.1-6.4
            c0-2.1,0-4.3-0.1-6.4c0.5,0.2,1.1,0.4,1.5,0.7h2.5c0.5-0.6,0.6-0.7,0.7-0.7s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4
            s-0.1,0.2-0.4,0.4v0.5c0,1.4,0,2.4,0,2.7s-0.3,0.5-0.8,0.5s-0.5-0.1-0.5-0.4v-0.3h-2.8V429.2z M59,417.4h-2.8v1.3H59V417.4z
            M56.2,420.5H59v-1.4h-2.8L56.2,420.5z M60.2,425.4c0,1.8-1.2,3.4-3,4c-0.1,0-0.2,0-0.1-0.1c1.1-1,1.8-2.4,1.8-3.9h-0.8
            c-0.4,0-0.8,0-1.2,0.1l-0.2-0.5c0.4,0.1,0.9,0.1,1.3,0.1h0.8c0-0.4,0-0.9,0-1.4V423h-0.5c-0.3,0-0.7,0-1,0.1l-0.2-0.5
            c0.4,0.1,0.7,0.1,1.1,0.1H63c0.5-0.6,0.7-0.8,0.8-0.8s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1H63v2h0.4
            c0.6-0.8,0.8-0.9,0.9-0.9s0.3,0.1,0.8,0.6c0.2,0.2,0.3,0.4,0.3,0.5s0,0.1-0.2,0.1H63c0,1.3,0,2.8,0,3.4s-0.3,0.5-0.8,0.5
            s-0.6-0.1-0.6-0.5s0.1-1.2,0.1-2.9v-0.6L60.2,425.4z M61.8,425v-2h-1.6c0,0.7,0,1.3-0.1,2H61.8z M63,421c0,0.4-0.2,0.5-0.7,0.5
            s-0.6-0.1-0.6-0.4c0-0.5,0-1,0-3.1c0-0.5,0-1.1-0.1-1.6c0.5,0.2,1,0.4,1.5,0.7h2.7c0.5-0.6,0.6-0.7,0.7-0.7s0.3,0.1,0.8,0.5
            c0.3,0.2,0.3,0.3,0.3,0.4s-0.1,0.2-0.4,0.4v1.7c0,8.1,0,8.5,0,8.9c0,0.4-0.1,0.8-0.4,1c-0.3,0.2-0.7,0.3-1.1,0.3
            c-0.3,0-0.3,0-0.3-0.3c-0.1-0.5-0.4-0.8-1.4-1c-0.1,0-0.1-0.2,0-0.2c0.8,0.1,1.2,0.1,1.7,0.1c0.2,0,0.2,0,0.2-0.2v-7.2h-3L63,421z
            M66,417.5h-3v1.3h3V417.5z M63,420.5h3v-1.4h-3V420.5z M78.1,420.9l0.4-0.4c-0.2-0.1-0.4-0.2-0.6-0.4c-0.9-0.6-1.7-1.3-2.3-2.2
            c-1.1,1.3-2.6,2.4-4.3,3c0.2,0,0.4,0.1,0.7,0.1h0.9c0.4-0.5,0.6-0.6,0.7-0.6s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4
            s0,0.1-0.4,0.4v0.3c0,0.8,0,1.4,0,1.6c0,0.4-0.3,0.5-0.7,0.5S73,424,73,423.7v-0.2h-1.5c-0.1,0.6-0.3,1.3-0.4,1.8h1.8
            c0.5-0.5,0.6-0.6,0.7-0.6s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.4,0.5c0,0.9-0.2,1.9-0.6,2.8c-0.2,0.3-0.4,0.6-0.8,0.8
            c-0.3,0.1-0.6,0.2-0.9,0.2c-0.2,0-0.2,0-0.2-0.3c-0.1-0.6-0.3-0.7-1.3-1.1c-0.1,0-0.1-0.2,0-0.2c0.8,0.1,1.2,0.1,1.5,0.1
            s0.6-0.1,0.7-0.4c0.2-0.7,0.3-1.5,0.4-2.3h-2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.3,0.4-0.7,0.4s-0.6-0.1-0.6-0.3
            c0-0.1,0-0.2,0.1-0.4c0.2-0.6,0.3-1.3,0.5-1.9c0.1-0.4,0.2-0.9,0.2-1.3c0.5,0.2,0.9,0.4,1.3,0.7H73v-1.8h-1c-0.3,0-0.5,0-0.7,0.1
            l-0.1-0.4c-0.8,0.3-1.5,0.5-2.3,0.7c-0.1,0-0.2-0.1-0.1-0.2c2.2-0.8,4.1-2.2,5.6-4.1h-2.2c-0.3,0-0.7,0-1,0.1L71,417
            c0.4,0.1,0.7,0.1,1.1,0.1h2.2c0.6-0.7,0.7-0.7,0.8-0.7s0.3,0.1,0.8,0.5c0.3,0.2,0.3,0.3,0.3,0.4s-0.1,0.2-0.5,0.3
            c0.3,0.3,0.7,0.5,1,0.8c0.3-0.4,0.5-0.7,0.7-1.1c0.2-0.3,0.3-0.6,0.4-0.9c1.2,0.6,1.4,0.8,1.4,0.9s0,0.2-0.7,0.3
            c-0.5,0.4-1,0.8-1.5,1.1c0.4,0.2,0.7,0.4,1.1,0.5l0.2,0.1c0.3-0.3,0.5-0.6,0.8-1c0.2-0.3,0.3-0.5,0.5-0.9c1.4,0.8,1.4,0.8,1.4,1
            s-0.1,0.3-0.6,0.2c-0.5,0.3-1,0.6-1.6,0.8c1.1,0.3,2.2,0.6,3.3,0.7c0.1,0,0.1,0.1,0,0.1c-0.4,0.2-0.7,0.5-0.8,1
            c0,0.1-0.1,0.2-0.2,0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.3-0.1-0.7-0.2-1.1-0.4c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.3,0.4v1
            c0,0.2,0.1,0.3,0.6,0.3s0.6,0,0.8-0.2c0.1-0.3,0.3-0.6,0.3-0.9c0-0.1,0.2-0.1,0.2,0s0,0.4,0,0.6s0,0.2,0.2,0.3
            c0.2,0.1,0.3,0.3,0.3,0.5c0,0.3-0.2,0.5-0.4,0.6c-0.4,0.2-0.9,0.2-1.4,0.2c-1.4,0-1.8-0.3-1.8-1.2c0-0.3,0-0.6,0-0.8v-0.7h-1.3v0.3
            c0,0.6-0.2,1.2-0.5,1.7c-0.5,0.7-1.2,1.1-2,1.2c-0.1,0-0.2-0.1-0.1-0.1c0.5-0.3,0.9-0.8,1.2-1.4c0.1-0.5,0.2-1,0.2-1.5
            c0-0.4,0-0.7-0.1-1.1c0.5,0.2,0.9,0.4,1.4,0.7L78.1,420.9z M71.3,419.9c-0.3,0-0.4-0.1-0.6-0.7c-0.2-0.5-0.5-0.9-0.9-1.2
            c0,0,0-0.1,0-0.1s0.1,0,0.1,0c1.4,0.2,2.1,0.7,2.1,1.3C72,419.5,71.7,419.8,71.3,419.9L71.3,419.9z M78.4,424.9
            c0.6-0.6,0.7-0.7,0.8-0.7s0.3,0.1,0.8,0.6c0.3,0.2,0.3,0.3,0.3,0.4s-0.1,0.3-0.6,0.5c-0.4,0.7-0.8,1.3-1.4,1.8
            c1.1,0.5,2.2,0.9,3.4,1c0.1,0,0.1,0.2,0,0.2c-0.5,0.1-0.8,0.5-0.9,0.9c-0.1,0.2-0.1,0.3-0.2,0.3c-0.2,0-0.4-0.1-0.6-0.1
            c-0.7-0.3-1.4-0.6-2-1.1c-0.2-0.1-0.4-0.3-0.6-0.5c-1.2,0.8-2.6,1.4-4.1,1.6c-0.1,0-0.2-0.1-0.1-0.2c1.3-0.5,2.5-1.2,3.5-2.2
            c-0.5-0.7-0.9-1.4-1.2-2.2h-0.2c-0.3,0-0.5,0-0.8,0.1l-0.2-0.6c0.4,0.1,0.7,0.1,1.1,0.1L78.4,424.9z M76.1,425.2
            c0.4,0.6,0.9,1.2,1.4,1.6c0.4-0.5,0.8-1,1-1.6H76.1z M86.3,421.5c0.5-0.5,0.6-0.6,0.7-0.6s0.3,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4
            s-0.1,0.3-0.4,0.4c-0.2,1.5-0.6,2.9-1.3,4.3c0.2,0.2,0.3,0.3,0.5,0.5c1.1,0.9,2.7,1.2,5.4,1.2c1.5,0,3-0.1,4.5-0.3
            c0.1,0,0,0.1,0,0.1c-0.4,0.2-0.7,0.6-0.6,1.1c0,0.2-0.1,0.3-0.4,0.3s-1.8,0.1-2.7,0.1c-3.4,0-5.1-0.4-6.4-1.6
            c-0.2-0.2-0.5-0.5-0.7-0.7c-0.7,1-1.7,1.8-2.7,2.5c-0.1,0-0.2-0.1-0.1-0.1c0.9-0.9,1.7-2.1,2.3-3.3c-0.3-0.7-0.6-1.4-0.9-2.2
            c0-0.2-0.1-0.3-0.2-0.4c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.3-0.2,0.6-0.2s0.2,0,0.2,0.1s-0.1,0.2-0.1,0.3c0,0.3,0.1,0.5,0.2,0.7
            c0.2,0.4,0.4,0.8,0.6,1.2c0.4-1.2,0.6-2.4,0.7-3.6h-1.1c0,0.1-0.1,0.2-0.2,0.3c-0.2,0.3-0.3,0.4-0.6,0.4c-0.4,0-0.8-0.2-0.8-0.5
            c0-0.1,0.1-0.3,0.1-0.4c0.7-1.2,1.4-2.5,1.8-3.8h-1.3c-0.3,0-0.6,0-0.9,0.1l-0.1-0.5c0.3,0.1,0.7,0.1,1,0.1h1.3
            c0.5-0.6,0.6-0.7,0.7-0.7s0.3,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s-0.1,0.2-0.5,0.4c-0.4,1.1-1,2.2-1.5,3.3L86.3,421.5z
            M92.1,422.1c0,0.5,0,1,0,1.5H94c0.6-0.7,0.8-0.8,0.9-0.8s0.3,0.1,0.9,0.6c0.2,0.2,0.4,0.3,0.4,0.4s0,0.1-0.2,0.1h-3.7
            c0,0.5,0,1,0,1.5h2.2c0.7-0.8,0.8-0.9,0.9-0.9s0.3,0.1,0.9,0.6c0.3,0.2,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-4.2c0,0.6,0,1.1,0,1.4
            s-0.2,0.6-0.8,0.6s-0.6-0.1-0.6-0.6c0-0.3,0-0.7,0-1.4h-2.3c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.3,0.1,0.7,0.1,1,0.1h2.4
            c0-0.5,0-1,0-1.5h-1.7c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.3,0.1,0.7,0.1,1,0.1h1.8c0-0.5,0-1,0-1.5h-1.6c-0.3,0-0.5,0-0.8,0.1
            l-0.2-0.5c0.3,0.1,0.6,0.1,0.9,0.1h1.7v-1.6h-2.2c-0.3,0-0.7,0-1,0.1l-0.2-0.5c0.4,0.1,0.7,0.1,1.1,0.1h2.2v-1.5h-1.5
            c-0.3,0-0.6,0-0.9,0.1l-0.2-0.6c0.3,0.1,0.7,0.1,1,0.1h1.5c0-0.6,0-1.1-0.1-1.6c1.4,0.2,1.7,0.2,1.7,0.5s-0.1,0.2-0.4,0.4v0.8h1.7
            c0.5-0.6,0.6-0.7,0.7-0.7s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.5,0.5v1.1h0.1c0.4-0.6,0.6-0.7,0.7-0.7s0.2,0.1,0.6,0.5
            c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0,0.1-0.2,0.1h-1.4c0,0.9,0,1.5,0,1.8s-0.2,0.6-0.7,0.6s-0.6-0.1-0.6-0.5L92.1,422.1z M92.1,419.9
            H94v-1.5h-1.9L92.1,419.9z M94,420.2h-1.9c0,0.5,0,1,0,1.6H94L94,420.2z M101.6,419.4c0.6-0.8,0.8-0.9,0.9-0.9s0.3,0.1,0.9,0.6
            c0.3,0.2,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-4.6c-0.3,0-0.7,0-1,0.1l-0.2-0.5c0.4,0.1,0.8,0.1,1.2,0.1L101.6,419.4z M99.8,428.7v0.5
            c0,0.4-0.3,0.6-0.7,0.6s-0.6-0.1-0.6-0.5s0-0.9,0-2.8c0-0.7,0-1.4-0.1-2.1c0.5,0.2,1,0.5,1.5,0.7h1.4c0.5-0.6,0.6-0.7,0.7-0.7
            s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4s-0.1,0.3-0.4,0.5v0.5c0,1.2,0.1,2.1,0.1,2.4s-0.3,0.6-0.8,0.6s-0.6-0.1-0.6-0.5v-0.1
            L99.8,428.7z M101,417.5c0.6-0.8,0.8-0.9,0.9-0.9s0.3,0.1,0.9,0.6c0.3,0.2,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-3.4
            c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.3,0.1,0.7,0.1,1,0.1H101z M101.1,421.4c0.6-0.7,0.7-0.8,0.8-0.8s0.3,0.1,0.8,0.6
            c0.2,0.2,0.4,0.3,0.4,0.4s0,0.1-0.2,0.1h-3.4c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.3,0.1,0.7,0.1,1,0.1L101.1,421.4z M101.1,423.2
            c0.6-0.7,0.7-0.8,0.8-0.8s0.3,0.1,0.9,0.6c0.2,0.2,0.4,0.3,0.4,0.4s0,0.1-0.2,0.1h-3.4c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5
            c0.3,0.1,0.7,0.1,1,0.1L101.1,423.2z M101.6,425.5h-1.8v2.9h1.7L101.6,425.5z M108.4,422.9c0.6-0.6,0.7-0.7,0.8-0.7
            s0.3,0.1,0.9,0.6c0.3,0.2,0.4,0.3,0.4,0.4s-0.2,0.3-0.5,0.5c-0.5,1.1-1.1,2.1-1.9,3c1.1,0.7,2.2,1.1,3.5,1.3c0,0,0.1,0,0.1,0.1
            s0,0.1-0.1,0.1l0,0c-0.5,0.1-0.7,0.4-0.8,1.1c0,0.2-0.1,0.3-0.2,0.3c-0.2,0-0.4-0.1-0.6-0.2c-1-0.4-2-1-2.7-1.9
            c-1.3,1-2.7,1.8-4.3,2.2c-0.1,0-0.1-0.2-0.1-0.2c1.4-0.7,2.7-1.6,3.7-2.8c-0.7-1-1.1-2.2-1.4-3.4h-0.2c-0.3,0-0.6,0-0.8,0.1
            l-0.2-0.6c0.3,0.1,0.7,0.1,1.1,0.1L108.4,422.9z M111,420c0,0.2,0.1,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.2,0.4c0,0.3-0.2,0.5-0.6,0.7
            c-0.5,0.1-0.9,0.2-1.4,0.2c-1.3,0-1.7-0.3-1.7-1.2c0-0.6,0-0.7,0-1.3v-1.5H106v0.3c0,0.9-0.1,1.8-0.5,2.6c-0.5,0.8-1.3,1.4-2.2,1.7
            c-0.1,0-0.2-0.1-0.1-0.1c0.6-0.5,1-1.2,1.2-2c0.1-0.6,0.2-1.2,0.2-1.8c0-0.6,0-1.2-0.1-1.7c0.7,0.3,0.9,0.4,1.5,0.7h1.6
            c0.5-0.5,0.6-0.6,0.7-0.6s0.3,0.1,0.7,0.5c0.1,0.1,0.2,0.2,0.3,0.4c0,0.1-0.1,0.3-0.4,0.4v2.2c0,0.2,0.1,0.3,0.6,0.3s0.5,0,0.7-0.2
            c0.3-0.4,0.4-0.8,0.5-1.3c0-0.1,0.2-0.1,0.2,0L111,420z M105.4,423.2c0.4,1,1,1.9,1.7,2.7c0.6-0.8,1.1-1.7,1.4-2.7H105.4z"/>
          <path d="M147.6,417.8L147.6,417.8c-0.3,0-0.5,0-0.7,0.1l-0.2-0.5c0.3,0.1,0.6,0.1,0.9,0.1h2.6c0.5-0.6,0.7-0.8,0.8-0.8
            s0.3,0.1,0.8,0.5c0.3,0.2,0.4,0.3,0.4,0.4s0,0.1-0.2,0.1h-0.8v4.2c0,2.8,0,6.2,0,7.4c0,0.4-0.3,0.6-0.7,0.6c-0.2,0-0.6-0.1-0.6-0.6
            c0-0.3,0.1-1.3,0.1-3.2c-0.6,0.6-1.3,1.1-2.1,1.4c-0.1,0.4-0.1,0.5-0.3,0.5s-0.3-0.3-0.8-1.6l0.8-0.1L147.6,417.8z M148.8,420.4
            h1.2v-2.5h-1.2V420.4z M148.8,423h1.2v-2.3h-1.2V423z M148.8,426.2c0.4-0.1,0.8-0.3,1.2-0.4v-2.5h-1.2V426.2z M152.3,421.8
            c0.6-0.7,1.1-1.5,1.5-2.3c0.2-0.4,0.3-0.7,0.5-1.1c1.1,0.5,1.4,0.6,1.4,0.8s-0.1,0.2-0.5,0.2c-0.6,0.9-1.3,1.7-2.2,2.4
            c0.6,0,1.2-0.1,1.8-0.2c-0.1-0.3-0.2-0.6-0.4-0.9c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.9,0.5,1.4,1,1.4,1.5c0,0.4-0.2,0.7-0.6,0.7
            s-0.4-0.2-0.5-0.6c0-0.1,0-0.2,0-0.3c-0.8,0.3-1.6,0.6-2.5,0.8c0,0.3-0.1,0.4-0.3,0.4s-0.2-0.2-0.5-1.4L152.3,421.8L152.3,421.8z
            M153,417.6c0.1-0.3,0.2-0.7,0.3-1c1.1,0.3,1.4,0.5,1.4,0.7s-0.1,0.2-0.5,0.3c-0.4,0.5-0.9,0.9-1.4,1.4c0.3,0.2,0.5,0.5,0.5,0.9
            c0,0.3-0.2,0.6-0.5,0.6c0,0,0,0-0.1,0c-0.3,0-0.4-0.1-0.5-0.5c-0.1-0.5-0.4-1-0.8-1.4c0-0.1,0-0.1,0.1-0.1c0.3,0.1,0.6,0.2,0.9,0.3
            C152.7,418.4,152.9,418,153,417.6L153,417.6z M154.1,426.2c0.1-0.7,0.1-1.3,0.1-2c0-0.3,0-0.6,0-0.9c1.5,0.1,1.7,0.2,1.7,0.4
            s-0.1,0.3-0.4,0.4c0,0.5,0,0.9,0,1.3c0,0.8-0.1,1.6-0.4,2.4c-0.6,1.2-1.7,2.1-3.1,2.4c-0.1,0-0.1-0.1-0.1-0.1
            c0.7-0.4,1.3-1,1.8-1.7c0.3-0.5,0.4-1,0.5-1.5h-0.9v0.3c0,0.4-0.2,0.5-0.7,0.5s-0.5-0.1-0.5-0.5c0-0.2,0-0.7,0-1.7
            c0-0.5,0-1-0.1-1.6c1.2,0.1,1.5,0.2,1.5,0.4s-0.1,0.2-0.3,0.3v1.9L154.1,426.2z M157,417.8c0.1-0.4,0.3-0.8,0.4-1.1
            c1.1,0.4,1.4,0.5,1.4,0.7s-0.1,0.2-0.5,0.3c-0.4,0.5-0.9,0.9-1.4,1.3c0.4,0.2,0.6,0.5,0.6,0.9c0,0.3-0.2,0.6-0.5,0.6c0,0,0,0,0,0
            c-0.3,0-0.4-0.1-0.5-0.5c-0.1-0.5-0.4-1-0.8-1.4c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0.3,0.1,0.6,0.2,0.9,0.3
            C156.7,418.4,156.9,418.1,157,417.8L157,417.8z M158,419.4c0.2-0.4,0.3-0.7,0.5-1.1c0.9,0.4,1.4,0.6,1.4,0.8s-0.1,0.2-0.5,0.2
            c-0.6,0.9-1.4,1.7-2.3,2.4c0.6,0,1.3-0.1,1.9-0.1c-0.1-0.3-0.2-0.7-0.4-1c0-0.1,0-0.1,0.1-0.1c1,0.5,1.6,1.1,1.6,1.7
            c0,0.3-0.2,0.6-0.6,0.7c0,0,0,0,0,0c-0.3,0-0.5-0.2-0.5-0.6c0-0.1,0-0.2-0.1-0.3c-0.8,0.3-1.5,0.5-2.3,0.7c0,0.3-0.1,0.4-0.2,0.4
            s-0.3-0.2-0.6-1.4h0.6C157.1,421,157.6,420.3,158,419.4L158,419.4z M157.5,426.6c0,1.5,0,2.5,0,2.9s-0.2,0.5-0.8,0.5
            s-0.5-0.1-0.5-0.5s0-1.5,0-3.8c0-1.4,0-1.9-0.1-2.5c1.5,0.2,1.6,0.2,1.6,0.4s-0.1,0.3-0.4,0.4v2.2h1.1v-1.7c0-0.2,0-0.4-0.1-1
            c1.2,0.1,1.5,0.2,1.5,0.4s-0.1,0.2-0.4,0.3v0.4c0,1.2,0,2,0,2.3s-0.2,0.6-0.7,0.6s-0.5-0.1-0.5-0.4v-0.6L157.5,426.6z M164.4,418.4
            c-0.1,1.3-0.5,2.5-1,3.6l0.1,0.1h1.1c0.5-0.5,0.6-0.6,0.7-0.6s0.3,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s-0.1,0.2-0.4,0.4v0.8
            c0,2.2,0.1,3.7,0.1,4.2s-0.2,0.6-0.8,0.6c-0.4,0-0.5-0.1-0.5-0.5v-0.3h-1.4v1.1c0,0.4-0.2,0.6-0.7,0.6s-0.6-0.1-0.6-0.5
            c0-0.9,0.1-1.8,0.1-4.1c0-0.2,0-0.5,0-0.9c-0.4,0.4-0.7,0.8-1.1,1.2c-0.1,0-0.2-0.1-0.1-0.1c1.2-1.9,2-4.1,2.1-6.4h-0.6
            c-0.3,0-0.7,0-1,0.1l-0.2-0.5c0.4,0.1,0.7,0.1,1.1,0.1h2.2c0.6-0.8,0.7-0.9,0.9-0.9s0.3,0.1,0.8,0.6c0.2,0.2,0.3,0.3,0.3,0.5
            s0,0.1-0.2,0.1L164.4,418.4z M164.7,422.4h-1.4v4.8h1.4V422.4z M169.6,428.5c-1.3,0.9-2.8,1.4-4.3,1.5c-0.1,0-0.1-0.1-0.1-0.2
            c1-0.4,1.9-1,2.7-1.7c0.3-0.3,0.6-0.6,0.8-0.9c1.2,0.6,1.6,0.9,1.6,1.1S170.1,428.6,169.6,428.5L169.6,428.5z M171.3,417.9
            c-0.4,0.7-0.9,1.4-1.5,2h2.5c0.5-0.6,0.6-0.7,0.8-0.7s0.3,0.1,0.8,0.5c0.3,0.2,0.3,0.3,0.3,0.4s-0.1,0.3-0.4,0.5v1
            c0,2.7,0.1,4.5,0.1,5.1s-0.3,0.7-0.8,0.7c-0.4,0-0.6-0.2-0.6-0.6h-3.9c0,0.4-0.3,0.6-0.7,0.6s-0.7-0.1-0.7-0.5
            c0-0.6,0.1-1.4,0.1-5.1c0-1,0-1.6-0.1-2.6c0.5,0.2,1,0.4,1.5,0.7h0.6c0.2-0.7,0.3-1.3,0.4-2h-1.8c-0.3,0-0.7,0-1,0.1l-0.2-0.5
            c0.4,0.1,0.7,0.1,1.1,0.1h4.4c0.7-0.8,0.9-0.9,1-0.9s0.3,0.1,1,0.6c0.3,0.3,0.4,0.4,0.4,0.5s-0.1,0.1-0.2,0.1H171.3z M172.4,420.3
            h-3.9v1.8h3.9V420.3z M172.4,422.4h-3.9v1.8h3.9V422.4z M172.4,424.5h-3.9v2h3.9V424.5z M173.7,429.9c-0.4,0-0.4,0-0.9-0.8
            c-0.5-0.7-1.1-1.2-1.8-1.5c-0.1,0,0-0.2,0.1-0.2c2.4,0.3,3.4,0.8,3.4,1.7C174.4,429.6,174.1,429.9,173.7,429.9
            C173.7,429.9,173.7,429.9,173.7,429.9L173.7,429.9z M177.8,423.7c-0.6,0.4-1.2,0.8-1.8,1.2c-0.1,0-0.2-0.1-0.1-0.2
            c1-1,1.9-2,2.6-3.2c0.3-0.5,0.6-1.1,0.8-1.6c1.5,0.7,1.7,0.8,1.7,1s-0.2,0.3-0.6,0.3c-0.4,0.5-0.8,1-1.3,1.5
            c0.4,0.1,0.5,0.1,0.5,0.3s-0.1,0.2-0.4,0.3v6.1c0,0.4-0.3,0.6-0.8,0.6c-0.3,0-0.6-0.1-0.6-0.5s0.1-1.5,0.1-3.8L177.8,423.7z
            M180.1,417.9c-1,1.4-2.4,2.5-4,3.2c-0.1,0-0.2-0.1-0.1-0.2c0.9-0.8,1.7-1.7,2.3-2.7c0.3-0.5,0.6-1,0.8-1.6
            c1.3,0.6,1.7,0.9,1.7,1.1S180.6,417.9,180.1,417.9z M186.3,425.2c0,1.1,0,2.1,0,3.2c0,0.4-0.1,0.7-0.4,1c-0.4,0.3-0.9,0.5-1.4,0.5
            c-0.3,0-0.3,0-0.4-0.3c-0.1-0.6-0.5-0.8-1.9-1.1c-0.1,0-0.1-0.2,0-0.2c0.7,0,1.6,0.1,2.1,0.1c0.3,0,0.3-0.1,0.3-0.3v-6.2h-3.2
            c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.4,0.1,0.7,0.1,1.1,0.1h5.1c0.7-0.8,0.9-0.9,1-0.9s0.3,0.1,1,0.6c0.3,0.3,0.4,0.4,0.4,0.5
            s0,0.1-0.2,0.1h-2.7L186.3,425.2z M186.1,418c0.7-0.8,0.9-0.9,1-0.9s0.3,0.1,1,0.6c0.3,0.3,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-6
            c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.4,0.1,0.7,0.1,1.1,0.1H186.1z M193.8,417.8c1.4,0.5,2.1,1.1,2.1,1.8c0,0.4-0.3,0.7-0.6,0.7
            c0,0,0,0,0,0c-0.3,0-0.4-0.1-0.6-0.7c-0.2-0.6-0.5-1.2-1-1.6c-0.6,1-1.3,1.9-2.2,2.7c0.2,0.1,0.4,0.1,0.6,0.1h1.6
            c0.4-0.5,0.6-0.6,0.7-0.6s0.3,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1h-1.4v2h0.5c0.4-0.6,0.6-0.7,0.7-0.7
            s0.3,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1h-2.1v4.4c0.7-0.1,1.5-0.2,2.1-0.3c0.1,0,0.1,0.2,0,0.2
            c-1.5,0.7-3,1.2-4.5,1.7c0,0.4-0.1,0.5-0.3,0.5s-0.3-0.2-0.7-1.7c0.6,0,1,0,1.4-0.1l0.7-0.1v-4.5h-1.2c-0.3,0-0.5,0-0.8,0.1
            l-0.2-0.5c0.3,0.1,0.6,0.1,0.9,0.1h1.2v-2H192c-0.2,0-0.4,0-0.6,0.1l-0.1-0.4c-0.3,0.3-0.7,0.5-1.1,0.8c-0.1,0-0.2-0.1-0.1-0.1
            c0.8-0.9,1.5-2,2-3.1c0.2-0.5,0.4-1.1,0.5-1.7c1.3,0.4,1.7,0.5,1.7,0.7s-0.1,0.3-0.5,0.3L193.8,417.8z M191.6,427.3
            c-0.3,0-0.5-0.2-0.5-0.7c0-0.7-0.1-1.4-0.4-2.1c0-0.1,0.1-0.1,0.1-0.1c1,0.9,1.3,1.5,1.3,2.1C192.2,427,192,427.3,191.6,427.3z
            M195.6,425.1c-0.3,0.8-0.8,1.6-1.3,2.3c0,0.1-0.2,0-0.2-0.1c0.2-0.6,0.3-1.3,0.4-2c0.1-0.4,0.1-0.9,0.1-1.3
            c1.1,0.4,1.4,0.5,1.4,0.7S195.9,425.1,195.6,425.1z M197.8,426.1c0,1.6,0,2.5,0,3.4c0,0.4-0.2,0.6-0.7,0.6c-0.4,0-0.6-0.2-0.6-0.5
            c0-1,0.1-2,0.1-6c0-0.9,0-1.8,0-3c-0.3,0.3-0.7,0.6-1.1,0.9c-0.1,0-0.2-0.1-0.1-0.1c0.5-0.7,1-1.6,1.3-2.4c0.2-0.4,0.3-0.9,0.4-1.4
            c1.3,0.5,1.6,0.7,1.6,0.8s-0.1,0.2-0.5,0.3c-0.4,0.6-0.8,1.1-1.3,1.6c0.4,0.2,0.8,0.4,1.1,0.6h1v-3c0-0.5,0-0.9-0.1-1.4
            c1.6,0.1,1.8,0.3,1.8,0.5s-0.1,0.3-0.5,0.4v3.6h1c0.4-0.5,0.5-0.5,0.6-0.5s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4
            s-0.1,0.2-0.4,0.4v1.1c0,5,0,5.5,0,5.7c0,0.4-0.1,0.8-0.3,1.1c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.4-0.1-0.4-0.5s-0.5-0.6-1.4-0.9
            c-0.1,0-0.1-0.2,0-0.2c0.7,0.1,1.3,0.1,1.6,0.1s0.3,0,0.3-0.2V426L197.8,426.1z M201.4,421.4h-3.5v2h3.5V421.4z M197.8,425.7h3.5
            v-2h-3.5V425.7z M203.1,420.9c-0.2,0-0.4-0.2-0.6-0.5c-0.3-0.9-0.9-1.8-1.6-2.4c0,0,0-0.2,0.1-0.1c1.8,0.5,2.7,1.3,2.7,2.3
            C203.7,420.6,203.5,420.9,203.1,420.9L203.1,420.9z"/>
          <path class="st2" d="M135.3,425.3v5.3l-0.3,0.5h5l-0.2-0.5v-14.3l0.2-0.5h-15.9l0.2,0.5v14.3l-0.2,0.5h5l-0.3-0.5v-5.3
            c0-1.8,1.4-3.2,3.2-3.2C133.9,422,135.4,423.5,135.3,425.3C135.4,425.3,135.4,425.3,135.3,425.3L135.3,425.3z"/>
        </g>
        <path class="an11" d="M25,392.3l-2.5,6.8H1.7v-1c6.1-5.6,10.4-10.1,12.9-13.7s3.7-6.8,3.7-9.7c0.1-2-0.7-4-2-5.5c-1.3-1.4-3-2.2-4.9-2.2
          c-1.7,0-3.3,0.5-4.7,1.5c-1.4,1.1-2.5,2.7-3,4.5h-1c0.2-2.8,1.4-5.4,3.3-7.4c1.8-1.7,4.2-2.6,6.7-2.6c2.7-0.1,5.2,0.9,7.1,2.8
          c1.8,1.7,2.9,4,2.9,6.5c0,1.9-0.4,3.7-1.3,5.4c-1.6,3.3-3.7,6.3-6.2,8.9c-4.3,4.7-7,7.6-8.1,8.5h9.2c1.3,0,2.6,0,3.9-0.2
          c0.7-0.1,1.4-0.4,2-0.8c0.7-0.5,1.2-1.1,1.6-1.7H25z M30,399.8v-1c2.2,0,4.3-0.6,6.3-1.6c2.3-1.3,4.2-3.1,5.6-5.3
          c1.8-2.5,3.1-5.3,3.8-8.3c-2.2,1.6-4.7,2.5-7.4,2.6c-2.4,0-4.8-1-6.4-2.9c-1.8-2.1-2.8-4.8-2.6-7.6c0-3,0.9-5.9,2.6-8.2
          c1.8-2.8,5-4.4,8.3-4.4c2.9,0,5.6,1.3,7.4,3.6c2.6,3.1,3.9,7,3.8,11c0,3.8-1,7.6-2.9,11c-1.9,3.4-4.7,6.3-8,8.4
          c-2.7,1.8-5.9,2.8-9.1,2.8H30z M46.1,381.7c0.3-1.8,0.4-3.6,0.5-5.4c0-1.8-0.3-3.7-0.9-5.4c-0.4-1.7-1.3-3.2-2.4-4.5
          c-0.9-1-2.2-1.5-3.6-1.5c-1.6,0-3.1,0.8-4,2.1c-1.2,1.4-1.8,3.4-1.8,6.1c0,3.7,0.8,6.5,2.3,8.6c0.9,1.4,2.5,2.2,4.2,2.2
          c1,0,1.9-0.2,2.8-0.6C44.2,382.9,45.2,382.4,46.1,381.7L46.1,381.7z M77.6,363.1v1c-1.9,0.1-3.8,0.6-5.6,1.3c-1.6,0.8-3,1.9-4.3,3.1
          c-1.4,1.4-2.6,3-3.5,4.7c-1,1.9-1.8,4-2.3,6.1c2.2-1.6,4.8-2.5,7.5-2.6c2.4,0,4.7,1.1,6.3,2.9c1.8,2.1,2.8,4.7,2.7,7.5
          c0,2.9-0.9,5.7-2.7,8c-1.9,2.8-5.1,4.5-8.5,4.4c-2.3,0-4.5-0.8-6.1-2.4c-3.3-3.1-5.1-7.5-4.9-12c0-3.1,0.6-6.2,1.9-9
          c1.3-2.9,3.1-5.4,5.4-7.6c1.9-1.9,4.2-3.4,6.7-4.4c1.9-0.7,3.9-1.1,6-1.1L77.6,363.1z M61.5,381.3c-0.3,1.9-0.4,3.8-0.5,5.7
          c0,1.8,0.3,3.7,0.9,5.4c0.5,1.8,1.4,3.4,2.7,4.7c0.9,0.8,2,1.2,3.2,1.2c1.6-0.1,3.1-0.8,4-2.1c1.3-1.8,1.9-3.9,1.8-6.1
          c0.1-2.7-0.5-5.3-1.8-7.7c-0.9-2-2.8-3.2-5-3.3c-0.7,0-1.4,0.2-2.1,0.4C63.6,380,62.5,380.6,61.5,381.3z M104.2,363.1v1
          c-1.9,0.1-3.8,0.6-5.6,1.4c-1.6,0.8-3,1.9-4.3,3.1c-1.4,1.4-2.6,3-3.5,4.7c-1,1.9-1.8,4-2.3,6.1c2.2-1.6,4.8-2.5,7.5-2.6
          c2.4,0,4.7,1.1,6.3,2.9c1.8,2.1,2.8,4.7,2.7,7.5c0,2.9-0.9,5.7-2.7,8c-1.9,2.8-5.1,4.5-8.5,4.4c-2.3,0.1-4.5-0.8-6.1-2.4
          c-3.3-3.1-5.1-7.5-4.9-12c0-3.1,0.6-6.2,1.9-9c1.3-2.9,3.1-5.4,5.4-7.5c1.9-1.9,4.2-3.4,6.7-4.4c1.9-0.7,3.9-1.1,6-1.1H104.2z
          M88.1,381.3c-0.3,1.9-0.4,3.8-0.5,5.7c0,1.8,0.3,3.7,0.9,5.4c0.5,1.8,1.4,3.4,2.7,4.7c0.9,0.8,2,1.2,3.2,1.2c1.6-0.1,3.1-0.8,4-2.1
          c1.3-1.8,1.9-3.9,1.8-6.1c0.1-2.7-0.5-5.3-1.8-7.7c-0.9-2-2.8-3.2-5-3.3c-0.7,0-1.4,0.2-2.1,0.4C90.2,380,89.1,380.6,88.1,381.3z
          M109.1,379.3h13.4v3.9h-13.4V379.3z M134.9,381.3c-2.1-1.5-3.9-3.4-5.4-5.5c-0.8-1.3-1.2-2.8-1.2-4.3c0-2.3,1-4.4,2.6-5.9
          c1.9-1.7,4.4-2.6,7-2.5c2.5-0.1,4.9,0.7,6.9,2.3c1.6,1.3,2.6,3.2,2.6,5.3c0,1.4-0.5,2.8-1.4,4c-0.9,1.3-2.9,2.9-5.8,4.8
          c2.2,1.6,4.3,3.5,6.1,5.5c1.3,1.6,2,3.6,2,5.6c0,2.4-1,4.7-2.8,6.4c-2,1.8-4.7,2.8-7.4,2.6c-2.9,0.2-5.8-1-7.8-3.1
          c-2.7-2.7-3-7.1-0.7-10.1C131,384.4,132.8,382.7,134.9,381.3z M136.1,382.3c-1.3,1.1-2.4,2.5-3.2,4c-0.7,1.4-1,3-1,4.6
          c-0.1,2,0.6,3.9,1.8,5.4c1.2,1.3,2.9,2.1,4.7,2c1.6,0.1,3.3-0.5,4.5-1.6c1.1-1,1.7-2.4,1.7-3.9c0-1.2-0.3-2.4-1-3.4
          C142.3,387.7,139.8,385.2,136.1,382.3L136.1,382.3z M138.9,378.5c1.5-1.3,2.9-2.7,3.9-4.4c0.6-1.1,0.9-2.4,0.8-3.7
          c0.1-1.6-0.5-3.1-1.5-4.3c-1.1-1.1-2.6-1.6-4.2-1.5c-1.6-0.1-3.1,0.5-4.3,1.5c-1.1,0.9-1.7,2.2-1.7,3.6c0,0.9,0.2,1.9,0.7,2.7
          c0.5,1,1.2,1.8,2,2.6L138.9,378.5z M161.5,381.3c-2.1-1.5-3.9-3.4-5.4-5.5c-0.8-1.3-1.2-2.8-1.2-4.3c0-2.3,0.9-4.4,2.6-5.9
          c4-3.2,9.8-3.3,13.9-0.2c1.6,1.3,2.6,3.2,2.6,5.3c-0.1,1.4-0.5,2.8-1.4,4c-0.9,1.3-2.9,2.9-5.8,4.8c2.2,1.6,4.3,3.5,6.1,5.5
          c1.3,1.6,2,3.6,2,5.6c0,2.4-1,4.7-2.8,6.4c-2,1.8-4.7,2.8-7.4,2.6c-2.9,0.2-5.8-1-7.8-3.1c-2.7-2.7-3-7.1-0.7-10.1
          C157.6,384.4,159.4,382.7,161.5,381.3z M162.7,382.3c-1.3,1.1-2.4,2.5-3.2,4c-0.7,1.5-1,3-1,4.6c-0.1,2,0.6,3.9,1.8,5.4
          c1.2,1.3,2.9,2.1,4.7,2c1.6,0.1,3.3-0.5,4.5-1.6c1.1-1,1.7-2.4,1.7-3.9c0-1.2-0.3-2.4-1-3.4C168.9,387.7,166.4,385.2,162.7,382.3
          L162.7,382.3z M165.5,378.5c1.6-1.2,2.9-2.7,4-4.4c0.6-1.1,0.9-2.4,0.8-3.7c0.1-1.6-0.5-3.1-1.5-4.3c-1.1-1.1-2.6-1.6-4.2-1.5
          c-1.6-0.1-3.1,0.5-4.3,1.5c-1,0.9-1.7,2.2-1.7,3.6c0,0.9,0.2,1.9,0.7,2.7c0.5,1,1.2,1.8,2,2.6L165.5,378.5z M188.1,381.3
          c-2.1-1.5-3.9-3.4-5.4-5.5c-0.8-1.3-1.2-2.8-1.2-4.3c0-2.3,1-4.4,2.6-5.9c4-3.2,9.8-3.3,13.9-0.2c1.6,1.3,2.6,3.2,2.6,5.3
          c-0.1,1.4-0.5,2.8-1.4,4c-0.9,1.3-2.9,2.9-5.9,4.8c2.2,1.6,4.3,3.5,6.1,5.5c1.3,1.6,2,3.6,2,5.6c0,2.4-1,4.7-2.8,6.4
          c-2,1.8-4.7,2.8-7.4,2.6c-2.9,0.2-5.8-1-7.8-3.1c-2.7-2.7-3-7.1-0.7-10.1C184.2,384.4,186,382.7,188.1,381.3z M189.3,382.3
          c-1.3,1.1-2.4,2.5-3.2,4c-0.7,1.5-1,3-1,4.6c-0.1,2,0.6,3.9,1.8,5.4c1.2,1.3,2.9,2.1,4.7,2c1.6,0.1,3.3-0.5,4.5-1.6
          c1.1-1,1.7-2.4,1.7-3.9c0-1.2-0.3-2.4-1-3.4C195.5,387.7,193,385.2,189.3,382.3L189.3,382.3z M192.1,378.5c1.6-1.2,2.9-2.7,4-4.4
          c0.6-1.1,0.9-2.4,0.8-3.7c0.1-1.6-0.5-3.1-1.5-4.3c-1.1-1.1-2.6-1.6-4.2-1.5c-1.6-0.1-3.1,0.5-4.3,1.5c-1.1,0.9-1.7,2.2-1.7,3.6
          c0,0.9,0.2,1.9,0.7,2.7c0.5,1,1.2,1.8,2,2.6L192.1,378.5z M214.6,381.3c-2.1-1.5-3.9-3.4-5.4-5.5c-0.8-1.3-1.2-2.8-1.2-4.3
          c0-2.3,1-4.4,2.6-5.9c4-3.2,9.8-3.3,13.9-0.2c1.6,1.3,2.6,3.2,2.6,5.3c-0.1,1.4-0.5,2.8-1.4,4c-0.9,1.4-2.9,3-5.8,4.8
          c2.2,1.6,4.3,3.5,6.1,5.5c1.3,1.6,2,3.6,2,5.6c0,2.4-1,4.7-2.8,6.4c-2,1.8-4.7,2.8-7.4,2.6c-2.9,0.2-5.8-1-7.8-3.1
          c-2.7-2.7-3-7.1-0.7-10.1C210.8,384.4,212.6,382.7,214.6,381.3z M215.9,382.3c-1.3,1.1-2.4,2.5-3.2,4c-0.7,1.5-1,3-1,4.6
          c-0.1,2,0.6,3.9,1.8,5.4c1.2,1.3,2.9,2.1,4.7,2c1.6,0.1,3.3-0.5,4.5-1.6c1.1-1,1.7-2.4,1.7-3.9c0-1.2-0.3-2.4-1-3.4
          C222.1,387.7,219.6,385.2,215.9,382.3L215.9,382.3z M218.7,378.5c1.5-1.3,2.9-2.7,3.9-4.4c0.6-1.1,0.9-2.4,0.8-3.7
          c0.1-1.6-0.5-3.1-1.5-4.3c-1.1-1.1-2.6-1.6-4.2-1.5c-1.6-0.1-3.1,0.5-4.3,1.5c-1.1,0.9-1.7,2.2-1.7,3.6c0,0.9,0.2,1.9,0.7,2.7
          c0.5,1,1.2,1.8,2,2.6L218.7,378.5z"/>
        <path class="an10" d="M18.5,344.6l-1.8,4.9h-15v-0.7c3.4-3,6.6-6.3,9.4-9.9c1.8-2.6,2.7-4.9,2.7-7c0.1-1.5-0.5-2.9-1.5-4c-0.9-1-2.2-1.6-3.6-1.6
          c-1.2,0-2.4,0.4-3.4,1.1c-1.1,0.8-1.9,1.9-2.3,3.2H2.3c0.2-2,1-3.9,2.4-5.3c1.3-1.2,3.1-1.9,4.9-1.9c1.9-0.1,3.8,0.7,5.2,2
          c1.3,1.2,2.1,2.9,2.1,4.7c0,1.3-0.3,2.7-0.9,3.9c-1.2,2.4-2.7,4.5-4.5,6.5c-3.1,3.4-5.1,5.5-5.8,6.2h6.7c1,0,1.9,0,2.9-0.1
          c0.5-0.1,1-0.3,1.5-0.6c0.5-0.3,0.9-0.8,1.1-1.3L18.5,344.6z M22,328.9c0.6-1.5,1.5-2.9,2.8-4c1.2-1,2.6-1.5,4.1-1.4
          c1.8-0.1,3.5,0.6,4.7,2c0.8,0.9,1.2,2,1.2,3.2c0,1.9-1.2,3.8-3.5,5.8c1.4,0.5,2.7,1.4,3.6,2.6c0.8,1.2,1.2,2.6,1.2,4
          c0,2.1-0.7,4.1-2.1,5.7c-1.8,2.1-4.5,3.2-8,3.2c-1.2,0.1-2.4-0.1-3.5-0.6c-0.5-0.3-0.9-0.8-0.9-1.4c0-0.4,0.2-0.7,0.4-1
          c0.3-0.3,0.7-0.4,1.1-0.4c0.3,0,0.7,0,1,0.1c0.5,0.2,1,0.4,1.5,0.7c0.5,0.3,1,0.5,1.6,0.7c0.5,0.1,1,0.2,1.5,0.2
          c1.3,0,2.5-0.5,3.3-1.5c0.9-0.9,1.4-2.2,1.4-3.5c0-1-0.2-2-0.7-2.9c-0.3-0.6-0.6-1.1-1.1-1.6c-0.7-0.6-1.4-1-2.2-1.4
          c-0.9-0.4-1.9-0.6-2.9-0.6h-0.6v-0.6c1.1-0.1,2.1-0.5,3-1.1c1.8-1,2.9-2.8,2.9-4.9c0-1.2-0.4-2.3-1.2-3.1c-0.8-0.8-1.9-1.2-3-1.2
          c-2,0-3.6,1-4.9,3.1L22,328.9z M57.6,339c0.2-0.2,0.3-0.1,0.5,0s0.2,0.3,0,0.5c-1.3,1.6-3,3.1-4.7,3.1c-1.4,0.1-2.8-0.3-4-1
          c-1.9-1-3.2-1.8-4.4-1.8c-1.4-0.1-2.7,0.4-3.8,1.2c-0.1,0.1-0.3,0.1-0.4-0.1s-0.1-0.3,0-0.4c1.4-1.6,3-3,4.7-3
          c1.3,0,2.7,0.3,3.9,0.9c1.8,0.9,3.2,1.8,4.5,1.8C55.2,340.4,56.6,339.9,57.6,339z M76.4,324l-1.5,3.2h-7.7l-1.7,3.4
          c3,0.3,5.8,1.6,7.9,3.7c1.6,1.6,2.5,3.7,2.5,6c0,1.3-0.3,2.6-0.8,3.7c-0.5,1.1-1.2,2.1-2.1,2.9c-0.8,0.8-1.7,1.5-2.8,2
          c-1.4,0.7-2.9,1-4.4,1c-1.2,0.1-2.3-0.2-3.3-0.8c-0.6-0.4-1-1-1-1.7c0-0.4,0.2-0.7,0.4-0.9c0.3-0.3,0.7-0.4,1.1-0.4
          c0.3,0,0.6,0,0.8,0.1c0.4,0.2,0.9,0.5,1.3,0.8c0.8,0.6,1.8,1,2.9,1c1.5,0,2.9-0.6,3.9-1.7c1.1-1.1,1.7-2.5,1.7-4
          c0-1.6-0.5-3.1-1.5-4.4c-1.1-1.4-2.5-2.5-4.1-3.1c-1.8-0.7-3.7-1-5.6-1l4.9-9.9H76.4z M80.9,328.9c0.6-1.5,1.5-2.9,2.8-4
          c1.2-1,2.6-1.4,4.2-1.4c1.8-0.1,3.5,0.6,4.7,2c0.8,0.9,1.2,2,1.3,3.2c0,1.9-1.2,3.8-3.5,5.8c1.4,0.5,2.7,1.4,3.6,2.6
          c0.8,1.2,1.2,2.6,1.2,4c0,2.1-0.7,4.1-2.1,5.7c-1.8,2.1-4.5,3.2-8,3.2c-1.2,0.1-2.4-0.1-3.5-0.6c-0.5-0.3-0.9-0.8-0.9-1.4
          c0-0.4,0.2-0.7,0.4-1c0.3-0.3,0.7-0.4,1.1-0.3c0.3,0,0.7,0,1,0.1c0.5,0.2,1,0.4,1.4,0.7c0.5,0.3,1,0.5,1.6,0.7
          c0.5,0.1,1,0.2,1.5,0.2c1.3,0,2.5-0.5,3.3-1.5c0.9-0.9,1.4-2.2,1.4-3.5c0-1-0.2-2-0.7-2.9c-0.2-0.6-0.6-1.1-1.1-1.6
          c-0.7-0.6-1.4-1-2.2-1.4c-0.9-0.4-1.9-0.6-2.9-0.6h-0.6v-0.6c1.1-0.1,2.1-0.5,3-1.1c0.9-0.5,1.7-1.2,2.2-2.1
          c0.5-0.8,0.7-1.8,0.7-2.7c0-1.2-0.4-2.3-1.2-3.1c-0.8-0.8-1.9-1.2-3-1.2c-1.9,0-3.6,1-4.9,3.1L80.9,328.9z M105.9,342.3
          c1.4-0.4,2.7-1,3.9-1.5c0.2-0.1,0.3,0.3,0.2,0.4c-2.5,2.1-5.3,3.9-8.2,5.3c-0.1,1-0.3,1.3-0.7,1.3s-0.9-0.9-1.9-3.8
          c0.8-0.1,1.6-0.3,2.4-0.5l1.5-0.4V333h-1.8c-0.5,0-1,0.1-1.5,0.2l-0.4-1.2c0.6,0.2,1.1,0.2,1.7,0.3h1.9v-4.9c0-1.5-0.1-2.8-0.2-3.8
          c3.8,0.4,4.2,0.7,4.2,1.1s-0.4,0.6-1.1,0.9v6.8h0.5c1-1.4,1.4-1.7,1.6-1.7s0.6,0.3,1.6,1.2c0.4,0.4,0.7,0.7,0.7,0.9
          s-0.1,0.3-0.4,0.3h-4L105.9,342.3z M119.1,339.1h4.3c1.5-1.8,2-2.1,2.2-2.1s0.6,0.2,2.1,1.5c0.6,0.5,0.9,0.8,0.9,1s-0.1,0.3-0.4,0.3
          h-9.1v1.7c0,4.7,0.2,8.3,0.2,9.3s-0.6,1.3-1.9,1.3c-0.9,0-1.3-0.3-1.3-1s0.1-4.9,0.1-9.6v-1.7H110c-0.6,0-1.2,0.1-1.8,0.2l-0.3-1.2
          c0.7,0.2,1.4,0.3,2.1,0.3h6.2v-12.6h-4.8c-0.6,0-1.3,0.1-1.9,0.2l-0.3-1.2c0.7,0.2,1.4,0.2,2.1,0.3h11.5c1.3-1.6,1.7-1.9,1.9-1.9
          s0.6,0.3,1.9,1.3c0.6,0.5,0.8,0.8,0.8,1s-0.1,0.3-0.4,0.3h-7.9L119.1,339.1z M112.9,336.6c-0.8,0-1.2-0.5-1.3-2.1
          c-0.1-1.9-0.5-3.8-1.4-5.5c-0.1-0.1,0.1-0.4,0.3-0.3c2.8,1.7,4.2,3.8,4.2,5.9c0.1,1-0.6,1.9-1.6,2
          C112.9,336.6,112.9,336.6,112.9,336.6z M125.7,330.8c-1.3,2.4-2.9,4.5-4.7,6.5c-0.1,0.1-0.5-0.1-0.5-0.3c0.7-1.7,1.3-3.4,1.7-5.1
          c0.3-1.1,0.6-2.3,0.7-3.5c3.4,1.1,3.9,1.3,3.9,1.8S126.5,330.8,125.7,330.8z M157.8,344.6l-1.8,4.9h-15v-0.7c3.4-3,6.6-6.3,9.4-9.9
          c1.8-2.6,2.7-4.9,2.7-7c0.1-1.5-0.5-2.9-1.5-4c-0.9-1-2.2-1.6-3.6-1.6c-1.2,0-2.4,0.4-3.4,1.1c-1.1,0.8-1.8,1.9-2.2,3.2h-0.7
          c0.2-2,1-3.9,2.4-5.3c1.3-1.2,3.1-1.9,4.9-1.9c1.9-0.1,3.8,0.7,5.2,2c1.3,1.2,2.1,2.9,2.1,4.7c0,1.3-0.3,2.7-0.9,3.9
          c-1.2,2.4-2.7,4.5-4.5,6.5c-3.1,3.4-5.1,5.5-5.9,6.2h6.7c1,0,1.9,0,2.9-0.1c0.5-0.1,1-0.3,1.5-0.6c0.5-0.3,0.9-0.8,1.1-1.3
          L157.8,344.6z M177.7,339c0.2-0.2,0.3-0.1,0.5,0s0.2,0.3,0,0.5c-1.4,1.6-3,3.1-4.7,3.1c-1.4,0.1-2.8-0.3-4-1c-1.9-1-3.2-1.8-4.4-1.8
          c-1.4-0.1-2.7,0.4-3.7,1.2c-0.1,0.1-0.3,0.1-0.4-0.1s-0.1-0.3,0-0.4c1.4-1.6,3-3,4.7-3c1.3,0,2.7,0.3,3.9,0.9
          c1.8,0.9,3.2,1.8,4.5,1.8C175.3,340.4,176.7,339.9,177.7,339z M197.7,340.1v2.7h-3.4v6.7h-3.1v-6.7h-10.8v-2.4l11.8-16.9h2.1v16.6
          H197.7z M191.2,340.1v-12.7l-9,12.7H191.2z M206.2,339.6c-0.2,1.7-0.5,3.5-1.1,5.1c-1,2.8-2.7,5.2-4.9,7.1c-0.1,0.1-0.4-0.1-0.3-0.3
          c1.4-2.4,2.4-5,2.9-7.8c0.6-3.9,0.9-7.8,0.8-11.8c0-2,0-2.9-0.2-4.6c1.7,0.8,2,0.9,3.7,1.7h15.7c1.2-1.3,1.4-1.5,1.7-1.5
          s0.6,0.2,1.7,1.1c0.5,0.4,0.7,0.6,0.7,0.9s-0.1,0.3-0.9,0.9v0.9c0,1.2,0.1,2.3,0.1,3.1c0,1-0.5,1.5-1.6,1.5c-1,0-1.4-0.4-1.4-1.3
          v-0.2h-16.5c0,1.5-0.1,3-0.3,4.4h7.6v-1.5c0-0.5-0.1-1.3-0.1-2.2c3.6,0.4,3.9,0.7,3.9,1s-0.3,0.5-0.9,0.9v1.7h6.9
          c1.5-1.7,1.9-2,2.1-2s0.6,0.3,2.1,1.4c0.7,0.5,0.9,0.8,0.9,1s-0.1,0.3-0.4,0.3h-13c-0.1,1.1-0.3,2.1-0.5,3.2h7.4
          c1.1-1.3,1.4-1.5,1.6-1.5s0.6,0.2,1.7,1.3c0.5,0.5,0.7,0.7,0.7,1s-0.1,0.3-1,0.8c-0.1,1.7-0.5,3.4-1,5.1c-0.3,0.9-0.9,1.6-1.7,2
          c-0.8,0.3-1.6,0.5-2.4,0.4c-0.8,0-0.9-0.1-1.1-0.8c-0.2-0.9-0.9-1.5-3.1-2.1c-0.2,0-0.1-0.5,0-0.5c2.1,0.3,3.4,0.4,4.2,0.4
          s1.3-0.3,1.5-1.2c0.4-1.4,0.6-2.8,0.7-4.2h-7.8c-1.4,4.4-4.8,7-10.4,8.7c-0.2,0-0.3-0.3-0.2-0.4c5.4-2.9,8-6.6,8.4-12.2L206.2,339.6
          z M223.4,325c1.7-1.8,2.1-2.1,2.3-2.1s0.7,0.3,2.3,1.4c0.8,0.6,1,0.8,1,1s-0.1,0.3-0.4,0.3h-24.8c-0.8,0-1.6,0.1-2.4,0.2l-0.4-1.2
          c0.9,0.2,1.8,0.2,2.7,0.3L223.4,325z M223,329.8h-16.5v1.3c0,0.9,0,1.8,0,2.6H223L223,329.8z"/>
        <rect class="st4 an9" x="175.8" y="279" width="1" height="27.8"/>
        <path class="an8" d="M17.1,298.1c0,2.5-0.9,4-2.4,4c-0.7,0-1.3-0.5-1.3-1.2c0,0,0-0.1,0-0.1c0-0.7,0.3-1,1.1-1.4c1.3-0.5,2.2-1.8,2.2-3.2
          c0-0.1,0.3-0.1,0.3,0C17.1,296.8,17.1,297.5,17.1,298.1z M25.3,291c0,0.9,0,1.6,0,2c0,0.8-0.4,1.1-1.4,1.1c-0.8,0-1.1-0.3-1.1-0.9
          c0-0.3,0-1,0.1-2.1H18v0.4c0,0.8-0.4,1-1.4,1s-1.1-0.3-1.1-0.9s0.1-1.4,0.1-4c0-1.4,0-2.9-0.1-4.3c1,0.4,2,0.9,2.9,1.4h4.5
          c0-2,0-2.8-0.1-4c2.7,0.3,3.1,0.4,3.1,0.8c0,0.2-0.2,0.4-0.7,0.6v2.5h5c1-1.1,1.2-1.2,1.4-1.2s0.5,0.1,1.4,0.9
          c0.4,0.4,0.6,0.5,0.6,0.7s-0.2,0.5-0.7,0.8v0.9c0,2.4,0,4.2,0,4.8c0,0.8-0.4,1.1-1.4,1.1c-0.8,0-1-0.3-1-1V291H25.3z M22.8,290.4
          c0-1,0-2.4,0-4v-1.2H18v5.2L22.8,290.4z M21.6,300.4c0,0.7,0.4,0.8,3.2,0.8c1.8,0,2.7,0,3.3-0.5s0.9-1.3,1.4-2.9
          c0-0.1,0.3-0.1,0.3,0c0,0.7,0,1.3,0,2.1c0,0.5,0,0.5,0.6,0.8c0.3,0.2,0.4,0.4,0.4,0.8c0,0.6-0.4,1-1.2,1.3c-1.7,0.4-3.5,0.5-5.3,0.4
          c-4.4,0-5.1-0.4-5.1-2.9c0-0.5,0.1-1,0.1-3.1c0-0.9,0-1.7-0.1-2.6c2.8,0.3,3.1,0.4,3.1,0.7s-0.1,0.4-0.7,0.7V300.4z M26.4,299
          c-0.7,0-0.9-0.2-1.3-1.5c-0.4-1-1-1.8-1.8-2.5c-0.1-0.1,0-0.2,0.1-0.2c2.7,0.5,4.2,1.4,4.2,2.8C27.6,298.3,27.1,298.9,26.4,299
          C26.4,299,26.4,299,26.4,299z M30.4,290.4v-5.2h-5.2l0.1,5.2H30.4z M34.1,302c-0.8,0-1-0.3-1.4-1.9c-0.2-1.4-0.9-2.6-1.8-3.6
          c-0.1-0.1,0-0.3,0.2-0.2c2.9,0.9,4.4,2.4,4.4,4.1C35.4,301.3,34.9,302,34.1,302z M56.6,288.4c1.3-1.4,1.5-1.6,1.7-1.6
          s0.5,0.2,1.6,1.1c0.5,0.4,0.7,0.6,0.7,0.8s-0.1,0.3-0.3,0.3h-7.5c-1,0.9-2.1,1.8-3.2,2.6h3.9c1-1,1.3-1.2,1.5-1.2s0.5,0.1,1.6,1.1
          c0.5,0.5,0.7,0.6,0.7,0.8c0,0.4-0.3,0.5-1.3,0.5c-1.4,0.8-3,1.5-4.6,2c0,0.2-0.3,0.4-0.8,0.7v1.1h5.5c1.2-1.4,1.5-1.6,1.7-1.6
          s0.5,0.2,1.6,1.1c0.5,0.4,0.7,0.6,0.7,0.8s-0.1,0.3-0.3,0.3h-9.2v1.4c0,1.9,0.1,2.3,0.1,3c0,0.5-0.1,1-0.3,1.5
          c-0.6,0.8-1.5,1.2-2.5,1c-0.5,0-0.5,0-0.7-0.8s-0.9-1.2-2.8-1.6c-0.1,0-0.1-0.4,0-0.4c1.3,0.1,2.4,0.1,3.5,0.1
          c0.2,0,0.3-0.1,0.3-0.4v-3.9h-6.1c-0.5,0-1.1,0.1-1.6,0.1l-0.3-0.8c-0.9,0.3-1.8,0.6-2.7,0.8c-0.1,0-0.3-0.3-0.1-0.4
          c3.1-1.2,6.1-2.8,8.8-4.8H43c-0.5,0-1,0.1-1.5,0.2l-0.3-1c0.5,0.1,1.1,0.2,1.7,0.2H47c1.1-0.8,2.1-1.6,3.1-2.5h-10
          c-0.6,0-1.2,0.1-1.8,0.1l-0.3-0.9c0.6,0.1,1.3,0.2,1.9,0.2h6.8v-3.5h-4.3c-0.5,0-1,0.1-1.5,0.1l-0.3-0.9c0.6,0.1,1.1,0.2,1.7,0.2
          h4.4v-0.8c0-0.9,0-1.9-0.2-2.8c3.1,0.3,3.4,0.6,3.4,0.8s-0.3,0.5-0.8,0.7v2.1h1.6c1-1.2,1.3-1.4,1.5-1.4s0.5,0.2,1.4,1
          c0.2,0.2,0.4,0.3,0.5,0.6c0.7-1,1.3-2,1.9-3c2,1.1,2.8,1.6,2.8,1.9s-0.3,0.5-1.3,0.5c-1.3,1.6-2.7,3.2-4.2,4.6L56.6,288.4z
          M48.8,292.1c-2.6,1.7-5.4,3.2-8.4,4.3c0.5,0.1,1,0.1,1.5,0.1h6.2v-0.3c0-0.8,0-1.6-0.1-2.4c1.4,0.2,2.2,0.4,2.7,0.5
          c1-0.6,1.9-1.3,2.6-2.2H48.8z M50.7,288.4c1.1-1.1,2.2-2.3,3.1-3.5h-4.7v3.5H50.7z M69.8,296.8c0,4.4,0.1,5.6,0.1,6.1
          c0,0.7-0.4,1-1.3,1c-0.7,0-1-0.2-1-0.9s0.2-3,0.2-5.5c-1.3,1.8-3,3.3-5,4.3c-0.1,0-0.3-0.2-0.2-0.3c2.1-2.1,3.7-4.6,4.8-7.4H65
          c-0.5,0-1,0.1-1.5,0.1l-0.3-0.9c0.6,0.1,1.1,0.2,1.7,0.2h2.8v-0.8c0-0.7,0-1.3-0.1-2c2.1,0.2,2.9,0.4,2.9,0.7s-0.2,0.4-0.7,0.6v1.4
          h1.8c0.9-1.1,1.2-1.3,1.3-1.3s0.5,0.2,1.4,0.9c0.4,0.3,0.6,0.5,0.6,0.7s-0.1,0.3-0.3,0.3h-4.8v1.2c2.4,0.5,3.7,1.4,3.7,2.6
          c0.1,0.6-0.4,1.1-1,1.2c0,0,0,0-0.1,0c-0.6,0-0.8-0.3-1.2-1.2c-0.3-0.8-0.8-1.6-1.4-2.2V296.8z M69.3,289.8c0.4-1,0.7-2,1-3
          c0.1-0.6,0.2-1.1,0.3-1.7c2,0.6,2.7,1,2.7,1.3s-0.2,0.4-0.9,0.4c-0.7,1.1-1.6,2.1-2.5,3h1.6c1-1.3,1.3-1.5,1.5-1.5s0.5,0.2,1.4,1
          c0.4,0.4,0.6,0.6,0.6,0.8s-0.1,0.3-0.3,0.3h-10c-0.5,0-1,0.1-1.5,0.1l-0.3-0.9c0.6,0.1,1.1,0.2,1.7,0.2L69.3,289.8z M67.7,282.6
          c0-0.7,0-1.3-0.1-1.9c2.7,0.2,3.1,0.5,3.1,0.7s-0.3,0.4-0.8,0.6v2.2h1.7c0.8-1.1,1.1-1.3,1.3-1.3s0.5,0.2,1.3,0.9
          c0.3,0.3,0.6,0.5,0.6,0.7s-0.1,0.3-0.3,0.3h-8.9c-0.5,0-1,0.1-1.5,0.1l-0.3-0.9c0.6,0.1,1.1,0.2,1.7,0.2h2.3L67.7,282.6z
          M66.9,289.3c-0.7,0-0.8-0.2-1-1.7c-0.1-0.7-0.3-1.4-0.7-2c-0.1-0.1,0-0.3,0.2-0.2c1.7,0.6,2.6,1.6,2.6,2.6c0.1,0.6-0.4,1.2-1,1.3
          C67,289.3,67,289.3,66.9,289.3L66.9,289.3z M77.7,289.7c0,1.8-0.2,3.6-0.5,5.3c-0.8,4.2-2.4,6.5-5.2,8.4c-0.1,0.1-0.4-0.2-0.3-0.3
          c2.1-2.5,3.1-5.1,3.6-9.6c0.2-1.9,0.2-4.2,0.2-7c0-1.4-0.1-2.1-0.2-3.3c1.2,0.5,1.5,0.6,2.6,1.1c1.2-0.6,2.3-1.3,3.3-2.2
          c0.5-0.4,1-0.9,1.4-1.4c1.7,1,2.5,1.6,2.5,2s-0.2,0.4-1.1,0.3c-2,0.9-4.1,1.5-6.3,1.9v0.6c0,1.3,0,2.4,0,3.4h5
          c1-1.3,1.3-1.5,1.5-1.5s0.5,0.2,1.5,1.1c0.4,0.4,0.7,0.6,0.7,0.8s-0.1,0.3-0.3,0.3h-3.4v4.7c0,3.2,0,7.3,0,8.6
          c0,0.6-0.5,0.9-1.4,0.9c-0.7,0-1-0.2-1-0.8s0.1-2.6,0.1-6.4v-7L77.7,289.7z M100.8,294h3.7c1.3-1.6,1.6-1.8,1.7-1.8s0.5,0.2,1.7,1.3
          c0.6,0.5,0.8,0.7,0.8,0.9s-0.1,0.3-0.3,0.3h-7.6v7h5.5c1.4-1.7,1.7-1.9,1.9-1.9s0.5,0.2,1.8,1.3c0.6,0.5,0.8,0.7,0.8,0.9
          s-0.1,0.3-0.3,0.3H91.2c-0.8,0-1.6,0.1-2.4,0.1l-0.3-0.9c0.9,0.1,1.8,0.2,2.7,0.2h7.2v-7H94c-0.7,0-1.4,0.1-2.1,0.1l-0.3-0.9
          c0.7,0.1,1.5,0.2,2.3,0.2h4.5v-5.9h-4.5c-1.1,2.2-2.8,4.1-4.9,5.5c-0.1,0.1-0.3-0.1-0.3-0.2c2.3-3.1,3.8-6.8,4.3-10.6
          c2.3,0.6,3.2,1,3.2,1.4s-0.3,0.5-1,0.6c-0.3,1-0.7,1.9-1.2,2.8h4.2v-3.1c0-1.2,0-2.4-0.2-3.6c3.2,0.3,3.5,0.6,3.5,0.9
          s-0.3,0.5-0.9,0.7v5h4.3c1.3-1.7,1.6-1.9,1.8-1.9s0.5,0.2,1.8,1.3c0.6,0.5,0.8,0.7,0.8,0.9s-0.1,0.3-0.3,0.3h-8.3L100.8,294z
          M119.2,284.8c0.3-0.6,0.5-1.2,0.7-1.9c0.2-0.7,0.3-1.3,0.4-2c2,0.6,2.6,0.9,2.6,1.2s-0.2,0.3-0.8,0.4c-0.6,0.9-1.3,1.6-2.1,2.3h1.7
          c0.8-1,1-1.1,1.3-1.1s0.5,0.1,1.3,0.8c0.4,0.3,0.5,0.5,0.5,0.7s-0.1,0.3-0.4,0.3h-3.6v2.2h0.6c0.6-0.8,0.9-1,1.1-1s0.5,0.2,1.2,0.7
          c0.3,0.2,0.5,0.5,0.5,0.6s-0.1,0.3-0.3,0.3h-3v2.2h0.6c0.6-0.8,0.9-1,1.1-1s0.5,0.2,1.2,0.7c0.3,0.2,0.5,0.4,0.5,0.6
          s-0.1,0.3-0.3,0.3h-3v2.4h1.1c0.7-0.9,0.9-1.1,1.1-1.1s0.5,0.2,1.2,0.8c0.3,0.3,0.5,0.5,0.5,0.6s-0.1,0.3-0.3,0.3H117v0.4
          c0,0.6-0.4,0.8-1.2,0.8c-0.6,0-0.8-0.2-0.8-0.6c0-1.2,0.1-2.3,0.1-7v-1c-0.7,0.6-1.4,1.1-2.3,1.6c-0.1,0-0.2-0.1-0.2-0.2
          c1.2-1.3,2.1-2.9,2.8-4.5c0.3-0.8,0.6-1.6,0.8-2.5c2,0.5,2.7,0.8,2.7,1.1s-0.2,0.4-0.8,0.4c-0.5,0.9-1,1.7-1.6,2.4L119.2,284.8z
          M125.1,301.3c-3.7,1.6-7.6,2.5-11.6,2.6c-0.1,0-0.3-0.4-0.2-0.4c3.5-0.6,6.9-1.7,10-3.3c-1.3-1-2.4-2.3-3.3-3.7H117
          c-0.5,0-1,0.1-1.5,0.2l-0.3-1c0.6,0.1,1.2,0.2,1.8,0.2h11.1c1-0.9,1.2-1,1.4-1s0.5,0.1,1.6,1c0.5,0.5,0.7,0.6,0.7,0.8
          s-0.2,0.4-1.1,0.5c-1.1,1.1-2.3,2.1-3.6,2.9c3.1,0.7,6.3,1,9.4,0.8c0.1,0,0.1,0.2,0,0.3c-0.9,0.4-1.2,0.8-1.5,2
          c-0.1,0.4-0.2,0.5-0.6,0.5c-0.8,0-1.5-0.1-2.3-0.3c-2.3-0.3-4.5-1-6.6-2L125.1,301.3z M118.9,287.5v-2.2H117v2.2H118.9z M117,290.3
          h1.9v-2.2H117V290.3z M117,293.3h1.9v-2.4H117V293.3z M120.7,296.5c1.1,1.2,2.5,2.2,4,2.9c1.3-0.8,2.4-1.8,3.4-2.9H120.7z
          M127.1,294.1c0,0.6-0.3,0.9-1.2,0.9c-0.6,0-0.8-0.2-0.8-0.6c0-0.8,0.1-1.6,0.1-4.9c0-1,0-2,0-2.9c-0.4,0.3-0.8,0.6-1.2,0.9
          c-0.1,0.1-0.3-0.1-0.2-0.2c0.9-1.2,1.6-2.6,2.1-4.1c0.3-0.7,0.5-1.5,0.6-2.2c2.1,0.6,2.7,0.8,2.7,1.1s-0.1,0.3-0.8,0.4
          c-0.4,0.9-0.9,1.7-1.6,2.4h2.5c0.3-0.7,0.5-1.4,0.8-2.1c0.2-0.6,0.3-1.1,0.4-1.7c2,0.6,2.6,0.9,2.6,1.2s-0.2,0.3-0.8,0.4
          c-0.6,0.8-1.3,1.6-2.1,2.3h2.3c0.9-1,1.1-1.2,1.3-1.2s0.5,0.1,1.4,0.8c0.4,0.3,0.6,0.5,0.6,0.7s-0.1,0.3-0.4,0.3h-4.1v2.2h0.9
          c0.6-0.9,0.9-1.1,1.1-1.1s0.5,0.2,1.3,0.8c0.3,0.3,0.5,0.5,0.5,0.6s-0.1,0.3-0.3,0.3h-3.5v2.2h0.9c0.6-0.9,0.9-1.1,1.1-1.1
          s0.5,0.2,1.3,0.8c0.3,0.3,0.5,0.5,0.5,0.6s-0.1,0.3-0.3,0.3h-3.4v2.4h1.4c0.8-1,1.1-1.2,1.2-1.2s0.5,0.2,1.3,0.8
          c0.4,0.3,0.6,0.5,0.6,0.7s-0.1,0.3-0.3,0.3h-8.4L127.1,294.1z M129.4,287.6v-2.2h-2.2v2.2H129.4z M127.1,290.3h2.2v-2.2h-2.2V290.3z
          M127.1,293.3h2.2V291h-2.2V293.3z M143.4,292.1c0.7-0.2,1.5-0.5,2.4-0.8c0.1,0,0.2,0.2,0.1,0.3c-0.8,0.6-1.7,1.2-2.5,1.7v2.3
          c0,2.5,0,4.6,0,5.6c0.1,0.7-0.2,1.4-0.6,1.9c-0.6,0.5-1.3,0.7-2.1,0.6c-0.2,0-0.4-0.2-0.4-0.5c0-1-0.4-1.3-2.3-1.9
          c-0.1,0-0.1-0.3,0-0.3c1.1,0.1,2,0.1,2.7,0.1c0.3,0,0.4,0,0.4-0.4v-6.2l-1.2,0.6c-0.1,0.6-0.3,0.9-0.5,0.9s-0.4-0.2-1.2-2.9l1.5-0.2
          c0.6-0.1,1.1-0.2,1.5-0.3V287h-1.3c-0.4,0-0.9,0.1-1.3,0.1l-0.3-0.9c0.5,0.1,1.1,0.2,1.6,0.2h1.3v-2.7c0-1,0-2-0.1-3
          c2.6,0.2,3.2,0.4,3.2,0.7s-0.2,0.4-0.8,0.7v4.3h0.1c0.8-1.1,1-1.3,1.2-1.3s0.5,0.2,1.3,0.9c0.3,0.3,0.5,0.5,0.5,0.7
          s-0.1,0.3-0.3,0.3h-2.8L143.4,292.1z M156.6,287c0.7-0.8,0.9-1,1.1-1s0.5,0.1,1.3,0.8c0.4,0.3,0.5,0.5,0.5,0.6s-0.1,0.3-0.6,0.7v1.9
          h0c0.7-1.2,1-1.4,1.2-1.4s0.4,0.2,1.1,1c0.2,0.2,0.4,0.4,0.4,0.7c0,0.2-0.1,0.3-0.3,0.3H159c0,1.7,0.1,2.9,0.1,3.3
          c0,0.6-0.5,0.9-1.3,0.9s-1-0.3-1-0.8v0h-3.2v2.9h3.1c1-1.3,1.3-1.5,1.5-1.5s0.5,0.2,1.5,1c0.4,0.4,0.6,0.6,0.6,0.8s-0.1,0.3-0.3,0.3
          h-6.4v3.6c0.9,0.1,2,0.1,3.2,0.1c1.6,0,3.2-0.1,4.9-0.2c0.1,0,0.1,0.2,0,0.3c-0.7,0.3-1.1,0.9-1.2,1.7c-0.1,0.5-0.1,0.6-0.4,0.6
          c-0.4,0-0.8,0.1-1.2,0c-2.5,0.1-4.9-0.2-7.2-1.1c-1.6-0.7-2.9-1.9-3.6-3.4c-0.9,2-2.5,3.6-4.5,4.5c-0.1,0-0.3-0.2-0.2-0.3
          c1.7-1.7,2.8-3.8,3.1-6.2c0.1-0.7,0.2-1.4,0.3-2.2c2.4,0.4,2.9,0.5,2.9,0.9s-0.2,0.4-0.7,0.6c-0.2,0.7-0.4,1.4-0.6,2
          c0.7,1.1,1.8,2,3.1,2.3V294H148c-0.5,0-1,0.1-1.5,0.2l-0.3-0.9c0.6,0.1,1.2,0.2,1.8,0.2h3.4v-2.7h-4.2c-0.5,0-1,0.1-1.5,0.1
          l-0.3-0.9c0.6,0.1,1.2,0.2,1.8,0.2h4.2v-2.5h-2.6c-0.6,0-1.2,0.1-1.7,0.2l-0.3-1c0.7,0.1,1.3,0.2,2,0.2h2.6v-2.2h-4.1
          c-0.5,0-1.1,0.1-1.6,0.1l-0.3-0.9c0.6,0.1,1.2,0.2,1.8,0.2h4.1v-1c0-0.8,0-1.7-0.1-2.5c2.7,0.3,3,0.5,3,0.8s-0.2,0.4-0.7,0.6v2.2
          h3.5c1.2-1.5,1.5-1.7,1.7-1.7s0.5,0.2,1.7,1.2c0.5,0.4,0.7,0.6,0.7,0.8s-0.1,0.3-0.3,0.3h-7.3v2.2H156.6z M153.6,290.1h3.2v-2.5
          h-3.2V290.1z M153.6,290.6v2.7h3.2v-2.7H153.6z"/>
        <rect class="st4 an7" y="279" width="1" height="27.8"/>
        <path class="an6" d="M99.9,12.7c0,1.4-0.5,2.2-1.3,2.2c-0.4,0-0.7-0.3-0.7-0.7c0,0,0-0.1,0-0.1c0-0.4,0.2-0.6,0.6-0.8c0.7-0.3,1.2-1,1.2-1.8
          c0-0.1,0.2-0.1,0.2,0C99.8,12,99.9,12.3,99.9,12.7z M104.4,8.7c0,0.5,0,0.9,0,1.1c0,0.4-0.2,0.6-0.8,0.6s-0.6-0.1-0.6-0.5
          c0-0.2,0-0.6,0-1.1h-2.7V9c0,0.5-0.2,0.6-0.8,0.6S99,9.4,99,9s0-0.8,0-2.2c0-0.8,0-1.6-0.1-2.4c0.6,0.2,1.1,0.5,1.6,0.8h2.5
          c0-1.1,0-1.5-0.1-2.2c1.5,0.2,1.7,0.2,1.7,0.5s-0.1,0.2-0.4,0.3v1.4h2.8c0.5-0.6,0.6-0.7,0.8-0.7s0.3,0.1,0.8,0.5
          c0.2,0.2,0.3,0.3,0.3,0.4s-0.1,0.3-0.4,0.4v0.5c0,1.3,0,2.3,0,2.6s-0.2,0.6-0.8,0.6c-0.4,0-0.6-0.1-0.6-0.5V8.7H104.4z M103,8.4
          c0-0.6,0-1.3,0-2.2V5.5h-2.7v2.9H103z M102.3,13.9c0,0.4,0.2,0.4,1.8,0.4c1,0,1.5,0,1.8-0.3s0.5-0.7,0.8-1.6c0-0.1,0.2,0,0.2,0
          c0,0.4,0,0.7,0,1.2c0,0.3,0,0.3,0.4,0.4c0.2,0.1,0.2,0.2,0.2,0.4c0,0.3-0.2,0.6-0.7,0.7c-1,0.2-1.9,0.3-2.9,0.2
          c-2.4,0-2.8-0.2-2.8-1.6c0-0.3,0-0.6,0-1.7c0-0.5,0-1-0.1-1.4c1.5,0.2,1.7,0.2,1.7,0.4s-0.1,0.2-0.4,0.4L102.3,13.9z M104.9,13.2
          c-0.4,0-0.5-0.1-0.7-0.9c-0.2-0.5-0.5-1-1-1.4c-0.1,0,0-0.1,0.1-0.1c1.5,0.3,2.3,0.8,2.3,1.6C105.7,12.8,105.4,13.1,104.9,13.2
          L104.9,13.2z M107.2,8.4V5.5h-2.9v2.9H107.2z M109.3,14.8c-0.4,0-0.6-0.2-0.8-1.1c-0.1-0.8-0.5-1.4-1-2c0,0,0-0.1,0-0.1
          c0,0,0,0,0.1,0c1.6,0.5,2.4,1.3,2.4,2.3C110,14.5,109.7,14.8,109.3,14.8z M107.8,21.2c0.7-0.8,0.9-0.9,1-0.9s0.3,0.1,0.9,0.6
          c0.3,0.2,0.4,0.3,0.4,0.4s-0.1,0.1-0.2,0.1h-4.2c-0.6,0.5-1.1,1-1.7,1.5h2.2c0.6-0.6,0.7-0.7,0.8-0.7s0.3,0.1,0.9,0.6
          c0.3,0.3,0.4,0.4,0.4,0.5s-0.2,0.3-0.7,0.3c-0.8,0.5-1.7,0.8-2.5,1.1c0,0.1-0.2,0.2-0.4,0.4v0.6h3.1c0.7-0.8,0.8-0.9,0.9-0.9
          s0.3,0.1,0.9,0.6c0.3,0.2,0.4,0.4,0.4,0.5s-0.1,0.1-0.2,0.1h-5.1v0.8c0,1,0,1.3,0,1.6c0,0.3-0.1,0.6-0.2,0.8
          c-0.3,0.4-0.9,0.7-1.4,0.6c-0.3,0-0.3,0-0.4-0.4s-0.5-0.6-1.6-0.9c-0.1,0-0.1-0.2,0-0.2c0.7,0,1.3,0.1,2,0.1c0.1,0,0.2-0.1,0.2-0.2
          V26h-3.4c-0.3,0-0.6,0-0.9,0.1l-0.1-0.4c-0.5,0.2-1,0.3-1.5,0.4c-0.1,0-0.1-0.2-0.1-0.2c1.7-0.7,3.4-1.6,4.9-2.6h-1.8
          c-0.3,0-0.5,0-0.8,0.1l-0.2-0.5c0.3,0.1,0.6,0.1,0.9,0.1h2.3c0.6-0.5,1.2-0.9,1.7-1.4h-5.6c-0.3,0-0.7,0-1,0.1l-0.2-0.5
          c0.3,0.1,0.7,0.1,1.1,0.1h3.8v-1.9H100c-0.3,0-0.6,0-0.8,0.1L99,18.8c0.3,0.1,0.7,0.1,1,0.1h2.4v-0.5c0-0.5,0-1.1-0.1-1.5
          c1.7,0.1,1.9,0.3,1.9,0.5s-0.2,0.3-0.5,0.4V19h0.9c0.6-0.7,0.7-0.8,0.8-0.8s0.3,0.1,0.8,0.5c0.1,0.1,0.2,0.2,0.3,0.3
          c0.4-0.5,0.7-1.1,1-1.6c1.1,0.6,1.5,0.9,1.5,1s-0.2,0.3-0.7,0.3c-0.7,0.9-1.5,1.7-2.3,2.5L107.8,21.2z M103.5,23.3
          c-1.4,1-3,1.8-4.6,2.4c0.3,0.1,0.6,0.1,0.8,0.1h3.4v-0.2c0-0.5,0-0.9,0-1.3c0.8,0.1,1.2,0.2,1.5,0.3c0.5-0.3,1-0.7,1.5-1.2H103.5z
          M104.6,21.2c0.6-0.6,1.2-1.3,1.7-2h-2.6v2H104.6z M99,36.4c-0.5,0.5-1,1.1-1.6,1.5c0,0-0.2-0.1-0.1-0.1c1.1-1.5,2-3.2,2.5-5
          c0.2-0.6,0.3-1.3,0.4-1.9c1.3,0.4,1.8,0.6,1.8,0.8s-0.1,0.3-0.5,0.3c-0.4,1.1-0.9,2.1-1.5,3.1c0.6,0.1,0.7,0.2,0.7,0.3
          s-0.1,0.2-0.4,0.3v1.2c0,3.2,0,5.3,0,6c0,0.4-0.2,0.6-0.8,0.6s-0.6-0.1-0.6-0.5s0.1-1.5,0.1-3.7L99,36.4z M107.7,41.7
          c0.7-1,0.9-1.1,1-1.1s0.3,0.1,1,0.7c0.3,0.3,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-7.8c-0.4,0-0.8,0-1.2,0.1l-0.2-0.5
          c0.4,0.1,0.8,0.1,1.3,0.1L107.7,41.7z M107.2,33.7c0.7-0.9,0.9-1,1-1s0.3,0.1,0.9,0.7c0.3,0.3,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-6.7
          c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.3,0.1,0.7,0.1,1,0.1H107.2z M105.3,52.8c0.6-0.5,0.7-0.6,0.8-0.6s0.3,0.1,0.8,0.6
          c0.3,0.2,0.3,0.3,0.3,0.4s-0.1,0.3-0.6,0.4c-0.6,0.6-1.2,1.2-1.9,1.7c0.3,0.1,0.5,0.2,0.8,0.3c1.6,0.4,3.2,0.5,4.8,0.5
          c0.1,0,0.1,0.1,0,0.2c-0.5,0.1-0.9,0.5-0.9,1c0,0.3-0.1,0.4-0.4,0.4c-0.3,0-0.5-0.1-0.8-0.1c-1-0.2-1.9-0.5-2.8-0.9
          c-0.5-0.2-1-0.5-1.5-0.8c-2,1.1-4.2,1.7-6.4,1.7c-0.1,0-0.1-0.1,0-0.2c2-0.3,3.9-1.1,5.6-2.2c-0.6-0.5-1-1.1-1.4-1.8
          c-1,1.2-2.4,2.2-3.9,2.8c-0.1,0-0.1-0.1-0.1-0.1c1.2-0.9,2.3-2,3.2-3.2c0.2-0.4,0.4-0.8,0.6-1.2c1,0.2,1.4,0.4,1.4,0.6
          s-0.1,0.1-0.4,0.2l-0.3,0.3L105.3,52.8z M100.8,48.1c-0.1-0.1-0.2-0.2-0.2-0.5c0-0.4-0.3-0.9-0.6-1.2h-0.1c-0.4,0-0.9,0-1.2-0.1
          c-0.1,0-0.1-0.2,0-0.2c2.3-0.1,4.6-0.5,6.8-1c0.5-0.1,1.1-0.3,1.5-0.6c1.2,0.7,1.5,1,1.5,1.1s-0.3,0.2-0.7,0.1
          c-1.6,0.3-3.1,0.5-4.7,0.6c0.9,0.3,1.4,0.7,1.4,1.1c0,0.3-0.2,0.6-0.5,0.6h2c0.2-0.4,0.4-0.8,0.5-1.2c0.1-0.3,0.2-0.6,0.3-0.9
          c1.3,0.4,1.6,0.6,1.6,0.8s-0.1,0.2-0.6,0.3c-0.4,0.4-0.8,0.8-1.3,1.1h1.6c0.5-0.6,0.6-0.7,0.7-0.7s0.3,0.1,1,0.8
          c0.3,0.3,0.4,0.4,0.4,0.5s-0.2,0.3-0.6,0.3c-0.2-0.1-0.4-0.1-0.6,0c-0.3,0.2-0.7,0.4-1,0.6c-0.1,0-0.1,0-0.1-0.1
          c0.2-0.3,0.3-0.7,0.3-1.1h-9.1c0.1,1-0.2,1.9-0.9,1.9c-0.4,0-0.7-0.3-0.7-0.7c0,0,0,0,0-0.1c0-0.3,0.1-0.5,0.4-0.6
          c0.6-0.3,1-0.9,0.9-1.6c0,0,0.1-0.1,0.1,0c0,0,0,0,0,0c0.1,0.3,0.2,0.5,0.2,0.8H100.8z M100.1,50.2c0,0.9-0.3,1.8-1.1,1.8
          c-0.4,0-0.7-0.3-0.7-0.6c0,0,0,0,0-0.1c0-0.3,0.1-0.5,0.5-0.7c0.6-0.2,1-0.8,1-1.5c0-0.1,0.1-0.1,0.1,0
          C100.1,49.5,100.1,49.9,100.1,50.2z M103.7,48.1c-0.2,0-0.3-0.2-0.3-0.5c-0.1-0.5-0.2-0.9-0.5-1.2l0,0c-0.8,0-1.6,0.1-2.3,0.1
          c0.8,0.3,1.2,0.7,1.2,1.1c0,0.2-0.2,0.5-0.4,0.6L103.7,48.1z M106.2,50.1c0,0.2,0,0.3,0.4,0.3c0.2,0.1,0.3,0.2,0.3,0.4
          c0,0.3-0.2,0.5-0.5,0.6c-0.8,0.2-1.6,0.3-2.4,0.3c-2.5,0-2.8-0.2-2.8-1.2c0-0.1,0-0.3,0-0.8c0-0.3,0-0.7-0.1-1
          c1.4,0.2,1.5,0.2,1.5,0.4s-0.1,0.2-0.4,0.3v1c0,0.4,0.2,0.4,1.3,0.4c1.2,0,1.7,0,1.9-0.2s0.4-0.5,0.6-1.2c0,0,0-0.1,0.1-0.1
          c0,0,0.1,0,0.1,0.1L106.2,50.1z M102,53.1l-0.1,0.1c0.5,0.6,1.1,1.1,1.8,1.5c0.6-0.5,1.2-1,1.6-1.7L102,53.1z M104.3,50.5
          c-0.3,0-0.4-0.1-0.5-0.7c-0.1-0.4-0.4-0.7-0.7-1c-0.1,0,0-0.1,0.1-0.1c1.2,0.2,1.8,0.6,1.8,1.2C104.9,50.2,104.6,50.4,104.3,50.5
          L104.3,50.5z M108.4,51.9c-0.4,0-0.5-0.1-0.7-0.8c-0.1-0.6-0.5-1.2-1-1.6c0,0,0-0.1,0.1-0.1c1.6,0.3,2.3,0.9,2.3,1.7
          C109.1,51.5,108.8,51.8,108.4,51.9L108.4,51.9z M105.8,60.5c0.6-0.6,0.7-0.7,0.8-0.7s0.3,0.1,0.8,0.6c0.3,0.2,0.3,0.3,0.3,0.5
          s-0.1,0.2-0.6,0.4c-1.2,2-2.8,3.7-4.8,4.9l0,0h4.6c0.5-0.6,0.6-0.7,0.7-0.7s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4
          s-0.1,0.2-0.4,0.4v0.7c0,1.7,0.1,2.9,0.1,3.3s-0.3,0.5-0.8,0.5s-0.6-0.1-0.6-0.5v-0.4h-4.8v0.3c0,0.4-0.2,0.5-0.8,0.5
          s-0.6-0.1-0.6-0.5c0-0.6,0.1-1.1,0.1-3.4c0-0.2,0-0.3,0-0.5c-1.1,0.5-2.2,0.9-3.4,1.3c-0.1,0-0.1-0.1-0.1-0.2
          c1.9-0.8,3.6-1.9,5.1-3.3h-0.2c-0.3,0-0.4-0.2-0.6-0.7c-0.1-0.6-0.4-1.2-0.9-1.6c-0.9,0.7-1.9,1.4-3,1.8c-0.1,0-0.2-0.1-0.1-0.1
          c1.4-1,2.6-2.3,3.6-3.8c0.4-0.6,0.7-1.2,0.9-1.9c1.4,0.3,1.7,0.5,1.7,0.7s-0.1,0.3-0.5,0.4c-0.2,0.3-0.4,0.6-0.6,0.9L105.8,60.5z
          M102.7,60.9c-0.4,0.6-0.9,1.1-1.5,1.6c1.2,0.3,2,0.9,2,1.6c0,0.1,0,0.3-0.1,0.4c1.1-1,2-2.3,2.8-3.6L102.7,60.9z M107.2,66.6h-4.8
          v3.7h4.8V66.6z M97.6,85.1c-0.1,0-0.2-0.1-0.1-0.2c3.8-2.7,5.3-5.7,5.3-10.8c0-0.4,0-0.8-0.1-1.2c1.8,0.2,2,0.2,2,0.5
          s-0.1,0.2-0.5,0.4c0,0.4,0,0.8-0.1,1.3c0.2,1.7,0.7,3.3,1.6,4.8c1.1,1.7,2.7,3,4.5,3.7c0.1,0,0.1,0.2,0,0.2c-0.5,0.1-0.7,0.4-0.9,1
          c-0.1,0.2-0.1,0.3-0.3,0.3c-0.3-0.1-0.5-0.2-0.7-0.4c-1.5-1.2-2.7-2.7-3.4-4.5c-0.5-1.2-0.7-2.5-0.9-3.7
          C103.6,80.9,101.7,83.3,97.6,85.1z M104,89.3c0.4-0.5,0.5-0.6,0.7-0.6s0.2,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s-0.1,0.2-0.5,0.3
          c-0.1,0-0.2,0-0.3,0.1c-0.2,0.2-0.5,0.3-0.7,0.4c0.1,0,0.1,0.1,0.1,0.2c0,0.1-0.1,0.1-0.1,0.2l-2.4,0.3v0.3c0,0.3,0.1,0.3,0.5,0.3
          c0.4,0,0.9,0,1.3-0.1c0.2-0.1,0.3-0.3,0.4-0.7c0-0.1,0.2-0.1,0.2,0v0.3c0,0.1,0,0.2,0.2,0.3s0.3,0.2,0.3,0.4s-0.2,0.5-0.7,0.6
          c-0.5,0.1-1.1,0.1-1.7,0.1c-1.1,0-1.5-0.1-1.5-0.9v-0.5h-0.4c-0.2,0-0.4,0.1-0.6,0.1l-0.2-0.5c0.2,0,0.5,0,0.8,0h0.4v-0.1
          c0-0.2,0-0.6,0-0.9c1.3,0.1,1.4,0.2,1.4,0.3s-0.1,0.2-0.3,0.3v0.3l1-0.1c0.3-0.6,0.4-0.6,0.6-0.6s0.3,0.1,0.7,0.3
          c0.1-0.2,0.1-0.5,0.1-0.7h-4.8V90c0.1,1.9-0.1,3.7-0.3,5.6c-0.2,1.3-0.8,2.5-1.6,3.5c0,0.1-0.2,0-0.1-0.1c0.4-1.1,0.6-2.3,0.7-3.5
          c0.1-1.2,0.2-2.6,0.2-4.5c0-0.9,0-1.5,0-2.5l1.4,0.8h1.2v-1.4c0-0.4,0-0.9-0.1-1.3c1.5,0.1,1.7,0.3,1.7,0.4s-0.1,0.2-0.4,0.3v0.8
          h0.9c0.5-0.7,0.7-0.8,0.8-0.8s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1h-2.6v0.9L104,89.3z M100.8,96.1
          c0,0.1-0.1,0.2-0.1,0.3c0.7,0.2,1,0.5,1,1c0,0.2-0.1,0.3-0.2,0.4c0.4,0,0.7-0.1,0.9-0.1c0.1-0.2,0.1-0.4,0.2-0.7
          c0-0.2,0.1-0.5,0.1-0.7c0.9,0.3,1.2,0.4,1.2,0.6s0,0.1-0.4,0.2c-0.2,0.2-0.4,0.4-0.6,0.6c0.7-0.1,1.4-0.2,1.9-0.3
          c0.1,0,0.1,0.1,0,0.1c-1.7,0.8-3.4,1.3-5.2,1.6c-0.1,0.3-0.1,0.4-0.3,0.4s-0.2-0.2-0.5-1.5c0.4,0,0.9,0,1.4,0l0.6-0.1
          c-0.1-0.1-0.1-0.2-0.1-0.4c0-0.4-0.2-0.7-0.3-1h-0.3c-0.3,0-0.5-0.1-0.5-0.4s0-0.4,0-1.1c0-0.4,0-0.7,0-1.1c0.6,0.2,0.7,0.3,1.2,0.5
          h2.3c0.4-0.4,0.5-0.5,0.6-0.5s0.3,0.1,0.7,0.4c0.2,0.1,0.3,0.2,0.3,0.3s0,0.1-0.3,0.3v0.2c0,0.4,0,0.6,0,0.7c0,0.4-0.2,0.5-0.7,0.5
          c-0.3,0-0.4-0.1-0.4-0.3L100.8,96.1z M103.3,93.2c0.4-0.5,0.6-0.6,0.7-0.6s0.3,0.1,0.7,0.4c0.2,0.2,0.3,0.3,0.3,0.3s0,0.1-0.2,0.1
          h-4.6c-0.2,0-0.5,0-0.7,0.1l-0.2-0.5c0.3,0.1,0.6,0.1,0.8,0.1L103.3,93.2z M103.3,94.7h-2.5v1h2.5V94.7z M107.2,91.3
          c0,1,0.2,1.9,0.4,2.9c0.1-0.2,0.2-0.5,0.3-0.7c0.2-0.5,0.3-1,0.4-1.5c1.2,0.4,1.6,0.6,1.6,0.8s-0.1,0.2-0.5,0.3
          c-0.3,0.9-0.7,1.8-1.3,2.5c0.2,0.4,0.4,0.8,0.6,1.1c0.2,0.4,0.5,0.6,0.6,0.6s0.4-0.4,0.9-1.3c0-0.1,0.2,0,0.1,0.1
          c-0.3,1.4-0.3,1.5-0.3,1.6s0,0.2,0.2,0.5c0.1,0.1,0.1,0.2,0.1,0.4c0,0.3-0.3,0.6-0.6,0.6c-0.7-0.2-1.3-0.6-1.7-1.3
          c-0.3-0.4-0.6-0.8-0.8-1.3c-1,1.1-2.4,2-3.8,2.5c-0.1,0-0.1-0.1-0.1-0.2c1.4-0.9,2.5-2,3.4-3.3c-0.4-1.3-0.7-2.7-0.8-4.1l-0.5,0.1
          c-0.2,0-0.4,0.1-0.6,0.2l-0.2-0.5c0.3,0,0.5,0,0.8,0l0.5-0.1c-0.1-0.9-0.1-1.9-0.2-3.1c0-0.6,0-0.8-0.1-1.4c1.5,0.2,1.8,0.3,1.8,0.5
          s-0.1,0.2-0.4,0.3c0,1.5,0.1,2.6,0.1,3.5l1.2-0.2c0.5-0.8,0.6-0.9,0.7-0.9s0.3,0.1,0.8,0.4c0.3,0.1,0.3,0.3,0.3,0.4
          c0,0.1-0.1,0.1-0.1,0.1L107.2,91.3z M108.6,89.7c-0.3,0-0.4-0.2-0.6-0.8c-0.1-0.5-0.3-0.9-0.7-1.3c0,0,0-0.1,0-0.1
          c1.1,0.2,1.8,0.7,1.8,1.4C109.2,89.3,109,89.6,108.6,89.7L108.6,89.7z M107.9,102.1c0.7-0.8,0.8-0.8,0.9-0.8s0.3,0.1,0.9,0.6
          c0.3,0.2,0.4,0.3,0.4,0.4s0,0.1-0.2,0.1H99.5c0,0.4,0,0.7,0,1.2c0.1,1.8-0.1,3.6-0.5,5.4c-0.3,1.4-0.9,2.6-1.7,3.7
          c0,0.1-0.2,0-0.2-0.1c0.5-1.2,0.8-2.4,0.9-3.7c0.1-1.4,0.2-2.9,0.2-5.3c0-0.8,0-1.6-0.1-2.3c0.6,0.2,1.1,0.5,1.6,0.8h3.7v-0.5
          c0-0.4,0-0.8-0.1-1.1c1.7,0.2,1.9,0.3,1.9,0.5s-0.2,0.3-0.5,0.4v0.8L107.9,102.1z M102.5,107.6c0,3.3,0,4.4,0,4.9
          c0,0.3-0.3,0.5-0.7,0.5s-0.6-0.1-0.6-0.5c0-0.5,0.1-2.7,0.1-4.6c-0.7,1.1-1.6,2-2.6,2.8c-0.1,0-0.2-0.1-0.1-0.1
          c1.2-1.5,2-3.2,2.4-5.1h-0.6c-0.3,0-0.5,0-0.8,0.1l-0.2-0.5c0.3,0.1,0.6,0.1,0.9,0.1h0.9v-1.3c0-0.4,0-0.6-0.1-1.1
          c1.4,0.1,1.6,0.2,1.6,0.4s-0.1,0.2-0.4,0.4v1.6h0.1c0.4-0.6,0.6-0.7,0.7-0.7s0.3,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1
          h-1.7v1.4c1.1,0.2,1.7,0.7,1.7,1.2c0,0.4-0.2,0.7-0.6,0.7c0,0,0,0,0,0c-0.3,0-0.4-0.1-0.5-0.6C102.9,108.1,102.7,107.8,102.5,107.6
          L102.5,107.6z M105.7,112.4c0,0.3-0.3,0.6-0.7,0.6s-0.6-0.2-0.6-0.5c0-1,0.1-2,0.1-5.9c0-1.1,0-2.3-0.1-3.4c0.5,0.2,1,0.4,1.5,0.7
          h2.3c0.5-0.6,0.6-0.6,0.7-0.6s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4s-0.1,0.3-0.4,0.5v1.3c0,3.4,0.1,5.7,0.1,6.5
          c0,0.3-0.2,0.6-0.6,0.6c0,0-0.1,0-0.1,0c-0.4,0-0.6-0.2-0.6-0.5v-0.4h-2.6L105.7,112.4z M108.2,104.2h-2.6v2.2h2.6V104.2z
          M105.7,109.1h2.6v-2.3h-2.6V109.1z M105.7,111.8h2.6v-2.4h-2.6V111.8z M99.1,124.2c-0.5,0.6-1.1,1.2-1.8,1.7
          c-0.1,0-0.2-0.1-0.1-0.1c1.1-1.4,1.9-3,2.5-4.6c0.3-0.8,0.5-1.5,0.6-2.3c1.3,0.3,1.8,0.6,1.8,0.8s-0.1,0.3-0.5,0.3
          c-0.5,1.2-1,2.3-1.7,3.3c0.8,0.1,0.9,0.2,0.9,0.3s-0.1,0.2-0.4,0.3v7.2c0,0.4-0.2,0.6-0.7,0.6s-0.6-0.1-0.6-0.5s0.1-1.5,0.1-3.7
          V124.2z M105.8,130.5h1.6c0.7-1,0.9-1.1,1-1.1s0.3,0.1,1,0.8c0.3,0.3,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-7.4c-0.4,0-0.8,0-1.2,0.1
          l-0.2-0.5c0.4,0.1,0.8,0.1,1.3,0.1h2.2v-6.2H102c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.3,0.1,0.7,0.1,1,0.1h2.5v-2.9c0-0.7,0-1.3-0.1-2
          c1.8,0.2,2,0.3,2,0.5s-0.2,0.3-0.5,0.4v4h2c0.7-0.8,0.9-1,1-1s0.3,0.1,0.9,0.7c0.3,0.3,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-4.2V130.5z
          M99,138.6c0.5-0.8,1-1.7,1.4-2.6c0.2-0.5,0.4-0.9,0.5-1.4c1.1,0.5,1.5,0.8,1.5,1s-0.1,0.2-0.5,0.2c-0.7,1-1.6,2-2.5,2.9
          c0.7,0,1.4,0,2-0.1c-0.1-0.4-0.3-0.8-0.5-1.1c0-0.1,0.1-0.1,0.1-0.1c1,0.6,1.5,1.2,1.5,1.8c0,0.4-0.3,0.8-0.6,0.8s-0.4-0.1-0.5-1
          c-0.3,0.1-0.5,0.2-0.8,0.3v1.9c0,1.5,0,3.4,0,4c0,0.4-0.3,0.5-0.7,0.5s-0.6-0.1-0.6-0.4s0.1-1.2,0.1-3v-1c-0.1,0.1-0.2,0.1-0.3,0.1
          c-0.4,1.2-0.9,2.2-1.7,3.2c0,0.1-0.2,0-0.2-0.1c0.2-0.7,0.4-1.4,0.6-2.2c0.1-0.6,0.2-1.2,0.2-1.8c0.5,0.1,1,0.3,1.4,0.5v-1.4
          c-0.3,0.1-0.8,0.2-1.3,0.3c-0.1,0.3-0.2,0.4-0.3,0.4s-0.3-0.3-0.5-1.5c0.3,0,0.5,0,0.7,0L99,138.6z M99.4,133.8
          c0.1-0.4,0.2-0.8,0.2-1.1c1.3,0.4,1.7,0.7,1.7,0.8s-0.2,0.2-0.6,0.2c-0.5,0.6-1,1.2-1.6,1.8c0.4,0.2,0.6,0.6,0.6,1
          c0,0.4-0.2,0.7-0.6,0.8c0,0,0,0,0,0c-0.3,0-0.4-0.1-0.6-0.8c-0.1-0.5-0.4-1-0.8-1.4c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0
          c0.4,0.1,0.8,0.3,1.1,0.4C99.1,134.8,99.3,134.3,99.4,133.8L99.4,133.8z M101.8,143.4c-0.4,0-0.4-0.1-0.5-1.1c0-0.6-0.2-1.2-0.5-1.8
          c0-0.1,0.1-0.1,0.1-0.1c1.1,0.8,1.5,1.4,1.5,2.1C102.5,143.1,102.2,143.4,101.8,143.4L101.8,143.4z M106.8,141.3c0,1.8,0,3.1,0,3.7
          s-0.3,0.6-0.8,0.6s-0.6-0.2-0.6-0.5c0-0.6,0-1.8,0-3.8H104v0.4c0,0.4-0.3,0.5-0.7,0.5s-0.5-0.2-0.5-0.4c0-0.7,0-1.4,0-4.3
          c0-0.9,0-1.8-0.1-2.8c0.5,0.2,0.9,0.5,1.4,0.7h1.4v-0.2c0-0.8,0-1.6-0.1-2.4c1.6,0.1,1.8,0.2,1.8,0.5s-0.1,0.3-0.5,0.4v1.8h1.5
          c0.5-0.6,0.6-0.6,0.7-0.6s0.2,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s-0.1,0.3-0.4,0.4v0.9c0,2.4,0.1,4,0.1,4.6c0,0.4-0.3,0.5-0.8,0.5
          s-0.5-0.1-0.5-0.4v-0.5L106.8,141.3z M105.6,135.8h-1.5v2.3h1.5V135.8z M105.6,141c0-0.2,0-0.4,0-0.5v-2h-1.5v2.6L105.6,141z
          M108.5,138.1v-2.3h-1.6v2.3H108.5z M108.5,141v-2.5h-1.6c0,0.7,0,1.4,0,2.1v0.5L108.5,141z M101.1,149.8h0.5
          c0.5-0.6,0.6-0.7,0.7-0.7s0.3,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1h-2.1v1.3h0.9c0.5-0.6,0.7-0.7,0.8-0.7
          s0.3,0.1,0.7,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1h-5.4c-0.2,0-0.5,0-0.7,0.1l-0.2-0.5c0.3,0.1,0.6,0.1,0.9,0.1h1.7v-1.3h-1.1
          c-0.2,0-0.4,0-0.6,0.1l-0.2-0.5c0.2,0.1,0.5,0.1,0.7,0.1h1.1v-1.2h-1.5c-0.2,0-0.4,0-0.6,0.1l-0.2-0.5c0.2,0.1,0.5,0.1,0.8,0.1h1.5
          V148c0-0.5,0-0.9-0.1-1.4c1.6,0.1,1.7,0.3,1.7,0.4s-0.2,0.2-0.4,0.3v0.9h0.8c0.5-0.6,0.7-0.8,0.8-0.8s0.3,0.1,0.8,0.5
          c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1h-2.4L101.1,149.8z M99.1,156c0,2.1,0,2.6,0,2.9s-0.2,0.6-0.7,0.6s-0.5-0.1-0.5-0.5
          c0-0.8,0.1-1.9,0.1-4c0-1.6,0-2,0-2.8c0.4,0.2,0.9,0.4,1.3,0.6h2.6c0.4-0.5,0.5-0.6,0.6-0.6s0.3,0.1,0.7,0.5
          c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4v0.8c0,2.9,0,3.4,0,3.7c0,0.4-0.1,0.8-0.4,1.2c-0.3,0.2-0.7,0.3-1.1,0.3
          c-0.2,0-0.2,0-0.2-0.3c-0.1-0.5-0.3-0.7-1.1-1c-0.1,0-0.1-0.2,0-0.2c0.5,0.1,1,0.1,1.5,0.1c0.1,0,0.1,0,0.1-0.2V156H99.1z
          M100,155.7v-2.5h-0.8v2.5H100z M101.9,153.1h-0.8v2.5h0.8V153.1z M109.3,147.8c-0.2,0-0.3,0-0.5-0.1c-1.1,0.4-2.3,0.6-3.5,0.7
          c-0.4,0.9-1.1,1.8-2,2.4c-0.1,0.1-0.1,0-0.1-0.1c0.4-0.7,0.6-1.4,0.8-2.1c0.1-0.4,0.1-0.7,0.1-1.1c0.4,0.2,0.7,0.3,1.1,0.5
          c0.8-0.2,1.5-0.5,2.2-0.9c0.3-0.1,0.5-0.3,0.8-0.5c1.1,0.7,1.4,0.9,1.4,1S109.5,147.8,109.3,147.8L109.3,147.8z M107,155.8
          c0,0.8,0,2,0,2.4c0,0.2,0,0.5-0.1,0.7c-0.3,0.4-0.8,0.6-1.3,0.6c-0.3,0-0.3,0-0.4-0.2c-0.1-0.6-0.4-0.9-1.3-1.1
          c-0.1,0-0.1-0.2,0-0.2c0.6,0,1.1,0.1,1.5,0.1s0.3-0.1,0.3-0.2v-2.1h-1.3c-0.2,0-0.5,0-0.7,0.1l-0.2-0.5c0.3,0.1,0.6,0.1,0.9,0.1h1.3
          v-2h-1.3c-0.3,0-0.7,0-1,0.1l-0.2-0.5c0.4,0.1,0.7,0.1,1.1,0.1h1.3v-1.8H105c-0.3,0-0.6,0-0.9,0.1l-0.2-0.5c0.3,0.1,0.7,0.1,1,0.1h3
          c0.4-0.5,0.5-0.6,0.6-0.6s0.3,0.1,0.8,0.5c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.4,0.4v1.5h0.1c0.3-0.7,0.4-0.8,0.6-0.8s0.3,0.1,0.6,0.6
          c0.1,0.1,0.2,0.3,0.2,0.4s0,0.1-0.2,0.1h-1.2c0,1.4,0.1,2.4,0.1,2.8s-0.3,0.5-0.7,0.5s-0.5-0.1-0.5-0.5v-0.5L107,155.8z
          M106.7,150.7c-0.3,0-0.5-0.2-0.5-0.7c0-0.5-0.1-1-0.3-1.4c0-0.1,0.1-0.1,0.1-0.1c0.9,0.6,1.3,1,1.3,1.5
          C107.4,150.4,107.1,150.6,106.7,150.7L106.7,150.7z M107,153.1h1v-1.8h-1V153.1z M108,153.4h-1v2h1V153.4z M109.4,150.5
          c-0.4,0-0.5-0.2-0.7-0.7c-0.1-0.6-0.4-1.1-0.8-1.6c0-0.1,0-0.1,0.1-0.1c1.3,0.6,1.9,1.1,1.9,1.7C110,150.2,109.7,150.4,109.4,150.5
          L109.4,150.5z M100.4,168.1c-0.9,0.7-1.9,1.2-3,1.6c-0.1,0-0.2-0.1-0.1-0.2c1.7-0.9,3-2.3,3.8-4.1h-2.4c-0.3,0-0.7,0-1,0.1l-0.2-0.5
          c0.3,0.1,0.7,0.1,1.1,0.1h2.5V163h-1.6c-0.3,0-0.7,0-1,0.1l-0.2-0.5c0.4,0.1,0.7,0.1,1.1,0.1h1.6V162c0-0.5,0-0.9-0.1-1.4
          c1.6,0.1,1.8,0.3,1.8,0.4s-0.2,0.2-0.5,0.4v1.2h2.4V162c0-0.5,0-0.9-0.1-1.4c1.6,0.1,1.8,0.3,1.8,0.4s-0.1,0.2-0.4,0.4v1.2h1.2
          c0.6-0.7,0.8-0.8,0.9-0.8s0.3,0.1,0.9,0.6c0.2,0.2,0.3,0.3,0.3,0.4s0,0.1-0.2,0.1H106v2.1h1.7c0.7-0.8,0.9-0.9,1-0.9s0.3,0.1,1,0.7
          c0.3,0.2,0.4,0.4,0.4,0.5s0,0.1-0.2,0.1h-4.4c0.5,0.7,1.1,1.3,1.9,1.8c0.9,0.5,1.9,0.8,2.9,1c0.1,0,0.1,0.2,0,0.2
          c-0.5,0.2-0.5,0.3-0.8,0.9c-0.1,0.2-0.1,0.2-0.2,0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.7-0.3-1.4-0.7-1.9-1.3l-0.3-0.2l-0.1,0.1v0.5
          c0,0.9,0,1.4,0,1.6c0,0.4-0.2,0.5-0.7,0.5s-0.5-0.1-0.5-0.4v-0.2h-3.8v1.8c0,0.4,0.2,0.4,2.6,0.4c2.1,0,2.7-0.1,2.9-0.4
          c0.3-0.4,0.5-0.9,0.6-1.5c0-0.1,0.2-0.1,0.2,0c0,0.4,0,1,0,1.3s0,0.2,0.2,0.3c0.2,0.1,0.3,0.3,0.3,0.5c0,0.4-0.2,0.6-0.7,0.7
          c-1.3,0.3-2.7,0.4-4,0.3c-2.8,0-3.3-0.2-3.3-1.4c0-0.5,0-1.9,0-3.4L100.4,168.1z M102.6,165.4c-0.3,0.7-0.8,1.3-1.3,1.8l0.3,0.1h3.7
          c0.4-0.4,0.5-0.5,0.6-0.5c-0.3-0.5-0.6-1-0.8-1.5H102.6z M105.4,167.7h-3.8v1.8h3.8V167.7z M102.4,163v2.1h2.4V163H102.4z
          M107.8,176.6c0.6-0.7,0.7-0.8,0.8-0.8s0.3,0.1,1.1,0.8c0.4,0.4,0.5,0.4,0.5,0.6s-0.1,0.3-0.6,0.3c-0.2-0.1-0.4,0-0.6,0.1
          c-0.4,0.3-0.8,0.5-1.3,0.8c-0.1,0-0.2,0-0.1-0.1c0.2-0.4,0.4-0.9,0.4-1.4h-8.5c0,1.1-0.4,1.9-1.1,1.9c-0.4,0-0.7-0.3-0.7-0.7
          c0,0,0,0,0-0.1c0-0.3,0.2-0.6,0.4-0.7c0.6-0.2,1-0.9,1-1.5c0-0.1,0.1-0.1,0.2,0c0.1,0.3,0.1,0.5,0.2,0.8h3.5v-0.9c0-0.4,0-0.8,0-1.1
          c1.7,0.2,1.9,0.3,1.9,0.5s-0.2,0.3-0.5,0.4v1.1L107.8,176.6z M102.2,180c-1.3,0.2-2.5,0.4-3.8,0.4c-0.1,0-0.1-0.2,0-0.2
          c2-0.4,3.9-1,5.8-1.9c0.6-0.3,1.1-0.6,1.5-1.1c1.2,0.8,1.5,1.1,1.5,1.3s-0.1,0.2-0.8,0.1c-0.9,0.5-1.8,0.8-2.8,1.1v2.3l3.8-0.5
          c0.7-1,0.8-1.1,0.9-1.1s0.3,0.1,1,0.5c0.4,0.2,0.4,0.3,0.4,0.4s-0.1,0.1-0.2,0.2l-6.1,0.8v2.3c0,1,0.1,1.1,2.2,1.1
          c1.6,0,2.1-0.1,2.3-0.4c0.3-0.6,0.6-1.2,0.8-1.9c0-0.1,0.1-0.1,0.1,0c0,0.5-0.1,1.1-0.1,1.6c0,0.2,0,0.2,0.2,0.3
          c0.2,0.1,0.4,0.3,0.4,0.5c0,0.4-0.3,0.7-0.9,0.9c-1.1,0.2-2.1,0.3-3.2,0.3c-2.7,0-3.2-0.4-3.2-1.8v-2.7l-3.3,0.5
          c-0.3,0.1-0.6,0.1-0.9,0.2l-0.2-0.5c0.3,0,0.7,0,1,0l3.4-0.5L102.2,180z M103,193c0.5-0.7,0.6-0.9,0.8-0.9s0.3,0.1,0.8,0.6
          c0.3,0.2,0.3,0.3,0.3,0.4s-0.1,0.1-0.2,0.1l-2.3,0.1c0,0.2,0,0.3,0.1,0.5c0.1,1,0.4,2,0.9,2.9c0.1,0.2,0.3,0.5,0.5,0.7
          c0,0,0.2-0.2,0.6-1.4c0-0.1,0.2,0,0.2,0c-0.1,0.5-0.2,1.4-0.2,1.6c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.2,0.5
          c0,0.3-0.2,0.6-0.6,0.6c0,0-0.1,0-0.1,0c-0.6-0.1-1.1-0.5-1.4-1c-0.6-1-1.1-2.1-1.3-3.2c-0.1-0.5-0.2-1-0.2-1.6l-1.8,0.1v3.7
          c0.8-0.2,1.5-0.4,2.3-0.6c0.1,0,0.2,0.2,0.1,0.2c-1.1,0.8-2.2,1.4-3.4,1.9c0,0.4-0.1,0.5-0.3,0.5s-0.3-0.4-0.7-1.7
          c0.3,0,0.6,0,0.8-0.1V191c0-0.6,0-1.2-0.1-1.8c0.5,0.2,1,0.4,1.5,0.7c0.8-0.2,1.7-0.5,2.4-0.9c0.4-0.2,0.7-0.4,1.1-0.7
          c0.9,0.6,1.3,1,1.3,1.1s-0.2,0.3-0.8,0.1c-0.5,0.2-1.1,0.3-1.7,0.4c0.8,0.1,0.8,0.1,0.8,0.3s-0.1,0.2-0.4,0.3c0,0.8,0,1.6,0.1,2.3
          L103,193z M101.4,199.9c0.6-0.7,0.8-0.8,0.8-0.8s0.3,0.1,0.9,0.6c0.3,0.2,0.4,0.3,0.4,0.4s0,0.1-0.2,0.1h-4.2c-0.3,0-0.6,0-0.9,0.1
          l-0.2-0.5c0.3,0.1,0.7,0.1,1,0.1H101.4z M101.3,193.1c-0.1-1-0.1-1.9-0.1-2.9c-0.6,0.1-1.1,0.1-1.7,0.1v2.8L101.3,193.1z
          M108.4,189.3c0.5-0.5,0.6-0.6,0.7-0.6s0.2,0.1,0.8,0.6c0.3,0.2,0.3,0.4,0.3,0.5s-0.1,0.2-0.5,0.3c-0.7,1.2-1.4,2.4-2.3,3.6
          c1.8,1.3,2.4,2.2,2.4,3.6c0,0.5-0.2,1-0.5,1.4c-0.3,0.3-0.7,0.4-1.1,0.4c-0.2,0-0.3,0-0.3-0.4s-0.5-0.7-1.4-0.8
          c-0.1,0-0.1-0.2,0-0.2c0.5,0,0.8,0.1,1.3,0.1s0.5,0,0.5-0.1s0.1-0.4,0.1-0.9c0-1.2-0.5-2.3-1.5-3.1c0.5-1.3,0.9-2.7,1.3-4.1h-2v8.6
          c0,1.1,0,2,0,2.4s-0.3,0.6-0.8,0.6s-0.5-0.2-0.5-0.5c0-1.1,0-2.6,0-7.7c0-2.5,0-3.6-0.1-4.4c0.5,0.2,0.9,0.4,1.4,0.7L108.4,189.3z"
          />
        <path class="st5 an5" d="M83.6,2.4c-0.5,0-0.9,0-1.4,0.2c-0.3,0.2-0.3,0.6-0.3,1.1c0,0.7,0.2,1.4,0.6,1.9c0.3,0.4,0.7,0.7,1.1,0.9
          c0.1,0,0.1,0.1,0.1,0.2s-0.1,0.1-0.2,0.1c-0.7-0.1-1.3-0.3-2-0.6c0-1,0-2,0-3s0-2.1,0-3.1c0-0.1,0-0.1,0.1-0.1s0.1,0,0.2,0.1
          c0,0.4,0,0.6,0.3,0.8c0.5,0.2,1,0.2,1.5,0.1h4.1c1.1,0,1.2,0,1.5-0.1s0.2-0.4,0.3-0.8c0-0.1,0-0.1,0.2-0.1s0.2,0,0.2,0.1
          c0,0.5,0,1.1,0,1.6s0,1.1,0,1.6c0,0.1,0,0.1-0.2,0.1s-0.1,0-0.2-0.1c0-0.4,0-0.7-0.3-0.8c-0.5-0.1-1-0.2-1.5-0.1L83.6,2.4z
          M84.1,12.6c-1.5,0.1-2.7-1-2.8-2.5c-0.1-1.5,1-2.7,2.5-2.8c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7,1,2.8,2.5c0.1,1.5-1,2.7-2.5,2.8
          C84.3,12.6,84.2,12.6,84.1,12.6z M84.1,8.6c-1.4,0-2.4,0.4-2.4,1.3s1.1,1.3,2.4,1.3s2.4-0.4,2.4-1.3S85.4,8.7,84.1,8.6L84.1,8.6z
          M83.5,16c-0.3,0.1-0.6,0.2-0.9,0.3c0.4,0.1,0.6,0.2,1,0.4l1.5,0.5c0.3,0.2,0.7,0.2,1.1,0.1c0.2-0.1,0.2-0.3,0.2-0.6
          c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.3,0,0.8,0,1.1s0,0.7,0,1c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.2-0.1-0.4-0.3-0.6
          c-0.3-0.3-0.7-0.5-1.1-0.6l-2.1-0.8l-1.3-0.5c-0.1-0.2-0.2-0.5-0.3-0.7c0,0,0-0.1,0.1-0.1c0.5-0.2,0.9-0.3,1.5-0.5l2.1-0.8
          c0.4-0.1,0.8-0.3,1.2-0.5c0.2-0.1,0.2-0.3,0.2-0.6c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.4,0,0.9,0,1.3s0,0.9,0,1.4
          c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.3,0-0.6-0.2-0.6s-0.5,0.1-1.2,0.3L83.5,16z M84.1,20.5c-1.5,0-2.2,0.9-2.2,1.6
          c0,0.6,0.3,1.2,0.8,1.6c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1l-0.1,0c-0.7-0.4-1.2-1.2-1.1-2.1
          c0-1.4,1.1-2.6,2.5-2.6c0,0,0.1,0,0.1,0c1.5-0.1,2.7,1.1,2.8,2.5c0,0,0,0.1,0,0.1c0,1.2-0.9,2.1-2.1,2.2c-0.1,0-0.1,0-0.2,0
          c-0.1-0.1-0.1-0.1-0.1-0.2c0-1.1,0-2.3,0-3.3C84.3,20.5,84.2,20.4,84.1,20.5z M84.9,22.7c0,0.1,0.1,0.1,0.2,0.1c0.6,0,1.4-0.3,1.4-1
          s-0.7-1.1-1.6-1.3C84.9,21.2,84.9,22,84.9,22.7z M82.8,32.7c-0.7,0-1,0.2-1,0.4c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0
          c-0.1,0-0.1,0-0.2,0c-0.2-0.3-0.3-0.6-0.3-1c0-0.4,0.2-0.8,0.6-1c-0.4-0.4-0.7-1-0.7-1.6c0-1.1,0.7-1.5,1.2-1.5c0.4,0,0.7,0.1,1,0.4
          c0.3,0.4,0.5,0.9,0.6,1.5c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.2,0.3,0.3,0.4,0.3h0.3c0.8,0,1.2-0.2,1.2-0.7c0-0.2-0.1-0.4-0.2-0.6
          c-0.2-0.2-0.4-0.2-0.6-0.2c-0.3,0-0.5-0.3-0.5-0.9c0-0.4,0.1-0.4,0.4-0.4c0.5,0,1.4,0.9,1.4,2.3c0,1.7-0.8,1.7-1.7,1.7L82.8,32.7z
          M84.3,31.6c-0.2-0.1-0.3-0.3-0.5-0.9c-0.1-0.5-0.6-0.9-1.2-0.8c-0.5,0-0.9,0.3-0.9,0.7c0,0.6,0.4,1,1,1L84.3,31.6z M86.1,33.9
          c0-0.1,0-0.1,0.2-0.1s0.2,0,0.2,0.1c0.1,0.4,0.2,0.7,0.5,1c0.2,0.2,0.5,0.4,0.8,0.6c0.1,0,0.1,0.1,0.1,0.2s0,0.2-0.1,0.2h-1.2
          c0,0.5,0,1.1,0,1.6c0,0,0,0-0.1,0l-0.3-0.1c0,0-0.1-0.1-0.1-0.1c0-0.4,0-1,0-1.4h-2.9c-0.4-0.1-0.8,0-1.2,0.2
          c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.7c0,0.1,0,0.1,0,0.2s-0.1,0.1-0.2,0c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.4,0.1-0.9,0.4-1.2
          c0.4-0.3,0.8-0.4,1.3-0.4h1.6h1.6L86.1,33.9z M86.5,45.1c0,0.6,0.1,1.3,0.2,1.9c0,0.1,0,0.1-0.1,0.1c-0.8,0-1.5,0-2,0h-1.5
          c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.4,0-0.9,0-1.2s0-0.9,0-1.3
          c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.1,1.2,0.1h1.8c0.3,0,0.6,0,0.9-0.1c0.2-0.1,0.2-0.2,0.2-0.8
          v-1.4h-3c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.4-0.2,0.7c0,0,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.5,0-1,0-1.3s0-0.9,0-1.3
          c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.3,0,0.6,0.2,0.7c0.4,0.1,0.8,0.1,1.2,0.1h3c0-0.3,0-0.6,0-0.9c0,0,0-0.1,0.1-0.1l0.3,0.1
          c0,0,0.1,0.1,0.1,0.1c0,0.3,0,0.5,0,0.8h0.7c0.9,0,1.7,0.4,2.3,1.1c0.5,0.6,0.7,1.4,0.7,2.2c0,0.8-0.2,1.3-0.6,1.3s-0.9-0.2-0.9-0.6
          s0.3-0.5,0.5-0.7s0.5-0.4,0.5-0.8s-0.4-0.9-1.2-1.1c-0.7-0.2-1.3-0.2-2-0.2V45.1z M83.8,54.4c0.4-0.8,0.7-1.3,1.6-1.3
          c0.6,0,1.4,0.5,1.4,1.8c0,0.3,0,0.5-0.1,0.8c0,0.2,0,0.5,0,0.8c-0.4,0-0.9,0.1-1.3,0.1c-0.1,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0.1-0.2
          c0.6-0.3,1.2-0.8,1.2-1.3s-0.3-0.9-0.8-0.9c-0.3,0-0.6,0.3-0.8,0.8l-0.2,0.4c-0.4,1-0.8,1.5-1.8,1.5c-0.8,0-1.7-0.6-1.7-2
          c0-0.6,0.1-1.1,0.3-1.6c0.4-0.1,0.8-0.2,1.2-0.2c0.1,0,0.1,0,0.1,0.1s0,0.1-0.1,0.2c-0.7,0.3-1.2,0.9-1.3,1.6
          c-0.1,0.5,0.3,0.9,0.8,1c0.1,0,0.1,0,0.2,0c0.5,0,0.7-0.4,1-1L83.8,54.4z M86,48.5c0-0.1,0-0.2,0.1-0.2s0.2,0,0.2,0.1
          c0.1,0.6,0.3,1.2,0.5,1.8c0,0.1,0,0.2-0.1,0.2c-0.3,0-0.7,0-1.1,0c0.6,0.3,1.3,0.8,1.3,1.5c0,0.4-0.2,0.6-0.4,0.6
          c-0.4,0-0.8-0.3-0.8-0.6s0.3-0.4,0.3-0.6s-0.1-0.3-0.3-0.5c-0.3-0.2-0.6-0.3-1-0.3h-1.4c-0.4,0-0.8,0-1.2,0.1
          c-0.2,0.1-0.2,0.3-0.2,0.7c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.4,0-0.9,0-1.3s0-0.9,0-1.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1
          c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.2,1.2,0.1h1.7c0.3,0,0.6,0,0.9-0.1c0.2,0,0.3-0.2,0.3-0.4L86,48.5z M86.1,57.5
          c0-0.1,0-0.1,0.2-0.1s0.2,0,0.2,0.1c0.1,0.4,0.2,0.7,0.5,1c0.2,0.3,0.5,0.5,0.8,0.6c0.1,0,0.1,0.1,0.1,0.2s0,0.2-0.1,0.2h-1.2
          c0,0.5,0,1.1,0,1.6c0,0,0,0-0.1,0L86.2,61c0,0-0.1-0.1-0.1-0.1c0-0.4,0-1,0-1.4h-2.9c-0.4-0.1-0.8,0-1.2,0.2
          c-0.2,0.1-0.3,0.4-0.3,0.6c0,0.3,0.1,0.5,0.3,0.7c0,0,0,0.1,0,0.2s-0.1,0.1-0.2,0c-0.4-0.4-0.6-0.8-0.6-1.4c0-0.4,0.1-0.9,0.4-1.2
          c0.4-0.3,0.8-0.4,1.3-0.4h1.6h1.6V57.5z M83.8,67c0.4-0.8,0.7-1.3,1.6-1.3c0.6,0,1.4,0.5,1.4,1.8c0,0.3,0,0.6-0.1,0.8
          c0,0.2,0,0.5,0,0.8c-0.4,0-0.9,0-1.3,0.1c-0.1,0-0.1,0-0.1-0.1s0-0.1,0.1-0.2c0.6-0.3,1.2-0.8,1.2-1.3s-0.3-0.9-0.8-0.9
          c-0.3,0-0.6,0.3-0.8,0.8l-0.2,0.4c-0.4,1-0.8,1.6-1.8,1.6c-0.8,0-1.7-0.6-1.7-2c0-0.6,0.1-1.1,0.3-1.6c0.4-0.1,0.8-0.2,1.2-0.3
          c0.1,0,0.1,0,0.1,0.1s0,0.1-0.1,0.2c-0.7,0.3-1.2,0.9-1.3,1.6c-0.1,0.5,0.3,0.9,0.8,1c0.1,0,0.1,0,0.2,0c0.5,0,0.7-0.4,1-1L83.8,67z
          M84.8,71c0.3,0,0.7,0,1-0.1c0.2-0.1,0.3-0.2,0.2-0.4v-0.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.2,0.1c0.1,0.4,0.3,1.3,0.5,1.8
          c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.8,0-1.5,0-2,0h-1.5c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.1-0.1,0.1-0.2,0.1
          s-0.1,0-0.1-0.1c0-0.4,0-0.9,0-1.3s0-0.9,0-1.3c0-0.1,0-0.1,0.1-0.1s0.2,0,0.2,0.1c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.2,1.2,0.1
          L84.8,71z M89.2,72.2c-0.4,0-0.7-0.3-0.8-0.8c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.7,0.4,0.8,0.8C89.9,71.9,89.6,72.2,89.2,72.2L89.2,72.2
          z M82.3,76.7c0,2-0.9,2.2-1.5,2.2c-0.9,0-2-0.5-2-3c0-1.5,0.7-2.4,1.4-2.4s1,0.4,1.3,1.3c0.2-0.7,0.5-0.9,0.8-0.9s0.8,0.4,1.1,1.2
          c0.2-0.8,0.7-1.4,1.6-1.4c1.1,0.1,1.9,1,1.8,2c0,0,0,0,0,0c0,0.4-0.1,0.9-0.3,1.3c0,0.8,0,1.4,0.1,1.8c0,0,0,0.1-0.1,0.1
          c0,0,0,0,0,0l-0.3-0.2c0,0-0.1,0-0.1-0.1c0-0.4,0-0.8,0-1.2C85.9,77.8,85.5,78,85,78c-1,0-1.7-0.7-1.7-2.1c0-0.1,0-0.3,0-0.4
          c-0.2-0.4-0.4-0.6-0.7-0.6s-0.4,0.3-0.4,0.7L82.3,76.7z M81.3,75.9c0-0.2,0-0.6,0-0.8c-0.1-0.1-0.1-0.2-0.2-0.2
          c-0.2-0.2-0.6-0.3-0.9-0.3c-0.7,0-1.2,0.6-1.2,1.5s0.5,1.7,1.3,1.7c0.5,0,0.9-0.2,0.9-1.4L81.3,75.9z M85.1,74.9
          c-0.9,0-1.4,0.4-1.4,1s0.6,0.9,1.4,0.9s1.4-0.4,1.4-0.9S85.9,74.9,85.1,74.9L85.1,74.9z M85.8,81.2c0.6,0.4,1,1,1,1.7
          c0.1,0.9-0.6,1.6-1.4,1.7c-0.1,0-0.1,0-0.2,0h-1.3h-0.8c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.1,0,0.1-0.1,0.1
          s-0.1,0-0.1-0.1c0-0.4,0-0.9,0-1.3s0-0.8,0-1.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.1,1.2,0.1h1.5
          c0.3,0,0.7,0,1-0.1c0.4-0.1,0.6-0.5,0.6-0.9c0-0.4-0.3-0.8-0.6-1c-0.3-0.2-0.6-0.3-0.9-0.2h-1.5c-0.4,0-0.8,0-1.2,0.1
          c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.4,0-0.8,0-1.2s0-0.9,0-1.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1
          c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.1,1.2,0.1h5.1c0.3,0,0.6,0,0.9,0c0.2,0,0.3-0.2,0.3-0.4c0,0,0,0,0,0v-0.3c0-0.1,0-0.1,0.1-0.1
          s0.1,0,0.2,0.1c0.1,0.6,0.3,1.3,0.5,1.9c0,0-0.1,0.1-0.1,0.1c-0.8,0-1.7-0.1-2.8-0.1L85.8,81.2z M86.1,85.8c0-0.1,0-0.1,0.2-0.1
          s0.2,0,0.2,0.1c0.1,0.4,0.2,0.7,0.5,1c0.2,0.3,0.5,0.5,0.8,0.6c0.1,0,0.1,0.1,0.1,0.2s0,0.2-0.1,0.2h-1.2c0,0.5,0,1.1,0,1.6
          c0,0,0,0-0.1,0l-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.4,0-1,0-1.4h-2.9c-0.4-0.1-0.8,0-1.2,0.2c-0.2,0.1-0.3,0.4-0.3,0.6
          c0,0.3,0.1,0.5,0.3,0.7c0,0,0,0.1,0,0.2s-0.1,0.1-0.2,0c-0.4-0.4-0.6-0.9-0.5-1.4c0-0.4,0.1-0.9,0.4-1.2c0.4-0.3,0.8-0.4,1.3-0.4
          h1.6h1.6L86.1,85.8z M80,90.3c0,0,0-0.1,0-0.2s0.2-0.1,0.2,0c0.3,0.5,0.7,0.7,1,0.7c0.1,0,0.3-0.2,0.3-0.3s0.1-0.4,0.4-0.4
          c0.4,0,0.8,0.4,0.8,0.8c-0.1,0.4-0.5,0.7-0.9,0.7C81.4,91.7,80.8,91.5,80,90.3z M83.6,95.4c-0.5,0-0.9,0-1.4,0.2
          c-0.3,0.2-0.3,0.6-0.3,1.1c0,0.7,0.2,1.4,0.6,1.9c0.3,0.4,0.7,0.7,1.1,0.9c0.1,0,0.1,0.1,0.1,0.2s-0.1,0.1-0.2,0.1
          c-0.7-0.2-1.3-0.4-2-0.6c0-1,0-2.1,0-3s0-2.1,0-3.1c0-0.1,0-0.1,0.1-0.1s0.1,0,0.2,0.1c0,0.4,0,0.7,0.3,0.8c0.5,0.2,1,0.2,1.5,0.2
          h4.1c1.1,0,1.2,0,1.5-0.1s0.2-0.4,0.3-0.8c0-0.1,0-0.1,0.2-0.1s0.2,0,0.2,0.1c0,0.5,0,1.1,0,1.6s0,1.1,0,1.6c0,0.1,0,0.1-0.2,0.1
          s-0.1,0-0.2-0.1c0-0.4,0-0.7-0.3-0.8c-0.5-0.1-1-0.2-1.5-0.1L83.6,95.4z M84.8,101c0.3,0,0.7,0,1,0c0.2-0.1,0.3-0.2,0.2-0.4v-0.3
          c0-0.1,0-0.1,0.1-0.2s0.1,0,0.2,0.1c0.1,0.4,0.3,1.3,0.5,1.8c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.8,0-1.5,0-2,0h-1.5
          c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.1-0.1,0.1-0.2,0.1s-0.1,0-0.1-0.1c0-0.4,0-0.9,0-1.3s0-0.9,0-1.3
          c0-0.1,0-0.1,0.1-0.1s0.2,0,0.2,0.1c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.1,1.2,0.1L84.8,101z M89.2,102.3c-0.4,0-0.8-0.3-0.9-0.7
          c0-0.4,0.3-0.8,0.7-0.9c0.1,0,0.1,0,0.2,0c0.4,0,0.7,0.4,0.7,0.9C89.8,101.9,89.5,102.2,89.2,102.3z M83.5,106
          c-0.3,0.1-0.6,0.2-0.9,0.3c0.4,0.2,0.6,0.2,1,0.3l1.5,0.5c0.3,0.2,0.7,0.2,1.1,0.2c0.2-0.1,0.2-0.3,0.2-0.6c0-0.1,0-0.1,0.1-0.1
          s0.1,0,0.1,0.1c0,0.3,0,0.8,0,1.1s0,0.7,0,1c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.2-0.1-0.4-0.3-0.6c-0.3-0.3-0.7-0.4-1.1-0.6
          l-2.1-0.8l-1.3-0.5c-0.1-0.2-0.2-0.5-0.3-0.7c0,0,0-0.1,0.1-0.1c0.5-0.2,0.9-0.3,1.5-0.5l2.1-0.8c0.4-0.1,0.8-0.3,1.2-0.5
          c0.2-0.2,0.2-0.3,0.2-0.6c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.4,0,0.9,0,1.3s0,1,0,1.4c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1
          c0-0.3,0-0.6-0.2-0.6c-0.4,0-0.8,0.1-1.2,0.3L83.5,106z M84.1,110.5c-1.5,0-2.2,0.9-2.2,1.6c0,0.6,0.3,1.2,0.8,1.6
          c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1-0.1c-0.7-0.4-1.2-1.2-1.1-2.1c0-1.4,1.1-2.6,2.5-2.6
          c0.1,0,0.1,0,0.2,0c1.5-0.1,2.7,1.1,2.8,2.5c0,0,0,0.1,0,0.1c0,1.2-0.9,2.1-2.1,2.2c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2
          c0-1.1,0-2.3,0-3.3C84.3,110.5,84.2,110.5,84.1,110.5z M84.9,112.7c0,0.1,0.1,0.1,0.2,0.1c0.6,0,1.4-0.3,1.4-1s-0.7-1.1-1.6-1.3
          C84.9,111.3,84.9,112,84.9,112.7z M83.2,120.5c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.4-0.2,0.8c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1
          c0-0.5,0-1,0-1.4s0-1,0-1.4c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.3,0,0.6,0.2,0.7c0.4,0.1,0.8,0.1,1.2,0.1h3c0-0.3,0-0.6,0-1
          c0,0,0-0.1,0.1-0.1l0.3,0.1c0,0,0.1,0.1,0.1,0.1c0,0.3,0,0.6,0,0.8h0.6c0.9,0,1.7,0.3,2.3,0.9c0.5,0.5,0.7,1.1,0.7,1.8
          c0,0.4-0.1,0.7-0.4,0.7c-0.5,0-0.9-0.3-0.9-0.5s0.1-0.3,0.3-0.5s0.4-0.3,0.3-0.5c0-0.3-0.4-0.5-1.2-0.6c-0.6-0.1-1.2-0.1-1.8-0.1
          c0,0.4,0,0.9,0,1.4c0,0.1,0,0.1-0.1,0.1l-0.3-0.1c0,0-0.1,0-0.1-0.1c0-0.4,0-0.8,0-1.3L83.2,120.5z M84.1,127.8
          c-1.5,0.1-2.7-1-2.8-2.5s1-2.7,2.5-2.8c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7,1,2.8,2.5s-1,2.7-2.5,2.8C84.3,127.9,84.2,127.9,84.1,127.8z
          M84.1,123.8c-1.4,0-2.4,0.4-2.4,1.3s1.1,1.3,2.4,1.3s2.4-0.4,2.4-1.3S85.4,123.9,84.1,123.8L84.1,123.8z M86,128.7
          c0-0.1,0-0.1,0.1-0.2s0.2,0,0.2,0.1c0.1,0.6,0.3,1.2,0.5,1.8c0,0.1,0,0.1-0.1,0.1c-0.3,0-0.7,0-1.1,0c0.6,0.3,1.3,0.8,1.3,1.5
          c0,0.4-0.2,0.6-0.4,0.6c-0.4,0-0.8-0.3-0.8-0.6s0.3-0.4,0.3-0.6s-0.1-0.3-0.3-0.5c-0.3-0.2-0.6-0.3-1-0.3h-1.4c-0.4,0-0.8,0-1.2,0.1
          c-0.2,0.1-0.2,0.3-0.2,0.7c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.4,0-0.9,0-1.3s0-0.9,0-1.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1
          c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.2,1.2,0.1h1.7c0.3,0,0.6,0,0.9-0.1c0.2,0,0.3-0.2,0.2-0.4c0,0,0,0,0,0V128.7z M84.1,134.5
          c-1.5,0-2.2,0.9-2.2,1.6c0,0.6,0.3,1.2,0.8,1.6c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1l-0.1-0.1
          c-0.7-0.4-1.2-1.2-1.1-2.1c0-1.4,1.1-2.6,2.5-2.6c0.1,0,0.1,0,0.2,0c1.5-0.1,2.7,1.1,2.8,2.5c0,0,0,0.1,0,0.1c0,1.2-0.9,2.1-2.1,2.2
          c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.2c0-1.1,0-2.3,0-3.3C84.3,134.5,84.2,134.5,84.1,134.5z M84.9,136.7
          c0,0.1,0.1,0.1,0.2,0.1c0.6,0,1.4-0.3,1.4-1s-0.7-1.1-1.6-1.3C84.9,135.3,84.9,136,84.9,136.7z M83.5,141.3
          c-0.3,0.1-0.6,0.2-0.9,0.3c0.4,0.1,0.6,0.2,1,0.4l1.5,0.5c0.3,0.2,0.7,0.2,1.1,0.1c0.2-0.1,0.2-0.3,0.2-0.6c0-0.1,0-0.1,0.1-0.1
          s0.1,0,0.1,0.1c0,0.3,0,0.8,0,1.1s0,0.6,0,0.9c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.2-0.1-0.4-0.3-0.6c-0.3-0.2-0.7-0.4-1.1-0.6
          l-2.1-0.8l-1.3-0.5c-0.1-0.2-0.2-0.5-0.3-0.7c0,0,0-0.1,0.1-0.1c0.5-0.1,0.9-0.3,1.5-0.5l2.1-0.8c0.4-0.1,0.8-0.3,1.2-0.5
          c0.2-0.1,0.2-0.3,0.2-0.6c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.4,0,0.9,0,1.3s0,0.9,0,1.4c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1
          c0-0.3,0-0.6-0.2-0.6s-0.5,0.1-1.2,0.3L83.5,141.3z M84.1,145.7c-1.5,0-2.2,0.9-2.2,1.6c0,0.6,0.3,1.2,0.8,1.6c0,0,0.1,0.1,0.1,0.1
          c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1l-0.1-0.1c-0.7-0.4-1.2-1.2-1.1-2.1c0-1.4,1.1-2.6,2.5-2.6c0.1,0,0.1,0,0.2,0
          c1.5-0.1,2.7,1.1,2.8,2.5c0,0,0,0.1,0,0.1c0,1.2-0.9,2.1-2.1,2.2c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0-1.1,0-2.3,0-3.3
          C84.3,145.8,84.2,145.7,84.1,145.7z M84.9,148c0,0.1,0.1,0.1,0.2,0.1c0.6,0,1.4-0.3,1.4-1s-0.7-1.1-1.6-1.3
          C84.9,146.5,84.9,147.3,84.9,148L84.9,148z M86,150c0-0.1,0-0.1,0.1-0.2s0.2,0,0.2,0.1c0.1,0.6,0.3,1.2,0.5,1.8c0,0.1,0,0.1-0.1,0.1
          c-0.3,0-0.7,0-1.1,0c0.6,0.3,1.3,0.8,1.3,1.5c0,0.4-0.2,0.6-0.4,0.6c-0.4,0-0.8-0.3-0.8-0.6s0.3-0.4,0.3-0.6s-0.1-0.3-0.3-0.5
          c-0.3-0.2-0.6-0.3-1-0.3h-1.4c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.3-0.2,0.7c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.4,0-0.9,0-1.3
          s0-0.9,0-1.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.2,1.2,0.1h1.7c0.3,0,0.6,0,0.9-0.1
          c0.2,0,0.3-0.2,0.3-0.4L86,150z M82.8,162.4c-0.7,0-1,0.1-1,0.4c0,0.1,0,0.2,0.1,0.3c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.2,0
          c-0.2-0.3-0.3-0.6-0.3-1c0-0.4,0.2-0.8,0.6-1c-0.5-0.4-0.7-1-0.7-1.6c0-1.1,0.7-1.4,1.2-1.4c0.4,0,0.7,0.1,1,0.4
          c0.3,0.4,0.5,0.9,0.6,1.4c0.1,0.3,0.2,0.6,0.4,0.9c0.1,0.1,0.3,0.2,0.4,0.3h0.3c0.8,0,1.2-0.2,1.2-0.7c0-0.2-0.1-0.4-0.2-0.6
          c-0.2-0.2-0.4-0.2-0.6-0.2c-0.3,0-0.5-0.3-0.5-0.9c0-0.4,0.1-0.4,0.4-0.4c0.5,0,1.4,0.9,1.4,2.3c0,1.7-0.8,1.7-1.7,1.7L82.8,162.4z
          M84.3,161.3c-0.2-0.1-0.3-0.3-0.5-0.9c-0.1-0.5-0.6-0.9-1.2-0.8c-0.5-0.1-0.9,0.3-0.9,0.7c0,0.6,0.4,1,1,1L84.3,161.3z M86,163.8
          c0-0.1,0-0.1,0.1-0.2s0.2,0,0.2,0.1c0.1,0.6,0.3,1.2,0.5,1.8c0,0.1,0,0.1-0.1,0.1c-0.3,0-0.7,0-1,0c0.7,0.3,1.1,1,1.1,1.8
          c0,0.9-0.6,1.6-1.5,1.7c0,0-0.1,0-0.1,0h-2c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1
          c0-0.4,0-0.9,0-1.2s0-0.9,0-1.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.1,1.2,0.1h1.5
          c0.3,0,0.7,0,1-0.1c0.4-0.1,0.6-0.5,0.6-0.8c0-0.4-0.3-0.8-0.7-1c-0.3-0.2-0.6-0.3-0.9-0.2h-1.5c-0.4,0-0.8,0-1.2,0.1
          c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.4,0-0.8,0-1.2s0-0.9,0-1.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1
          c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.1,1.2,0.1h1.7c0.3,0,0.7,0,1-0.1c0.2,0,0.3-0.2,0.2-0.3c0,0,0,0,0,0L86,163.8z M88.3,174.4
          c0.3,0,0.7,0,1-0.1c0.2,0,0.2-0.2,0.2-0.4v-0.3c0-0.1,0-0.2,0.1-0.2s0.2,0,0.2,0.1c0.1,0.6,0.3,1.2,0.5,1.9c0,0.1-0.1,0.1-0.1,0.1
          c-0.8,0-1.7-0.1-2.8-0.1h-4.3c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.2-0.2,0.4v0.3c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.2-0.1
          c0-0.6-0.1-1.2-0.2-1.8c0-0.1,0-0.1,0.1-0.1c0.3,0,0.5,0,0.8,0c-0.6-0.3-0.9-0.9-0.9-1.5c0-1.3,1.1-2.4,2.4-2.4c0.1,0,0.2,0,0.3,0
          c1.4-0.1,2.7,0.9,2.8,2.3c0,0.1,0,0.2,0,0.3c0,0.5-0.2,1-0.6,1.3H88.3z M84.8,174.4c0.3,0,0.7,0,1-0.2c0.4-0.2,0.6-0.6,0.7-1
          c0-0.9-1-1.4-2.4-1.4s-2.2,0.6-2.2,1.4c0,0.4,0.2,0.8,0.6,1c0.3,0.2,0.6,0.3,0.9,0.2L84.8,174.4z M84.1,181.9
          c-1.5,0-2.2,0.9-2.2,1.6c0,0.6,0.3,1.2,0.8,1.6c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1h-0.1
          c-0.7-0.4-1.2-1.2-1.1-2.1c-0.1-1.4,1-2.6,2.5-2.7c0.1,0,0.1,0,0.2,0c1.5-0.1,2.7,1.1,2.8,2.5c0,0,0,0.1,0,0.1
          c0,1.2-0.9,2.1-2.1,2.2c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.1-0.1-0.1-0.2c0-1.1,0-2.3,0-3.3C84.3,182,84.2,181.9,84.1,181.9z
          M84.9,184.1c0,0.1,0.1,0.1,0.2,0.1c0.6,0,1.4-0.3,1.4-1s-0.7-1.1-1.6-1.3C84.9,182.7,84.9,183.4,84.9,184.1z M83.5,188.7
          c-0.3,0.1-0.6,0.2-0.9,0.3c0.4,0.1,0.6,0.2,1,0.4l1.5,0.5c0.3,0.2,0.7,0.2,1.1,0.1c0.2-0.1,0.2-0.3,0.2-0.6c0-0.1,0-0.1,0.1-0.1
          s0.1,0,0.1,0.1c0,0.3,0,0.8,0,1.1s0,0.7,0,0.9c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.2-0.1-0.4-0.3-0.6c-0.3-0.2-0.7-0.4-1.1-0.6
          l-2.1-0.8l-1.3-0.5c-0.1-0.2-0.2-0.5-0.3-0.7c0,0,0-0.1,0.1-0.1c0.5-0.2,0.9-0.3,1.5-0.5l2.1-0.8c0.4-0.1,0.8-0.3,1.2-0.5
          c0.2-0.1,0.2-0.3,0.2-0.6c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.4,0,0.9,0,1.3s0,0.9,0,1.4c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1
          c0-0.3,0-0.6-0.2-0.6s-0.5,0.1-1.2,0.3L83.5,188.7z M84.1,193.1c-1.5,0-2.2,0.9-2.2,1.6c0,0.6,0.3,1.2,0.8,1.6c0,0,0.1,0.1,0.1,0.1
          c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1l-0.1-0.1c-0.7-0.4-1.2-1.2-1.1-2.1c0-1.4,1.1-2.6,2.5-2.6c0,0,0.1,0,0.1,0
          c1.5-0.1,2.7,1.1,2.8,2.5c0,0,0,0.1,0,0.1c0,1.2-0.9,2.1-2.1,2.2c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0-1.1,0-2.3,0-3.3
          C84.3,193.2,84.2,193.1,84.1,193.1z M84.9,195.4c0,0.1,0.1,0.1,0.2,0.1c0.6,0,1.4-0.3,1.4-1s-0.7-1.1-1.6-1.3
          C84.9,193.9,84.9,194.7,84.9,195.4z M86,197.4c0-0.1,0-0.1,0.1-0.2s0.2,0,0.2,0.1c0.1,0.6,0.3,1.2,0.5,1.8c0,0.1,0,0.1-0.1,0.1
          c-0.3,0-0.7,0-1.1,0c0.6,0.3,1.3,0.8,1.3,1.5c0,0.4-0.2,0.6-0.4,0.6c-0.4,0-0.8-0.3-0.8-0.6s0.3-0.4,0.3-0.6s-0.1-0.3-0.3-0.5
          c-0.3-0.2-0.6-0.3-1-0.3h-1.4c-0.4,0-0.8,0-1.2,0.1c-0.2,0.1-0.2,0.3-0.2,0.7c0,0.1,0,0.1-0.1,0.1s-0.1,0-0.1-0.1c0-0.4,0-0.9,0-1.3
          s0-0.9,0-1.3c0-0.1,0-0.1,0.1-0.1s0.1,0,0.1,0.1c0,0.3,0,0.5,0.2,0.6c0.4,0.1,0.8,0.2,1.2,0.1h1.7c0.3,0,0.6,0,0.9-0.1
          c0.2,0,0.3-0.2,0.3-0.4L86,197.4z"/>
        <path class="st4 an3" d="M34.5,128.2c0-0.4-0.3-0.8-1-1.5c-1.6-1.3-2-1.6-2.6-1.6c-0.4,0-0.8,0.3-1.8,1.7h-1.7v-10.7h7.9
          c0.6,0,0.8-0.2,0.8-0.5s-0.3-0.9-1.3-1.7c-2.1-1.8-2.6-2.1-3.1-2.1c-0.4,0-0.8,0.3-2.4,2.2h-9.9c-1.4-0.8-2.8-1.5-4.3-2.1
          c0.3,5.5,0.3,10.9,0.3,16.4c0,19.1-0.3,23.9-0.3,28.7c0,1,0.6,1.6,2,1.6c2.1,0,2.9-0.7,2.9-2.3v-2.2h13.6c0.5,0,0.7-0.2,0.7-0.6
          c-0.2-0.6-0.5-1.2-1-1.6c-1.6-1.6-2-2.1-2.5-2.1s-0.9,0.4-2.1,2.2h-1.2v-12.3h1.7v0.8c0,1.3,0.5,1.6,1.7,1.6c1.7,0,2.6-0.4,2.6-1.9
          c0-0.9-0.2-3.5-0.2-8.3v-2.4C34.4,128.7,34.5,128.6,34.5,128.2z M20.1,137.8v-9.1h9.1v9.1H20.1z M20.1,126.7v-10.7h3.2v10.7H20.1z
          M23.2,152.1h-3.2v-12.3h3.2V152.1z M47.2,140.6c-1.5-1.2-1.9-1.5-2.5-1.5s-0.8,0.3-2,1.7h-4.1c-1.3-0.7-2.7-1.3-4.1-1.7
          c0.2,3.5,0.2,7,0.2,10.4c0,6.1-0.2,7.5-0.2,9c0,1.1,0.5,1.5,1.7,1.5c2,0,2.6-0.5,2.6-1.9v-1.7h4v1.4c0,1,0.6,1.5,1.9,1.5
          c1.6,0,2.4-0.6,2.4-1.9c0-1.4-0.1-5.6-0.1-11.8v-2.4c0.8-0.4,1.1-0.8,1.1-1.3S47.9,141.2,47.2,140.6z M39,154.4v-11.6h4v11.6H39z
          M62.6,116.6c-2.3-2-2.9-2.4-3.4-2.4s-0.9,0.4-2.8,2.7H43.2c0.5-0.8,1-1.7,1.5-2.5c2.1-0.4,2.3-0.6,2.3-1.2s-1.1-1.3-6.5-2.9
          c-0.4,1.9-0.9,3.9-1.5,5.8c-1.2,3.5-2.9,6.8-5.1,9.8c-0.2,0.2,0.2,0.7,0.4,0.5c1.7-1.2,3.2-2.6,4.6-4.1c0.2,2.1,0.3,4.2,0.3,6.3
          c0,5.1-0.2,6.4-0.2,7.7s0.7,1.6,2,1.6c1.7,0,2.7-0.6,2.7-1.8v-0.8h9.8v0.8c0,1.1,0.8,1.5,2.1,1.5c1.7,0,2.8-0.7,2.8-1.9
          c0-0.9-0.2-3.5-0.2-7.5v-1.5c1.1-0.8,1.4-1.1,1.4-1.6s-0.3-0.8-1.1-1.5c-1.7-1.4-2.2-1.7-2.8-1.7s-0.8,0.3-2.4,2.1h-9.6
          c-1.9-0.8-2.4-1-4.7-1.8c1-1,1.9-2.1,2.7-3.3h21.5c0.5,0,0.7-0.2,0.7-0.6S63.5,117.4,62.6,116.6z M53.6,125.9v7.2h-9.8v-7.2H53.6z
          M62.7,140.5c-1.4-1.3-1.8-1.6-2.4-1.6c-0.4,0-0.8,0.3-2.3,1.9h-4.7c-1.2-0.6-2.5-1.2-3.9-1.6c0.2,5.4,0.2,10.7,0.2,16
          c0,1.6-0.1,2.3-0.1,2.8c0,1,0.6,1.4,1.8,1.4c1.6,0,2.5-0.5,2.5-1.6v-1.4h4.2v1.7c0,1,0.5,1.5,1.9,1.5c1.7,0,2.5-0.6,2.5-1.9
          c0-1.4-0.2-5.8-0.2-12.1v-2.4c1-0.7,1.2-1,1.2-1.4S63.3,141,62.7,140.5z M54,154.4v-11.6h4.2v11.6H54z"/>
        <path class="st4 an4" d="M18.8,187.1c1.3,2.8,1.5,3,2.8,3c1.5,0,2.7-1.3,2.7-2.8c0-2.9-3.2-4.8-9-5.3c-0.3,0-0.4,0.3-0.2,0.4
          C16.7,183.6,18,185.2,18.8,187.1z M21.9,174.4c1.3,2.9,1.5,3.1,2.9,3.1c1.5,0,2.7-1.3,2.7-2.8c0,0,0,0,0,0c0-3.1-3.4-5.2-9.1-5.6
          c-0.3,0-0.4,0.3-0.2,0.4C19.7,170.8,21.1,172.5,21.9,174.4z M63.1,185.1c-2.5-2.1-3.2-2.5-3.6-2.5s-0.9,0.4-2.9,2.9H39.1
          c0-1.6,0.1-3.2,0.1-4.9v-3.2c6.6-0.7,13-2.4,19.1-5.1c0.6,0.3,1.3,0.4,1.9,0.4c0.7,0,1-0.3,1-0.8c0-0.8-1.1-1.8-5.3-5
          c-1.1,1.1-2.3,2.1-3.6,2.9c-4.3,2.8-9.5,5.3-12.8,6.1c-2.6-1.3-3-1.4-5.7-2.5c0.2,2.9,0.3,5.6,0.3,10.4c0.1,6-0.3,12-1.3,17.9
          c-1.1,5.5-3.5,10.7-7,15c-0.2,0.2,0.3,0.6,0.5,0.4c5.1-4,8.3-7.8,10.2-13.3c1.5-4.2,2.3-9.4,2.6-16.4h9.3V202
          c0,8.4-0.2,12.6-0.2,13.9c0,1.2,0.8,1.6,2.4,1.6c2.1,0,3.2-0.6,3.2-2c0-2.9-0.2-11.6-0.2-18.6v-9.7h10.3c0.5,0,0.7-0.2,0.7-0.6
          S64.2,186,63.1,185.1z M23.5,201.1c2.2-5.4,4.3-10.9,6.5-16.3c0.1-0.3-0.5-0.5-0.6-0.3c-4.7,9-7.2,13.5-8.8,15.7
          c-1.4,2-2.1,2.1-3.3,2.1c-0.8,0-1.6-0.1-2.4-0.3c-0.3-0.1-0.4,0.7-0.2,0.8c4.1,1.4,4.6,2,4.6,5.6c0,1.7-0.4,3.5-0.4,5.3
          c0,1.9,0.9,2.8,2.6,2.8c1.9,0,3.3-1.6,3.3-3.9c0-2.9-2.1-5.2-2.1-8C22.8,203.4,23.1,202.2,23.5,201.1z"/>
        <path class="an2" d="M22.1,61.2h35.2c0.5,0,0.7-0.2,0.7-0.5s-0.4-0.9-1.7-2c-2.8-2.1-3.4-2.6-3.9-2.6s-0.9,0.4-3.3,3.1H22.1V61.2z
          M63.6,97.5c-0.9-0.4-1-0.5-1-1.9v-6.7c0-0.3-0.6-0.3-0.7-0.1c-1.4,5.9-2.1,8.3-3.8,9.4c-1.3,0.7-2.9,0.8-5.8,0.8
          c-4,0-4.9-0.4-4.9-2.2V74h16.4c0.6,0,0.7-0.2,0.7-0.5s-0.4-0.8-1.8-2c-3-2.4-3.7-2.8-4.1-2.8S57.7,69,55.3,72H15.3v2h14.4
          c0,7.3-0.8,12.6-2.4,16.8c-2.2,5.6-6.1,9.4-12.8,13.3c-0.1,0.1-0.1,0.3,0,0.4c0.1,0.1,0.1,0.1,0.2,0.1c8.4-2.6,13.6-5.8,16.7-11.3
          c2.5-4.5,3.7-10.7,3.8-19.3h7.2v13.3c0,4.1-0.1,7.2-0.1,10.7c0,4.5,1.9,5.5,9.8,5.5c4.8,0,8-0.3,10-0.9s2.8-1.6,2.8-3
          C64.9,98.7,64.4,97.9,63.6,97.5z"/>
        <path class="an1" d="M35.2,11.9h6.6v9.2H27.5v2h36.1c0.6,0,0.7-0.2,0.7-0.5s-0.4-0.9-1.5-1.7c-2.4-2-2.9-2.5-3.4-2.5
          s-0.9,0.4-2.9,2.7H47v-9.2h14.3c0.5,0,0.7-0.2,0.7-0.5s-0.4-0.9-1.4-1.7c-2.2-1.9-2.8-2.3-3.3-2.3c-0.3,0-0.9,0.4-2.7,2.5H47V3.7
          c1-0.4,1.6-0.8,1.6-1.4s-0.6-1.1-7-1.7c0.1,2,0.2,4,0.2,6.3v3H30.1v2L35.2,11.9L35.2,11.9z M23.6,17.1c2.6-3.9,4.7-8.2,6.3-12.6
          c2-0.5,2.2-0.7,2.2-1.3s-0.7-1-7-2.9c-0.4,3-1.1,6-2,8.9c-2.1,6.3-5,12.3-8.7,17.7c-0.2,0.2,0.3,0.8,0.6,0.6c2.2-2,4.1-4.2,5.9-6.5
          v12.5c0,8.6-0.4,12.8-0.4,14.3c0,1.6,0.6,2.1,2.4,2.1c2,0,2.9-0.8,2.9-2.3v-28c1.1-0.5,1.4-0.8,1.4-1.3S26.7,17.6,23.6,17.1z
          M63.3,44.9c-2.4-2-3-2.5-3.5-2.5s-0.9,0.4-3,2.8H47v-11h14.7c0.5,0,0.7-0.2,0.7-0.5S61.9,32.9,61,32c-2.2-1.9-2.8-2.3-3.3-2.3
          c-0.3,0-0.9,0.4-2.7,2.5h-8v-7.5h-5.2v7.5H29.6v2h12.2v11H27.5v2h36.7c0.5,0,0.7-0.2,0.7-0.5S64.4,45.8,63.3,44.9z"/>
      </svg>

      <div
        class="btn"
        v-scroll-to="{ element: `#contact` }"
        data-aos="fade"
        data-aos-delay="3000"
      >立即預約</div>
      <ul class="poetry">
        <li
          data-aos="fade"
          data-aos-delay="300" data-aos-duration="1000"
        >在水墨之間藝文行旅</li>
        <li
          data-aos="fade"
          data-aos-delay="500" data-aos-duration="1000"
        >在新舊之間味蕾散步</li>
        <li
          data-aos="fade"
          data-aos-delay="700" data-aos-duration="1000"
        >臨沂街 靜邀</li>
        <li
          data-aos="fade"
          data-aos-delay="900" data-aos-duration="800"
        >難得一遇的美好</li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
 #svg1{
    width: 58vw;
    height: 28vw;
    top: 14vw;
    left: 9vw;
    position: absolute;}
	.st0{fill:#E65300;}
	.st1{fill:#64513D;}
	.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#CF000E;}
	.st3{fill:#C6000B;}
	.st4{fill:#C00D23;}
  .st5{fill:#595757;}
  .an1{opacity: 0; animation: op .5s linear 2s forwards}
  .an2{opacity: 0; animation: op .5s linear 2.3s forwards}
  .an3{opacity: 0; animation: op .5s linear 2.6s forwards}
  .an4{opacity: 0; animation: op .5s linear 2.9s forwards}
  .an5{opacity: 0; animation: op .5s linear 3.2s forwards}
  .an6{opacity: 0; animation: op .5s linear 3.5s forwards}
  .an7{opacity: 0; animation: op .5s linear 3.8s forwards}
  .an8{opacity: 0; animation: op .5s linear 4.1s forwards}
  .an9{opacity: 0; animation: op .5s linear 4.4s forwards}
  .an10{opacity: 0; animation: op .5s linear 4.7s forwards}
  .an11{opacity: 0; animation: op .5s linear 5s forwards}
  .an12{opacity: 0; animation: op .5s linear 5.3s forwards}
  @-webkit-keyframes op {
    to {
        opacity: 1
    }
}

@media screen and (max-width: 767px) {
 #svg1{
    width: 61vw;
    height: 118vw;
    top: 26vw;
    left: 15vw;}
}
</style>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.bg-img {
  width: 100vw;
  height: auto;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  object-fit: cover;

  &:nth-child(3) {
    position: relative;
  }
}

.tree1 {
  animation: skew1 5s 0s ease-in-out infinite;
  transform-origin: bottom;
}

.tree2 {
  animation: skew2 3s 1s ease-in-out infinite;
  transform-origin: bottom;
}

@keyframes skew1 {
  0% {
    transform: skew(-2deg);
  }

  50% {
    transform: skew(-1deg);
  }

  100% {
    transform: skew(-2deg);
  }
}

@keyframes skew2 {
  0% {
    transform: skew(2deg);
  }

  50% {
    transform: skew(1deg);
  }

  100% {
    transform: skew(2deg);
  }
}

.btn {
  width: size(262);
  height: size(85);
  color: #fff;
  font-size: size(30);
  background: #c00d23;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: size(605);
  left: size(963);
  cursor: pointer;
}

.poetry {
  position: absolute;
  top: size(1255);
  left: size(1030);
  writing-mode: vertical-rl;
  text-orientation: upright;
  li {
    font-size: size(27);
    margin: 0 15px;
    text-align: left;
    color: #000;
  }
}
@media only screen and (max-width: 1440px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .tree2 {
    top: size-m(650) !important;
    position: relative !important;
  }

  .bg-img {
    width: 100vw;
    height: auto;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    object-fit: cover;

    &:nth-child(3) {
      position: relative;
    }
  }

  .btn {
    width: size-m(132);
    height: size-m(42);
    font-size: size-m(15);
    top: size-m(582);
    left: size-m(62);
  }

  .poetry {
    position: absolute;
    z-index: 5;
    top: size-m(855);
    left: size-m(100);
    right: auto;
    li {
      font-size: size-m(18);
      margin: 0 15px;
      text-align: left;
      width: 20px;
      color: #000;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import { isMobile, isTablet } from '@/utils'

export default {
  name: 'section1',

  data() {
    return {
      isMobile,
      isTablet,
    }
  },

  methods: {},

  created() {},

  mounted() {},

  computed: {},
}
</script>
